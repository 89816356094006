<div mat-dialog-title style="text-align: center">RTMP Source</div>
<div mat-dialog-content>
  <form [formGroup]="addRTMPFrom">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between none" fxLayoutGap="20px">
        <div fxLayout="column" fxFlex>
          <mat-label class="text-weight-bold">Serve</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input type="text" matInput />
          </mat-form-field>

          <mat-label class="text-weight-bold">Stream key</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input type="text" matInput />
          </mat-form-field>

          <mat-checkbox class="mb-16" color="primary" formControlName="isUseAuthentication">
            Use Authentication
          </mat-checkbox>

          <mat-label class="text-weight-bold">Username</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input type="text" matInput />
          </mat-form-field>

          <mat-label class="text-weight-bold">Password</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input type="text" matInput />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayout="row" [style.padding]="'0 20px'" [style.margin-top]="'20px'">
  <button fxFlex [style.background]="'#dcd9d9'" mat-flat-button>Cancel</button>
  <button fxFlex color="primary" mat-flat-button>Save</button>
</div>
