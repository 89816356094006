import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video, Audio } from '@app/shared/domain';
import { AudioService, VideoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-thumbnail-dialog',
  templateUrl: './edit-thumbnail-dialog.component.html',
  styleUrls: ['./edit-thumbnail-dialog.component.scss'],
})
export class EditThumbnailDialogComponent implements OnInit, OnDestroy {
  public model: Video | Audio;
  public type: string;
  public callback?: () => void;
  public isLoadingDefault: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditThumbnailDiaLogModel,
    public dialogRef: MatDialogRef<EditThumbnailDialogComponent>,
    private notifier: NotifierService,
    public videoService: VideoService,
    public audioService: AudioService
  ) {
    this.model = data.model;
    this.type = data.type;
    this.callback = data.callback;
  }

  ngOnInit(): void {}

  handleChangeThumbnail(files: FileList) {
    if (files.length > 0) {
      const { uuid: id } = this.model;
      const fmData = new FormData();
      fmData.append('uuid', id);
      fmData.append('thumbnail', files[0]);
      this.isLoadingDefault = true;
      if (this.type === 'video') {
        this.subscriptions.push(
          this.videoService.uploadVideoThumbnail(fmData, id).subscribe(
            (res) => {
              this.model = res;
              this.isLoadingDefault = false;
              if (this.callback) {
                this.callback();
              }
            },
            (err) => {
              this.notifier.notify('error', err);
              this.isLoadingDefault = false;
            }
          )
        );
      } else {
        this.subscriptions.push(
          this.audioService.updateAudioImage(fmData, id).subscribe(
            (res) => {
              this.model = res;
              this.isLoadingDefault = false;
              if (this.callback) {
                this.callback();
              }
            },
            (err) => {
              this.notifier.notify('error', err);
              this.isLoadingDefault = false;
            }
          )
        );
      }
    }
  }

  handleRemoveThumbnail() {
    this.isLoadingDefault = true;
    if (this.type === 'video') {
      this.subscriptions.push(
        this.videoService.removeVideoThumbnail(this.model.uuid).subscribe(
          (res) => {
            this.model = res;
            this.isLoadingDefault = false;
            if (this.callback) {
              this.callback();
            }
          },
          (err) => {
            this.notifier.notify('error', err);
            this.isLoadingDefault = false;
          }
        )
      );
    } else {
      this.subscriptions.push(
        this.audioService.removeAudioThumnail(this.model.uuid).subscribe(
          (res) => {
            this.model = res;
            this.isLoadingDefault = false;
            if (this.callback) {
              this.callback();
            }
          },
          (err) => {
            this.notifier.notify('error', err);
            this.isLoadingDefault = false;
          }
        )
      );
    }
  }

  handleClose() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => {
      subscription.unsubscribe();
    });
  }
}

export class EditThumbnailDiaLogModel {
  constructor(public type: string, public model: Video | Audio, public callback?: () => void) {}
}
