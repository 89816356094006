<div mat-dialog-title style="text-align: center">Edit the name</div>
<div mat-dialog-content>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label></mat-label>
    <input matInput [formControl]="inputTitle" type="text" [(ngModel)]="title" />
  </mat-form-field>
  <mat-error *ngIf="inputTitle.hasError('required') || inputTitle.hasError('pattern')"> Title is required </mat-error>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-flat-button color="primary" (click)="handleSaveItem()">Save</button>
</div>
