<!-- start language selector -->
<div class="language-dropdown">
  <!-- Dropdown Button -->
  <div class="dropdown-button" (click)="toggleDropdown()">
    <img
      [src]="'/assets/icons/flags/' + (selectedLanguageIcon || defaultLanguageIcon)"
      [alt]="selectedLanguageLabel || 'Select Language'"
      class="flag-icon"
    />
    <span class="selected-language">{{ selectedLanguageLabel || 'Select Language' }}</span>
  </div>
  <!-- Dropdown Menu -->
  <div *ngIf="dropdownOpen" class="dropdown-menu">
    <div
      *ngFor="let language of languages"
      class="dropdown-item"
      (click)="selectLanguage(language.code, language.icon, language.label)"
    >
      <img
        [src]="'/assets/icons/flags/' + language.icon"
        [class.selected]="selectedLanguage === language.code"
        [title]="language.label"
        alt="{{ language.label }}"
        class="flag-icon"
      />
      <span>{{ language.label }}</span>
    </div>
  </div>
</div>
<!-- end language selector -->
