import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlContainer } from '@angular/forms';
@Component({
  selector: 'app-purchase-link-setup',
  templateUrl: './purchase-link-setup.component.html',
  styleUrls: ['./purchase-link-setup.component.scss'],
})
export class PurchaseLinkSetupComponent implements OnChanges {
  @Input() publishedSites: any[] = [];
  @Input() qr_code_website: boolean = false;

  constructor(public controlContainer: ControlContainer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.publishedSites.currentValue) {
      this.publishedSites = changes.publishedSites.currentValue.filter((s: any) => !s.settings.isDefault);
    }
  }

  updatePaymentLinkType(value: number) {
    if (this.f.get('payment_way').disabled) {
      return;
    }
    if (
      (this.f.get('payment_way').value === 1 && value === 1) ||
      (this.f.get('payment_way').value === 2 && value === 2)
    ) {
      this.f.get('payment_way').setValue(undefined);
    } else {
      this.f.get('payment_way').setValue(value);
    }
  }

  get f() {
    return this.controlContainer?.control;
  }

  copyEmbeddedCode() {
    navigator.clipboard.writeText(this.f.get('payment_forms').value);
  }
}
