import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-choose-folder-action',
  templateUrl: './choose-folder-action.component.html',
  styleUrls: ['./choose-folder-action.component.scss'],
})
export class ChooseFolderActionComponent implements OnInit {
  no_of_items: number = 0;
  folder_destination: string = '';

  constructor(
    public dialogRef: MatDialogRef<ChooseFolderActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChooseFolderActionModel
  ) {
    this.no_of_items = data.no_of_items;
    this.folder_destination = data.folder_destination;
  }

  ngOnInit() {}

  onConfirm(action: any): void {
    this.dialogRef.close({ action: action });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}

export class ChooseFolderActionModel {
  constructor(public no_of_items: number, public folder_destination: string) {}
}
