import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-folder-dialog',
  templateUrl: './edit-folder-dialog.component.html',
  styleUrls: ['./edit-folder-dialog.component.scss'],
})
export class EditFolderDialogComponent implements OnInit {
  folderForm: FormGroup;
  folderData: any = {};
  mode: string = null;
  totalVideos: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditFolderModel,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditFolderDialogComponent>
  ) {
    this.folderData = data.folderData;
    console.log(this.folderData);
  }

  ngOnInit(): void {
    this.folderForm = this.fb.group({
      title: [this.folderData.title, [Validators.required, Validators.maxLength(40)]],
      description: [this.folderData.description, [Validators.required, Validators.maxLength(100)]],
    });
  }

  handleEditFolder() {
    if (this.folderForm.valid) {
      const formData = this.folderForm.value;
      let data = {
        title: formData.title,
        description: formData.description,
      };

      this.dialogRef.close(data);
    }
  }
}

export class EditFolderModel {
  constructor(public folderData: any) {}
}
