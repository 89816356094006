import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { TemplateService } from '@app/shared/services/template/template.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '@env/environment';

@Component({
  selector: 'app-build-dialog',
  templateUrl: './build-dialog.component.html',
  styleUrls: ['./build-dialog.component.scss'],
})
export class BuildDialogComponent implements OnInit {
  public submitted = false;
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<BuildDialogComponent>,
    public templateService: TemplateService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  handleBuild(): void {
    this.isLoading = true;
    this.templateService.publishWebsite(this.data).subscribe(
      () => {
        this.templateService.buildWebsite(this.data).subscribe(
          () => {
            this.submitted = true;
            this.isLoading = false;
          },
          (err) => {
            this.notifier.notify('error', err);
            this.isLoading = false;
            this.dialogRef.close();
          }
        );
      },
      (err) => {
        this.notifier.notify('error', err);
        this.isLoading = false;
        this.dialogRef.close();
      }
    );
  }

  @HostListener('document:click', ['$event'])
  clickedOut(e: any) {
    if (this.submitted && !e.target.closest('mat-dialog-container')) {
      this.dialogRef.close(true);
    }
  }
}
