import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services';
import { environment as env } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private TOKEN_KEY = 'Bearer';
  private url = 'https://api.tvstartupcms.com/api/v1/clients/event-ticket/list/';

  private importerPaths = [
    { url: '/api/v1/ytb/list', method: 'GET' },
    { url: '/api/v1/ytb/import', method: 'POST' },
    { url: '/api/v1/vimeo/list', method: 'GET' },
    { url: '/api/v1/vimeo/import', method: 'POST' },
    { url: '/api/v1/dbx/list', method: 'GET' },
    { url: '/api/v1/dbx/import', method: 'POST' },
  ];

  constructor(private authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isYoutubeImporterPath = this.importerPaths.some(
      (path) => request.url.includes(path.url) && request.method === path.method
    );

    const token = this.authService.getStoreToken();

    if (isYoutubeImporterPath) request = this.addApiKey(request);
    else if (token) request = this.addToken(request, token);

    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `${
          request.url === this.url ? `Tenant-Key ${localStorage.getItem('accessKey')}` : `${this.TOKEN_KEY} ${token}`
        }`,
      },
    });
  }

  private addApiKey(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: env.qmsUploaderAPIKey,
      },
    });
  }
}
