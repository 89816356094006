import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import {
  BroadcastStudioService,
  DeviceInfo,
  DeviceSetting,
  Graphic,
  GraphicWebgl,
  LiveDemoSourceType,
  LiveSetting,
  SourceItem,
} from '@app/shared';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Screen, VideoCameraChromaKeyService } from '@shared/services/videoCameraChromaKey';
import { VideocamerawebglService } from '@shared/services/videoCameraWebgl';
import { cloneDeep } from 'lodash';
import { concat, Observable } from 'rxjs';
import { ConnectSocialMediaDialogComponent } from '@shared/components/connect-social-media-dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@app/cms/templates/components/angular/confirm-dialog';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AuthService } from '@app/auth/services';

@Component({
  selector: 'app-broadcast-studio-setting-dialog',
  templateUrl: './broadcast-studio-setting-dialog.component.html',
  styleUrls: ['./broadcast-studio-setting-dialog.component.scss'],
})
export class BroadcastStudioSettingDialogComponent implements OnInit, AfterViewInit {
  public micInputs: DeviceInfo[] = [];
  public videoInputs: DeviceInfo[] = [];
  public speakerOutputs: DeviceInfo[] = [];
  public deviceSetting: DeviceSetting = {
    audioInputDevice: null,
    audioOutputDevice: null,
    videoDevice: null,
  };
  public defaultVideoDeviceId = '';
  public defaultAudioInputDeviceId = '';
  public defaultAudioOutputDeviceId = '';
  backgroundImages: Graphic[] = [];
  backgroundUploading = false;
  isLoading = true;
  show = true;
  loading = false;
  optionGraphicWebgl: GraphicWebgl = { similarity: 0, smoothness: 0, spill: 0, color: 'green' };
  optionGraphicWebglSelected: GraphicWebgl = { similarity: 0, smoothness: 0, spill: 0, color: 'green' };
  source: MediaStream;
  @ViewChild('videoCamera', { static: false })
  videoCamera: ElementRef<HTMLVideoElement>;
  @ViewChild('outputCanvas', { static: false })
  outputCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('outputCanvasWeb', { static: false })
  outputCanvasWeb: ElementRef<HTMLCanvasElement>;
  @ViewChild('inputUploadBackground', { static: false })
  inputUploadBackground: ElementRef<HTMLInputElement>;
  studio_uuid: any;
  item: SourceItem;
  liveStudioSetting: LiveSetting;
  liveStudioSettingCache: LiveSetting;
  backgroundGreenScreen: any = {
    uuid: 'none',
    source: '',
    optionWebgl: this.optionGraphicWebgl,
  } as any;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    public broadcastStudioService: BroadcastStudioService,
    private notify: NotifierService,
    public videoCameraChromaKeyService: VideoCameraChromaKeyService,
    public videocamerawebglService: VideocamerawebglService,
    public dialogRef: MatDialogRef<BroadcastStudioSettingDialogComponent>,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.studio_uuid = data.studio_uuid;
    this.item = data.item;
  }

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  selectBackgroundGreenScreen(bg: Graphic) {
    if (bg == null || !bg.source) {
      this.backgroundGreenScreen = {
        media_uuid: null,
        studio_uuid: this.studio_uuid,
        type: 5,
      };
    } else {
      this.backgroundGreenScreen = {
        media_uuid: bg.uuid,
        studio_uuid: this.studio_uuid,
        type: 5,
      };
    }
  }

  handleSelectBackgroundImage(bg: Graphic, type: string) {
    const liveStudioSetting = this.liveStudioSetting || {};
    const { backgroundVideo } = liveStudioSetting || {};
    if (bg?.uuid === backgroundVideo?.uuid) {
      return;
    }
    let backgroundImage = {
      uuid: 'none',
      source: '',
      optionWebgl: { similarity: 0, smoothness: 0, spill: 0, color: 'green' },
    };
    if (type !== 'none') {
      if (backgroundVideo.uuid === 'none') {
        // revert value Webgl when before it selected none
        this.optionGraphicWebgl = Object.assign({}, this.optionGraphicWebglSelected);
      }
      backgroundImage = {
        uuid: bg.uuid,
        source: bg.source,
        optionWebgl: this.optionGraphicWebgl,
      };
      if (!this.videoCameraChromaKeyService.isStreaming(this.item.id, Screen.LIVE_PREVIEW)) {
        this.videoCameraChromaKeyService.startStream(this.item.id, Screen.LIVE_PREVIEW);
      }
    } else {
      this.optionGraphicWebgl = { similarity: 0, smoothness: 0, spill: 0, color: 'green' };
      this.videoCameraChromaKeyService.stop(this.item.id, Screen.LIVE_PREVIEW);
    }
    this.optionGraphicWebglSelected = Object.assign({}, backgroundVideo?.optionWebgl);

    this.selectBackgroundGreenScreen(bg);
    this.backgroundImages = this.backgroundImages.map((bgr) =>
      bgr.uuid === bg?.uuid ? { ...bgr, is_active: !bgr.is_active } : { ...bgr, is_active: false }
    );
    this.liveStudioSetting.backgroundVideo = backgroundImage;
    this.broadcastStudioService.setLiveStudioSettingPreview(this.liveStudioSetting);
  }

  getBackgroundSrc(): string {
    const { backgroundVideo } = this.liveStudioSetting || {};
    return (backgroundVideo && backgroundVideo?.source) || '';
  }

  getWebgl(option: any) {
    return {
      similarity: option.similarity,
      smoothness: option.smoothness,
      spill: option.spill,
      color: option.color,
    };
  }

  onChangeWebgl() {
    const { backgroundVideo } = this.liveStudioSetting || {};
    if (backgroundVideo) {
      backgroundVideo.optionWebgl = this.getWebgl(this.optionGraphicWebgl);
      this.liveStudioSetting = {
        backgroundVideo,
        ...this.liveStudioSetting,
      };
      this.broadcastStudioService.setLiveStudioSettingPreview(this.liveStudioSetting);
    }
  }

  getItem() {
    return this.broadcastStudioService.sourceItems$.pipe(
      map((items) => items.filter((item) => item.active && item.is_main))
    );
  }

  onSave() {
    this.onChangeWebgl();
    const _observables: Array<Observable<any>> = [];
    if (this.backgroundGreenScreen?.media_uuid !== this.liveStudioSettingCache?.backgroundVideo?.uuid) {
      _observables.push(this.broadcastStudioService.setGraphicsDefault(this.backgroundGreenScreen));
    }
    const settings = {
      greenScreenSettings:
        this.backgroundGreenScreen.media_uuid != null
          ? this.liveStudioSetting.backgroundVideo?.optionWebgl
          : { similarity: 0, smoothness: 0, spill: 0, color: 'green' },
      sourceItems: this.broadcastStudioService.sourceItems.filter((item) => item.type !== LiveDemoSourceType.GUEST),
      sourceItemsActive: this.broadcastStudioService.sourceItemsActive.filter(
        (item) => item.type !== LiveDemoSourceType.GUEST
      ),
      bannerTickerStyle: this.liveStudioSetting.bannerTickerStyle,
      isOpenMic: this.liveStudioSetting.isOpenMic,
      isOpenCamera: this.liveStudioSetting.isOpenCamera,
      deviceSetting: this.deviceSetting,
    };
    _observables.push(this.broadcastStudioService.saveSetting(this.studio_uuid, { settings }));
    this.loading = true;
    concat(..._observables).subscribe(
      (rs) => {
        if (!rs?.message || !rs?.message.includes('successfully')) {
          this.loading = false;
          this.notifier.notify('error', 'Error system');
          return;
        }
      },
      (err) => {
        this.loading = false;
        this.notifier.notify('error', err);
      },
      () => {
        this.loading = false;
        this.broadcastStudioService.toggleCamera(this.source);
        this.broadcastStudioService.setLiveStudioSetting({
          ...this.liveStudioSetting,
          deviceSetting: this.deviceSetting,
        });
        this.streamVideo();
        this.notifier.notify('success', 'Setting successfully saved');
        this.dialogRef.close({});
      }
    );
  }

  onCancel() {
    this.broadcastStudioService.setLiveStudioSetting(this.liveStudioSettingCache);
    this.streamVideo();
  }

  streamVideo() {
    if ((window as any).stopCamera === false) {
      this.videoCameraChromaKeyService.reInit(this.item.id, Screen.LIVE_ITEM);
      this.videoCameraChromaKeyService.reInit(this.item.id, Screen.LIVE_MAIN);
    }
  }

  onSelectAudioOutput(event: MatOptionSelectionChange, device: DeviceInfo) {
    if (event.isUserInput) {
      this.deviceSetting = { ...this.deviceSetting, audioOutputDevice: device };
      this.broadcastStudioService.liveStudioSetting = {
        ...this.broadcastStudioService.liveStudioSetting,
        deviceSetting: this.deviceSetting,
      };
    }
  }

  async onSelectAudioInput(event: MatOptionSelectionChange, device: DeviceInfo) {
    if (event.isUserInput) {
      this.deviceSetting = { ...this.deviceSetting, audioInputDevice: device };
      await this.getSource();
    }
  }

  async onSelectVideo(event: MatOptionSelectionChange, device: DeviceInfo) {
    console.log('device==>', device);
    if (event.isUserInput) {
      this.deviceSetting = { ...this.deviceSetting, videoDevice: device };
      await this.getSource();
    }
  }

  async getSource() {
    try {
      this.source = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: this.deviceSetting.audioInputDevice.id },
        video: { deviceId: this.deviceSetting.videoDevice.id },
      });
      this.source.getTracks()[0].enabled = this.liveStudioSetting.isOpenMic;
      this.source.getTracks()[1].enabled = this.liveStudioSetting.isOpenCamera;
    } catch (error) {
      console.log(error);
    }
  }

  async setupDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log('devices==>', devices);
      for (const item of devices) {
        const { deviceId, kind, label } = item;
        switch (kind) {
          case 'audioinput':
            this.micInputs.push({
              id: deviceId,
              label: label || `microphone ${this.micInputs.length + 1}`,
            });
            break;
          case 'audiooutput':
            this.speakerOutputs.push({
              id: deviceId,
              label: label || `speaker ${this.speakerOutputs.length + 1}`,
            });
            break;
          case 'videoinput':
            this.videoInputs.push({
              id: deviceId,
              label: label || `camera ${this.videoInputs.length + 1}`,
            });
            break;

          default:
            this.notify.notify('error', 'Some other kind of source/device');
            break;
        }
      }
      console.log('videoInputs==>', this.videoInputs);
    } catch (error) {
      console.log(error);
    }
  }

  isSettingWebgl(): boolean {
    const { backgroundVideo } = this?.liveStudioSetting || {};
    const { similarity = 0, smoothness = 0, spill = 0 } = backgroundVideo?.optionWebgl || {};
    return !(similarity === 0 && smoothness === 0 && spill === 0);
  }

  getActiveBackground() {
    const { backgroundVideo } = this.liveStudioSetting || {};
    return backgroundVideo && backgroundVideo?.source !== null && backgroundVideo?.source !== '';
  }

  async ngOnInit(): Promise<void> {
    this.broadcastStudioService.getListStudioMaterials(this.studio_uuid).subscribe(
      async (materials) => {
        if (materials && materials.length) {
          let backgroundActive: Graphic;
          const backgrounds = (materials as Graphic[])
            .filter((item) => item.type === 5)
            .map((i) => {
              if (i.is_active) {
                backgroundActive = i;
              }
              return {
                ...i,
                source: `${environment.mediaUrl}/${this.authService.getTenant()}/studio/material/${i.source}`,
              };
            });
          if (backgroundActive) {
            this.selectBackgroundGreenScreen(backgroundActive);
          }
          this.backgroundImages = [
            {
              uuid: 'none',
              type: 0,
              is_active: !backgroundActive,
              source: null,
            },
            ...backgrounds,
          ];
        }
        const liveStudioSetting = cloneDeep(this.broadcastStudioService.liveStudioSetting || {});
        this.liveStudioSettingCache = cloneDeep(this.broadcastStudioService.liveStudioSetting || {});
        if (!liveStudioSetting.backgroundVideo) {
          liveStudioSetting.backgroundVideo = {
            uuid: 'none',
            source: null,
            optionWebgl: null,
          };
        }
        const optionWebgl = liveStudioSetting.backgroundVideo?.optionWebgl;
        if (optionWebgl) {
          this.optionGraphicWebgl = { ...optionWebgl };
          this.optionGraphicWebglSelected = Object.assign({}, optionWebgl);
        }
        this.liveStudioSetting = liveStudioSetting;
        this.deviceSetting = liveStudioSetting.deviceSetting;
        this.broadcastStudioService.setLiveStudioSettingPreview(cloneDeep(this.liveStudioSetting));
        this.defaultVideoDeviceId = this.deviceSetting?.videoDevice?.id;
        this.defaultAudioInputDeviceId = this.deviceSetting?.audioInputDevice?.id;
        this.defaultAudioOutputDeviceId = this.deviceSetting?.audioOutputDevice?.id;
        const media = document.getElementsByClassName('video-cam')[0] as HTMLVideoElement;
        if (media) {
          media.muted = true;
          media.volume = 0;
        }
        await this.getSource();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
    this.getItem().subscribe((items) => {
      if (items && items.length > 0) {
        this.source = items[0].source;
      }
    });
  }

  getFileExtension(filename: string) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? '' : ext[1];
  }

  handleUploadFile(files: FileList, type?: number) {
    let file = files[0];
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.notify.notify('warning', 'Only images are supported');
      return;
    }
    const ext = this.getFileExtension(file.name);
    const data = {
      studio_uuid: this.studio_uuid,
      end: true,
      ext,
      offset: 0,
      current_chunk: file.size,
      type,
      meta_data: {
        similarity: 0,
        smoothness: 0,
        spill: 0,
        color: 'green',
      },
    };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('options', JSON.stringify(data));
    this.backgroundUploading = true;
    this.broadcastStudioService.uploadGraphic(formData).subscribe(
      (res) => {
        const item: Graphic = {
          uuid: res.uuid,
          type,
          is_active: false,
          meta_data: { similarity: 0, smoothness: 0, spill: 0, color: 'green' },
        };
        this.backgroundImages.push({
          ...item,
          source: `${environment.mediaUrl}/${this.authService.getTenant()}/studio/material/${res.uuid}.${ext}`,
        });
        this.backgroundUploading = false;
      },
      (err) => {
        this.notifier.notify('error', err);
      },
      () => {
        this.backgroundUploading = false;
        this.inputUploadBackground.nativeElement.value = '';
      }
    );
  }

  onChangeDropdown() {
    this.show = !this.show;
  }

  openDialogLinkSocial() {
    this.onCancel();
    const dialogCSMRef = this.dialog.open(ConnectSocialMediaDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: { studioId: this.studio_uuid },
    });
    dialogCSMRef.afterClosed().subscribe((dialogResult) => {});
  }

  removeBackgroundImage(material: Graphic) {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete selected item?', '');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '470px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.handleRemoveBackgroundImage(material);
      }
    });
  }

  handleRemoveBackgroundImage(graphicItem: Graphic) {
    this.isLoading = true;
    this.broadcastStudioService.removeSingleGraphic(graphicItem.uuid).subscribe(
      (res) => {
        this.backgroundImages = this.backgroundImages.filter((bgImage) => bgImage.uuid !== graphicItem.uuid);
        if (graphicItem.is_active) {
          this.handleSelectBackgroundImage(
            {
              uuid: 'none',
              type: 0,
              is_active: true,
              source: null,
              meta_data: { similarity: 0, smoothness: 0, spill: 0, color: 'green' },
            },
            ''
          );
          this.broadcastStudioService
            .setGraphicsDefault({
              media_uuid: null,
              studio_uuid: this.studio_uuid,
              type: 5,
            })
            .subscribe((res) => {});
        }
        this.notifier.notify('success', res.message);
      },
      (err) => {
        this.notifier.notify('error', err);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
