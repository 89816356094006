import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import {
  BroadcastStudioService,
  DeviceInfo,
  DeviceSetting,
  LiveDemoSourceType,
  Graphic,
  GraphicWebgl,
  LiveSetting,
  SourceItem,
} from '@app/shared';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Screen, VideoCameraChromaKeyService } from '@shared/services/videoCameraChromaKey';
import { VideocamerawebglService } from '@shared/services/videoCameraWebgl';
import { cloneDeep } from 'lodash';
import { concat, Observable } from 'rxjs';
import { ConnectSocialMediaDialogComponent } from '@shared/components/connect-social-media-dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@app/cms/templates/components/angular/confirm-dialog';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-add-cam-source-dialog',
  templateUrl: './add-cam-source-dialog.component.html',
  styleUrls: ['./add-cam-source-dialog.component.scss'],
})
export class AddCamSourceDialogComponent implements OnInit {
  public micInputs: DeviceInfo[] = [];
  public videoInputs: DeviceInfo[] = [];
  public speakerOutputs: DeviceInfo[] = [];
  public defaultVideoDeviceId = '';
  show = true;
  loading = false;
  isEdit = false;
  optionGraphicWebgl: GraphicWebgl = { similarity: 0, smoothness: 0, spill: 0, color: 'green' };
  source: MediaStream;
  @ViewChild('videoCamera', { static: true })
  videoCamera: ElementRef<HTMLVideoElement>;
  @ViewChild('outputCanvas', { static: true })
  outputCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('outputCanvasWeb', { static: true })
  outputCanvasWeb: ElementRef<HTMLCanvasElement>;
  studio_uuid: any;
  item: SourceItem;
  liveStudioSetting: LiveSetting;
  backgroundGreenScreen: any = {
    uuid: 'none',
    source: '',
    optionWebgl: this.optionGraphicWebgl,
  } as any;

  constructor(
    private dialog: MatDialog,
    public broadcastStudioService: BroadcastStudioService,
    private notify: NotifierService,
    public videoCameraChromaKeyService: VideoCameraChromaKeyService,
    public videocamerawebglService: VideocamerawebglService,
    public dialogRef: MatDialogRef<AddCamSourceDialogComponent>,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.studio_uuid = data.studio_uuid;
    this.item = data?.item;
    this.isEdit = data?.isEdit;
  }

  async ngOnInit(): Promise<void> {
    await this.setupDevices();
    const previewStream = this.videoCameraChromaKeyService.addStreamScreen(
      this.videoCamera.nativeElement,
      this.outputCanvas,
      this.outputCanvasWeb,
      this.item.id,
      Screen.LIVE_PREVIEW
    );
    const liveStudioSetting = cloneDeep(this.broadcastStudioService.liveStudioSetting || {});
    if (!liveStudioSetting.backgroundVideo) {
      liveStudioSetting.backgroundVideo = {
        uuid: 'none',
        source: null,
        optionWebgl: null,
      };
    }
    const optionWebgl = liveStudioSetting.backgroundVideo?.optionWebgl;
    if (optionWebgl) {
      this.optionGraphicWebgl = { ...optionWebgl };
    }
    this.liveStudioSetting = liveStudioSetting;
    this.broadcastStudioService.setLiveStudioSettingPreview(cloneDeep(this.liveStudioSetting));
    if (!this.isEdit) {
      this.item = {
        ...this.item,
        deviceSetting: {
          videoDevice: this.videoInputs.length > 0 && this.videoInputs[0],
        },
      };
    }
    this.defaultVideoDeviceId = this.item?.deviceSetting?.videoDevice?.id;
    const media = document.getElementsByClassName('video-cam')[0] as HTMLVideoElement;
    if (media) {
      media.muted = true;
      media.volume = 0;
    }
    await this.getSource();

    if (this.getActiveBackground()) {
      previewStream.startStream(this.item.id, Screen.LIVE_PREVIEW);
    }
  }

  getBackgroundSrc(): string {
    const { backgroundVideo } = this.liveStudioSetting || {};
    return (backgroundVideo && backgroundVideo?.source) || '';
  }

  getWebgl(option: any) {
    return {
      similarity: option.similarity,
      smoothness: option.smoothness,
      spill: option.spill,
      color: option.color,
    };
  }

  onSave() {
    this.item.is_main = true;
    if (!this.isEdit) {
      this.broadcastStudioService.addSourceItem(this.item);
    }
    const settings = {
      greenScreenSettings:
        this.backgroundGreenScreen.media_uuid != null
          ? this.liveStudioSetting.backgroundVideo?.optionWebgl
          : { similarity: 0, smoothness: 0, spill: 0, color: 'green' },
      sourceItems: this.broadcastStudioService.sourceItems.filter((item) => item.type !== LiveDemoSourceType.GUEST),
      sourceItemsActive: this.broadcastStudioService.sourceItemsActive.filter(
        (item) => item.type !== LiveDemoSourceType.GUEST
      ),
      bannerTickerStyle: this.liveStudioSetting.bannerTickerStyle,
      isOpenMic: this.liveStudioSetting.isOpenMic,
      isOpenCamera: this.liveStudioSetting.isOpenCamera,
      deviceSetting: this.liveStudioSetting.deviceSetting,
    };
    this.broadcastStudioService.saveSetting(this.studio_uuid, { settings }).subscribe(
      (rs) => {
        if (!rs?.message || !rs?.message.includes('successfully')) {
          this.loading = false;
          this.notifier.notify('error', 'Error system');
          return;
        }
      },
      (err) => {
        this.loading = false;
        this.notifier.notify('error', err);
      },
      () => {
        this.loading = false;
        this.streamVideo();
        this.notifier.notify('success', 'Setting successfully saved');
        this.dialogRef.close({ source: this.source });
      }
    );
  }

  onCancel() {
    this.streamVideo();
  }

  streamVideo() {
    this.videoCameraChromaKeyService.stop(this.item.id, Screen.LIVE_PREVIEW);
    if (this.isEdit) {
      if ((window as any).stopCamera === false) {
        this.videoCameraChromaKeyService.reInit(this.item.id, Screen.LIVE_ITEM);
        this.videoCameraChromaKeyService.reInit(this.item.id, Screen.LIVE_MAIN);
      }
    }
  }

  async onSelectVideo(event: MatOptionSelectionChange, device: DeviceInfo) {
    if (event.isUserInput) {
      this.item.deviceSetting = { ...this.item.deviceSetting, videoDevice: device };
      await this.getSource();
    }
  }

  async getSource() {
    try {
      this.source = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { deviceId: this.item.deviceSetting.videoDevice.id },
      });
      if (this.source?.getVideoTracks()[0]) {
        this.source.getVideoTracks()[0].enabled = this.liveStudioSetting.isOpenCamera;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async setupDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      for (const item of devices) {
        const { deviceId, kind, label } = item;
        switch (kind) {
          case 'audioinput':
          case 'audiooutput':
            break;
          case 'videoinput':
            this.videoInputs.push({
              id: deviceId,
              label: label || `camera ${this.videoInputs.length + 1}`,
            });
            break;

          default:
            this.notify.notify('error', 'Some other kind of source/device');
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  isSettingWebgl(): boolean {
    const { backgroundVideo } = this?.liveStudioSetting || {};
    const { similarity = 0, smoothness = 0, spill = 0 } = backgroundVideo?.optionWebgl || {};
    return !(similarity === 0 && smoothness === 0 && spill === 0);
  }

  getActiveBackground() {
    const { backgroundVideo } = this.liveStudioSetting || {};
    return backgroundVideo && backgroundVideo?.source !== null && backgroundVideo?.source !== '';
  }
}
