import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { StreamAccount } from '@app/shared/domain';
import { StreamAccountService } from '@app/shared/services/stream-account/stream-account.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-restream-account-edit-dialog',
  templateUrl: './restream-account-edit-dialog.component.html',
  styleUrls: ['./restream-account-edit-dialog.component.scss'],
})
export class RestreamAccountEditDialogComponent implements OnInit {
  public streamAccount: StreamAccount;
  public accountSettingForm: FormGroup;
  public isLoading: boolean;
  constructor(
    public dialogRef: MatDialogRef<RestreamAccountEditDialogComponent>,
    private streamAccountService: StreamAccountService,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: RestreamAccountEditDialogModel
  ) {
    this.streamAccount = data.streamAccount;
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.accountSettingForm = this.formBuilder.group({
      channelName: [this.streamAccount.name, [Validators.required, NoWhitespaceValidator()]],
      streamKey: [this.streamAccount.stream_key, [Validators.required, NoWhitespaceValidator()]],
      serverUrl: [this.streamAccount.server_url, [Validators.required, NoWhitespaceValidator()]],
      provider: [this.streamAccount.provider, [Validators.required, NoWhitespaceValidator()]],
    });
  }

  get f() {
    return this.accountSettingForm.controls;
  }

  handleSave() {
    if (this.accountSettingForm.invalid) {
      CommonHelpers.validateAllFormFields(this.accountSettingForm);
      return;
    }
    this.isLoading = true;
    const { channelName, streamKey, serverUrl, provider } = this.accountSettingForm.value;
    const slug = CommonHelpers.createSlug(channelName);
    const newStreamAccount = {
      name: channelName,
      provider,
      stream_key: streamKey,
      server_url: serverUrl,
      slug,
    } as StreamAccount;
    this.streamAccountService.updateAccount(newStreamAccount, this.streamAccount.uuid).subscribe(
      (res) => {
        this.notifier.notify('success', `Successfully created account`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}

export class RestreamAccountEditDialogModel {
  constructor(public streamAccount: StreamAccount) {}
}
