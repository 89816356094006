import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Video } from '@app/shared';

@Component({
  selector: 'app-duplicate-video-dialog',
  templateUrl: './duplicate-video-dialog.component.html',
  styleUrls: ['./duplicate-video-dialog.component.scss'],
})
export class DuplicateVideoDialogComponent implements OnInit {
  video: Video;
  isRename: boolean;
  title: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DuplicateVideoDialogComponentModel,
    public dialogRef: MatDialogRef<DuplicateVideoDialogComponent>
  ) {
    this.video = data.video;
  }

  handleReplaceVideo() {
    this.dialogRef.close(true);
  }
  handleUploadReName() {
    this.dialogRef.close({
      title: this.title,
    });
  }
  handleCancel() {
    this.dialogRef.close(false);
  }

  ngOnInit(): void {}
}

export class DuplicateVideoDialogComponentModel {
  constructor(public video: Video) {}
}
