<h1 mat-dialog-title>Please select the category you want to add</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoadingDefault"></app-spinner>
  <mat-selection-list #cats [hidden]="isLoadingDefault">
    <mat-list-option *ngFor="let cat of categories" [value]="cat.uuid">
      <label [title]="cat.title | uppercase">
        {{ cat.title | uppercase }}
      </label>
    </mat-list-option>
  </mat-selection-list>
</div>

<div mat-dialog-actions>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]=""
    (click)="handleAddToCategory(cats.selectedOptions.selected)"
    [disabled]="cats.selectedOptions.selected.length === 0"
  >
    Add
  </button>
</div>
