import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { PingMonitorService } from '@shared/services/pingMonitor';
import { environment } from '@env/environment';

@Component({
  selector: 'icon-network-connection',
  templateUrl: './network-connection.component.html',
  styleUrls: ['./network-connection.component.scss'],
})
export class NetworkConnectionComponent implements OnInit, OnDestroy {
  lower = false;
  percent = 0;
  totalCol = 3;

  constructor(private elementRef: ElementRef, private pingMonitorService: PingMonitorService) {}

  ngOnInit() {
    const self = this;
    this.pingMonitorService.start({
      url: `${environment.wsHeartbeatUrl}`,
      config: { interval: 3, packetsNumber: 1, intervalUnits: 'seconds' },
    });

    this.pingMonitorService.on('up', (rs) => {
      self.lower = false;
      self.percent = rs.percent;
    });

    this.pingMonitorService.on('lower', (rs) => {
      self.percent = rs.percent;
      self.lower = true;
    });

    this.pingMonitorService.on('down', (rs) => {
      self.percent = 0;
      self.lower = false;
    });
  }

  getStatusByPercent(percent: number, colCurrent: number): string {
    const start = colCurrent >= 1 ? (colCurrent - 1) / this.totalCol : 0;
    const end = colCurrent / this.totalCol;
    return percent > 0 && ((percent >= start && percent <= end) || percent >= end)
      ? this.lower
        ? 'lower'
        : 'active'
      : 'none';
  }

  ngOnDestroy() {
    this.pingMonitorService.stop();
  }
}
