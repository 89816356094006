import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { SharedModule } from '@app/shared';
import { LayoutsModule } from './layouts/layouts.module';
import { AppComponent } from './app.component';
import { CoreModule, I18nService } from '@app/core';
import { AppRouting } from './app.routing';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppSettings } from './app.settings';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DatePipe } from '@angular/common';
import { LanguageModule } from './i18n/language.module';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    AppRouting,
    CoreModule,
    LayoutsModule,
    FlexLayoutModule,
    NotifierModule.withConfig(customNotifierOptions),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    LanguageModule,
  ],
  declarations: [AppComponent],
  providers: [AppSettings, DatePipe, I18nService],
  bootstrap: [AppComponent],
})
export class AppModule {}
