import { Playlist } from './../shared/domain/playlist.domain';
export const configConstans = {
  playlistStatuses: {
    DRAFT: {
      value: 'DRAFT',
      display: 'DRAFT',
    },
    SCHEDULED: {
      value: 'SCHEDULED',
      display: 'SCHEDULED',
    },
    LOOPING: {
      value: 'LOOPING',
      display: 'LOOPING',
    },
    NOWPLAYING: {
      value: 'NOWPLAYING',
      display: 'NOW PLAYING',
    },
    UPNEXT: {
      value: 'UPNEXT',
      display: 'UP NEXT',
    },
    COMPLETED: {
      value: 'COMPLETED',
      display: 'COMPLETED',
    },
  },
  playlistModes: {
    MANUAL: {
      value: 'MANUAL',
      display: 'MANUAL',
    },
    SCHEDULED: {
      value: 'SCHEDULED',
      display: 'SCHEDULED',
    },
  },
  categoryStatuses: {
    VISIBLE: {
      value: 'VISIBLE',
      display: 'VISIBLE',
    },
    INVISIBLE: {
      value: 'INVISIBLE',
      display: 'INVISIBLE',
    },
  },
  videoCategoryTypes: {
    STANDARD: {
      value: 'STANDARD',
      display: 'STANDARD CATEGORY',
    },
    VIDEO: {
      value: 'VIDEO',
      display: 'VOD PLAYLIST',
    },
  },
  audioCategoryTypes: {
    STANDARD: {
      value: 'STANDARD',
      display: 'STANDARD CATEGORY',
    },
    AUDIO: {
      value: 'AUDIO',
      display: 'AUDIO PLAYLIST',
    },
  },

  recordingMode: {
    MANUAL: {
      value: 'MANUAL',
      display: 'MANUAL MODE',
    },
    AUTO: {
      value: 'AUTO',
      display: 'AUTO MODE',
    },
  },

  recordingStatus: {
    SUBMITTED: 'SUBMITTED',
    PROGRESSING: 'PROGRESSING',
    COMPLETED: 'COMPLETED',
  },

  reStreamType: {
    EDGE: {
      value: 'EDGE',
      display: 'Edge',
    },
    LIVE: {
      value: 'LIVE',
      display: 'Live',
    },
  },

  reStreamMode: {
    AUTO: {
      value: 'AUTO',
      display: 'Auto',
    },
    MANUAL: {
      value: 'MANUAL',
      display: 'Manual',
    },
  },

  userType: {
    CMS_ADMIN: '1',
    SYSTEM_ADMIN: '2',
    SUBSCRIBER: '3',
    CMS_STAFF: '4',
  },
};
