<div [style.position]="'relative'" class="flex-p">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue()"
          [indeterminate]="selection.hasValue()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell *matHeaderCellDef>Thumbnails</th>
      <td mat-cell *matCellDef="let row">
        <img class="media-thumb" [src]="getThumbnail(row)" [alt]="row?.title" />
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <!-- <td mat-cell *matCellDef="let row">{{ row?.title || '_' }}</td> -->
      <div *matCellDef="let row" class="mat-cell video-title-cell title-cell">
        {{ row.title || '_' }}
      </div>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <!-- <td mat-cell *matCellDef="let row">{{ row?.description || '_' }}</td> -->
      <div *matCellDef="let row" class="mat-cell video-title-cell">
        {{ row?.description || '_' }}
      </div>
    </ng-container>
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
      <td mat-cell *matCellDef="let row">{{ row?.duration | durationRounded }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <!-- <td mat-cell *matCellDef="let row">{{ row?.job_status }}</td> -->
      <td mat-cell *matCellDef="let row">
        {{ row?.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Deleted</th>
      <td mat-cell *matCellDef="let row">
        {{ row?.updated ? (row.updated | date: 'MM-dd-yyyy h:mm a') : '_' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" [style.padding]="'10px'" class="align-right">
        <button mat-flat-button class="actions-button" [matMenuTriggerFor]="menu" color="primary">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="handleRestore(row)"><mat-icon>restore</mat-icon>Restore</button>
          <button mat-menu-item (click)="handleDelete(row)"><mat-icon>delete</mat-icon>Delete</button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="6">
        <app-empty [description]="'No videos'"></app-empty>
      </td>
    </tr>
  </table>
  <mat-paginator
    showFirstLastButtons
    [length]="params.total"
    [pageIndex]="params.page - 1"
    [pageSize]="params.pageSize"
    [pageSizeOptions]="params.pageSizeOptions"
    (page)="pageChanged($event)"
  >
  </mat-paginator>
</div>
