import { Component, Input, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  color: string;
  showFooter = true;
  year = new Date().getFullYear();
  private langChangeSubscription: Subscription;

  constructor(
    private footerService: FooterService,
    private i18nService: I18nService,
    private translateService: TranslateService
  ) {
    this.footerService.subject.subscribe((showFooter: boolean) => {
      this.showFooter = showFooter;
    });
  }

  ngOnInit() {
    this.langChangeSubscription = this.i18nService.langChange$.subscribe((language) => {
      this.refreshComponent(language);
    });
  }

  private refreshComponent(language: string): void {
    this.translateService.use(language);
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
