<h1 mat-dialog-title>Publish Website</h1>
<div *ngIf="!submitted" mat-dialog-content>
  <div class="info">Are you sure you want to deploy this website?</div>
  <div class="info">This will trigger the website deployment with the domain you specified</div>
</div>
<div *ngIf="!submitted" mat-dialog-actions class="action-btn">
  <button [disabled]="isLoading" mat-flat-button class="mat-small" color="grey" (click)="dialogRef.close(submitted)">
    Cancel
  </button>
  <button [loading]="isLoading" mat-flat-button class="mat-small" color="primary" (click)="handleDeploy()">
    Deploy
  </button>
</div>
<div *ngIf="submitted && !isTvsDomain" class="pl-result">
  <h4>Website Deployed!</h4>
  <div class="info">
    Login to your domain provider account and set these as custom name servers on the DNS settings of your domain:
  </div>
  <div class="info">{{ getNameServers() }}</div>
  <br />
  <div class="info">
    It might take 1 to 48 hours for the propagation to complete with the custom domain, frequent checking of the website
    with the url is suggested.
  </div>
  <br />
  Website Link: <a [href]="getLink()" target="_blank">{{ getLink() }}</a> <br /><br />
  <button mat-flat-button color="primary" (click)="dialogRef.close(true)">Close</button>
</div>

<div *ngIf="submitted && isTvsDomain" class="pl-result">
  <h4>The Deployment is now in progress!</h4>
  <br />
  <div class="info">
    Please wait for about 1 to 5 minutes for the deployment process to finish. Then try to access the website link
    below.
  </div>
  <br />
  <a [href]="getLink()" target="_blank">{{ getLink() }}</a> <br /><br />
  <button mat-flat-button color="primary" (click)="dialogRef.close(true)">Close</button>
</div>
