<ng-container *ngIf="projectLoading$ | async as projectLoading; else noProjectLoading">
  <button mat-button [color]="color" [class]="buttonClass" [loading]="projectLoading">Loading Project</button>
</ng-container>
<ng-template #noProjectLoading>
  <ng-container *ngIf="projectList$ | async as projectList; else noProjectList">
    <button mat-button [color]="color" [class]="buttonClass" [matMenuTriggerFor]="menu">
      <ng-container *ngIf="activeProject$ | async as activeProject; else noActiveProject">
        {{ activeProject.name }}
      </ng-container>
      <ng-template #noActiveProject> No Active Project </ng-template>
      <mat-icon *ngIf="projectList.length > 1">expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngIf="projectList.length; else tplEmpty">
        <ng-container *ngFor="let project of projectList">
          <button
            (click)="changeActiveProject(project)"
            *ngIf="project.uuid !== (activeProject$ | async)?.uuid"
            mat-menu-item
          >
            <mat-icon>swap_horiz</mat-icon>
            <span>Switch to {{ project.name }}</span>
          </button>
        </ng-container>
      </ng-container>
      <ng-template #tplEmpty>
        <button mat-menu-item>No chanel available!</button>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-template>
<ng-template #noProjectList>
  <button mat-button [color]="color" [class]="buttonClass">No Project</button>
</ng-template>
