import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Observable } from 'rxjs';
import { map } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private apiService: ApiService) {}

  getListPaymentGateway(): Observable<any> {
    return this.apiService.get(`/api/v1/gateway-setting/list/`);
  }

  savePaymentGateway(data: any[]): Observable<any> {
    return this.apiService.post(`/api/v1/gateway-setting/save-bulk/`, data);
  }

  syncStripePaymentGateway(): Observable<any> {
    return this.apiService.get(`/api/v1/gateway-setting/sync-stripe-webhook/`);
  }

  getListSubscriptionPlan(channelId: string, data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get(`/api/v1/subscription-plan/${channelId}/list/`, params);
  }

  saveSubscriptionPlans(channelId: string, data: any): Observable<any> {
    return this.apiService.post(`/api/v1/subscription-plan/${channelId}/save-bulk/`, data);
  }

  updateSubscriptionPlans(channelId: string, data: any): Observable<any> {
    return this.apiService.put(`/api/v1/subscription-plan/${channelId}/save-bulk/`, data);
  }

  removeSubscriptionPlan(channelId: string, subscriptionPlanId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/subscription-plan/${channelId}/remove-single/${subscriptionPlanId}`);
  }

  getPricingContent(channelId: string, contentId: string): Observable<any> {
    return this.apiService.get(`/api/v1/pricing/${channelId}/single/${contentId}/`);
  }

  getPricingList(content_type: any, data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get(`/api/v1/pricing/packages-list/${content_type}/`, params);
  }

  savePricingContent(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/pricing/save-single/`, data);
  }
}
