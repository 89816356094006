import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { StreamAccount } from '@app/shared/domain';
import { map } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StreamAccountService {
  constructor(private apiService: ApiService) {}

  createAccount(streamAccount: StreamAccount): Observable<any> {
    return this.apiService.post(`/api/v1/stream-accounts/`, streamAccount);
  }

  updateAccount(streamAccount: StreamAccount, accountId: string): Observable<any> {
    return this.apiService.put(`/api/v1/stream-accounts/${accountId}/`, streamAccount);
  }

  getAllAccounts(data: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get(`/api/v1/stream-accounts/`);
  }

  getAccountById(accountId: string): Observable<any> {
    return this.apiService.get(`/api/v1/stream-accounts/${accountId}/`);
  }

  deleteAccount(accountId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/stream-accounts/${accountId}/`);
  }

  toggleStream(accountId: string, active: boolean): Observable<any> {
    return this.apiService.put(`/api/v1/stream-accounts/${accountId}/activate/`, { active });
  }

  getReStream() {
    return this.apiService.get(`/api/v1/restreams/`);
  }

  changeStream(streamId: string, data: any) {
    return this.apiService.put(`/api/v1/restreams/${streamId}/save/`, data);
  }

  startReStream(channelId: string) {
    return this.apiService.get(`/api/v1/livestream/rtmp-endpoint/${channelId}/broadcasting/`);
  }

  stopReStream(channelId: string) {
    return this.apiService.get(`/api/v1/livestream/rtmp-endpoint/${channelId}/finished/`);
  }

  getChannelReStreamStatus(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/livestream/rtmp-endpoint/${channelId}/status/`);
  }
}
