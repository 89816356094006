import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Template } from '@app/shared/domain/template.domain';
import { getThemeThumbnail } from '../../../helpers/render';
import { environment } from '@env/environment';
import { TutorialType } from '@app/app.constants';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit {
  isProduction: any = environment.production;

  @Input() templates: any[];
  @Input() isTemplateView: boolean;
  @Output() previewTheme: EventEmitter<any> = new EventEmitter();
  @Output() selectTheme: EventEmitter<any> = new EventEmitter();
  @Output() openCreateNewTemplate: EventEmitter<any> = new EventEmitter();

  get TutorialType() {
    return TutorialType;
  }

  constructor() {}

  ngOnInit(): void {}

  preview(template: Template): void {
    this.previewTheme.emit(template);
  }

  select(template: Template): void {
    this.selectTheme.emit(template);
  }

  createNewTemplate(): void {
    this.openCreateNewTemplate.emit();
  }

  renderThemeThumbnail(name: string) {
    return getThemeThumbnail(name);
  }
}
