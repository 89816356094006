import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppSettingService {
  constructor(private apiService: ApiService) {}

  getAppSetting(tenantUuid: string): Observable<any> {
    return this.apiService.get(`/api/v1/app/configs/?tenant_id=${tenantUuid}`);
  }

  getAppSettingVersion(tenantUuid: string): Observable<any> {
    return this.apiService.get(`/api/v1/app/configs/version/?tenant_id=${tenantUuid}`);
  }

  saveAppSetting(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/app/configs/save/`, data);
  }

  saveAppSettingVersion(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/app/configs-version/save/`, data);
  }
  reportBug(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/email/report-bug/`, data);
  }

  getAppConfigJSON(tenantUuid: string): Observable<any> {
    return this.apiService.get(`/api/v1/app/configs/get-json-configs/?tenant_id=${tenantUuid}`);
  }

  submitAppConfigJSON(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/app/configs/add-json-configs/`, data);
  }
}
