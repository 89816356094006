<h1 mat-dialog-title class="text-center" fxLayoutAlign="center center">
  {{ title }}
</h1>
<div mat-dialog-content *ngIf="message">
  <div style="padding: 10px 0px">{{ message }}</div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center" style="margin-top: 20px">
  <button
    mat-flat-button
    [color]="button_text === 'Cancel' || button_text === 'Delete' ? 'danger' : 'primary'"
    (click)="onConfirm()"
  >
    {{ button_text }}
  </button>
  <button mat-stroked-button color="primary" (click)="onDismiss()" *ngIf="button_text != 'Include ad video'">
    Cancel
  </button>
  <button mat-stroked-button color="primary" (click)="onDeny()" *ngIf="button_text == 'Include ad video'">
    Do not include ad video
  </button>
</div>
