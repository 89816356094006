<h1 mat-dialog-title class="text-center" fxLayoutAlign="center center">
  {{ title }}
</h1>
<div mat-dialog-content>
  <p>{{ message }}</p>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
  <button mat-flat-button class="mat-small" color="primary" (click)="onConfirm()">Yes</button>
  <button mat-flat-button class="mat-small" color="grey" (click)="onDismiss()">No</button>
</div>
