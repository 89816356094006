<div>
  <section class="gallery-info">
    <h2>THEME GALLERY</h2>
    <p>Preview or select your new theme.</p>
  </section>
  <div class="cta-wrapper" *ngIf="!isProduction && isTemplateView" fxLayoutAlign="end center">
    <app-tutorial-button [tutorialType]="TutorialType.WebsiteBuilder" [relative]="true"></app-tutorial-button>
    <a class="nw-btn" mat-flat-button color="primary" (click)="createNewTemplate()">New Template</a>
  </div>
  <section [style.position]="'relative'" class="theme-list">
    <div class="theme-card" *ngFor="let template of templates" fxLayout="column" fxLayoutAlign="center center">
      <img [src]="renderThemeThumbnail(template.name)" alt="{{ template.name }}" />
      <div class="card-utils">
        <p>{{ template.name }}</p>
        <button mat-flat-button color="basic" (click)="preview(template)">Preview Theme</button>
        <button mat-flat-button color="primary" (click)="select(template)">Select Theme</button>
      </div>
    </div>
  </section>
</div>
