import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { gapi } from 'gapi-script';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BroadcastStudioMainService {
  link = new Subject();
  submitFormHandler = new BehaviorSubject(false);
  handleRandomKey = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  async streamToYoutube() {
    const createBroadcast = await gapi.client.youtube.liveBroadcasts.insert({
      part: ['id,snippet,contentDetails,status'],
      resource: {
        snippet: {
          title: `Testing Video For Restream`,
          scheduledStartTime: `${new Date().toISOString()}`,
          description: 'A description of your video stream. This field is optional.',
        },
        contentDetails: {
          recordFromStart: true,
          // startWithSlate: true,
          enableAutoStart: false,
          monitorStream: {
            enableMonitorStream: false,
          },
        },
        status: {
          privacyStatus: 'public',
          selfDeclaredMadeForKids: true,
        },
      },
    });
    const broadcastId = createBroadcast.result.id;

    const bindData = await gapi.client.youtube.liveBroadcasts.bind({
      part: ['id,snippet,contentDetails,status'],
      id: broadcastId,
      streamId: localStorage.getItem('youtubeStreamId'),
    });
    const transition = async () => {
      try {
        const response = await gapi.client.youtube.liveBroadcasts.transition({
          part: ['id,snippet,contentDetails,status'],
          broadcastStatus: 'live',
          id: broadcastId,
        });
      } catch (err) {
        transition();
      }
    };
    transition();
  }

  detectMyBrowser() {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }
    return browserName;
  }

  storeProcessId(res: any) {
    if (res.code == 200) {
      localStorage.setItem('processId', JSON.stringify(res.data));
    }
  }

  goLiveToSelectedPlatforms(platforms: any) {
    return this.http.post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', platforms);
  }

  submitFormHandlerStatus(status: any) {
    this.submitFormHandler.next(status);
  }

  handleRandomKeyStatus(status: any) {
    this.handleRandomKey.next(status);
  }
}
