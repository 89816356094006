<div class="container mt-5">
  <div style="display: flex; align-items: center; justify-content: space-between">
    <div>
      <div class="modal-header" *ngIf="type == 'youtube'">
        <img class="youtube-icon" src="assets/images/youtube2.png" alt="Youtube" />
        <h2>YouTube</h2>
      </div>

      <div class="modal-header" *ngIf="type == 'vimeo'">
        <img class="youtube-icon" src="assets/images/vimeo-full.png" alt="Youtube" style="width: 80px" />
      </div>

      <div class="modal-header" *ngIf="type == 'dropbox'">
        <img class="youtube-icon" src="assets/images/dropbox-full.png" alt="Youtube" style="width: 100px" />
      </div>
    </div>

    <div>
      <button
        mat-raised-button
        color="primary"
        class="mr-2"
        *ngIf="importSpecificVideo || importVideosFromChannel"
        (click)="backBtn()"
      >
        Back
      </button>
    </div>
  </div>

  <div class="option-wrapper">
    <div class="option-section" *ngIf="!importSpecificVideo && !importVideosFromChannel">
      <div *ngIf="type != 'youtube'">
        <button mat-raised-button color="primary" type="submit" class="mr-2" (click)="selectImportStyle('channel')">
          Import Videos Using <span *ngIf="type == 'youtube' || type == 'vimeo'">Channel</span>
          <span *ngIf="type == 'dropbox'">Folder Path or Folder Url</span>
        </button>
      </div>

      <div style="padding: 10px" *ngIf="type != 'youtube'">or</div>

      <div>
        <button mat-stroked-button color="primary" type="submit" class="mr-2" (click)="selectImportStyle('specific')">
          {{
            type == 'dropbox'
              ? 'Import Specific Video Using File Path or File Url'
              : 'Import Specific Video Using Video Url'
          }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="importVideosFromChannel">
    <form [formGroup]="channelForm" class="bg-light p-3 mb-3" style="margin-top: 10px" (ngSubmit)="fetchVideos()">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>
          <span *ngIf="type == 'youtube'">Channel ID or Channel Url:</span>
          <span *ngIf="type == 'vimeo'">Channel Url</span>
          <span *ngIf="type == 'dropbox'">Folder Path or Folder Url</span>
        </mat-label>
        <input matInput formControlName="channelId" />
        <mat-error *ngIf="channelForm.controls.channelId.invalid && channelForm.controls.channelId.touched">
          Please enter a valid Channel ID.
        </mat-error>
      </mat-form-field>
      <br />
      <div class="d-flex">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="mr-2"
          [disabled]="channelForm.invalid || isFetching"
        >
          Fetch Videos
        </button>
        <button
          mat-raised-button
          color="success"
          type="button"
          class="ms-2"
          [disabled]="selectedVideos.length === 0"
          (click)="handleImportVideos()"
        >
          Submit Selected Videos
        </button>
      </div>
    </form>
    <br />
    <div
      class="modal"
      id="loadingModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loadingModalLabel"
      aria-hidden="true"
      *ngIf="isFetching"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-transparent border-0">
          <div class="modal-body text-center" style="width: 75%; margin: auto">
            <img
              src="https://i.pinimg.com/originals/47/af/82/47af824852bb6fb9f31e13d3e76a86be.gif"
              alt="Loading..."
              class="img-fluid"
            />

            <mat-progress-bar mode="indeterminate" style="margin: 10px 0px"></mat-progress-bar>
          </div>
        </div>
      </div>
    </div>

    <div id="videoResults" *ngIf="videos.length != 0 && !isFetching" style="width: 100%; padding-bottom: 2px">
      <div class="row">
        <div *ngFor="let video of videos; let i = index">
          <div class="card" (click)="toggleCardSelection(video)" [ngClass]="{ 'card-body-selected': video.isSelected }">
            <img
              [src]="
                type == 'dropbox'
                  ? video.thumbnail
                  : video.thumbnail_url
                  ? video.thumbnail_url
                  : '/assets/images/no-image.png'
              "
              class="card-img-top"
              [alt]="video.title"
              style="height: 100px"
            />
            <div class="card-body">
              <h5 class="card-title">{{ video.title ? video.title : video.name }}</h5>
              <div style="margin: 0px auto">
                <a
                  class="watch-button"
                  [href]="video.video_url ? video.video_url : 'https://www.dropbox.com/home' + video.path_display"
                  target="_blank"
                  ><mat-icon aria-hidden="false" fontIcon="remove_red_eye"></mat-icon> <span>Watch Video</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div style="text-align: center">
        <mat-spinner color="primary" diameter="30" *ngIf="loadingMoreVideos" style="margin: auto"> </mat-spinner>
        <button
          mat-raised-button
          color="primary"
          type="button"
          class="mr-2"
          (click)="loadMoreVideos()"
          [disabled]="isLoadingDisabled()"
          *ngIf="!loadingMoreVideos"
        >
          Load More
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="importSpecificVideo">
    <form [formGroup]="videoForm" class="bg-light p-3 mb-3" style="margin-top: 10px">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label> {{ type == 'dropbox' ? 'Video File Path or Video File Url' : 'Video Url:' }} </mat-label>
        <input matInput formControlName="videoUrl" />
        <mat-error *ngIf="videoForm.controls.videoUrl.invalid && videoForm.controls.videoUrl.touched">
          Please enter {{ type == 'dropbox' ? 'Video File Path' : 'Video Url' }}
        </mat-error>
      </mat-form-field>
      <br />
      <div class="d-flex">
        <button
          mat-raised-button
          color="success"
          type="button"
          class="ms-2"
          [disabled]="videoForm.invalid"
          (click)="handleImportVideos()"
        >
          Import Video
        </button>
      </div>
    </form>
    <br />
  </div>
</div>
