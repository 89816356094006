import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LiveDemoSourceType, SourceItem } from '@app/shared/domain';
import { BroadcastStudioService } from '@app/shared/services';
import { BroadcastStudioEditAvatarDialogComponent } from '../broadcast-studio-edit-avatar-dialog';

import {
  BroadcastStudioEditNameDialogComponent,
  BroadcastStudioEditNameDialogModel,
} from '../broadcast-studio-edit-name-dialog';
import { Screen, VideoCameraChromaKeyService } from '@shared/services/videoCameraChromaKey';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@app/cms/templates/components/angular/confirm-dialog';

import { MatSliderChange } from '@angular/material/slider';
import { NotifierService } from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { AddCamSourceDialogComponent } from '@app/cms/broadcast-studio/components/add-cam-source-dialog/add-cam-source-dialog.component';
import { LiveScreenIndex } from '@app/app.constants';

@UntilDestroy()
@Component({
  selector: 'app-broadcast-studio-source-item',
  templateUrl: './broadcast-studio-source-item.component.html',
  styleUrls: ['./broadcast-studio-source-item.component.scss'],
})
export class BroadcastStudioSourceItemComponent implements OnInit, AfterViewInit {
  LiveDemoSourceType = LiveDemoSourceType;
  @Input() item: SourceItem;
  @Output() removeItem = new EventEmitter<string>();
  @ViewChild('slider')
  slider: any;
  @ViewChild('timeMedia')
  timeMedia: any;

  @ViewChild('outputCanvas', { static: false })
  outputCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('outputCanvasWeb', { static: false })
  outputCanvasWeb: ElementRef<HTMLCanvasElement>;
  @ViewChild('videoRef', { static: false })
  videoRef: ElementRef<HTMLVideoElement>;

  @ViewChild('videoCamera', { static: false })
  videoCamera: ElementRef<HTMLVideoElement>;

  @ViewChild('webcamFrame', { static: true })
  webcamFrame: ElementRef<HTMLDivElement>;
  mediaStream: MediaStream;
  isPlay = false;

  constructor(
    private dialog: MatDialog,
    private notify: NotifierService,
    private broadcastStudioService: BroadcastStudioService,
    private videoCameraChromaKeyService: VideoCameraChromaKeyService
  ) {}

  getBackgroundSrc(): string {
    const { backgroundVideo } = this.broadcastStudioService.liveStudioSetting || {};
    return (backgroundVideo && backgroundVideo?.source) || '';
  }

  handleEditItem() {
    const data = new BroadcastStudioEditNameDialogModel(this.item.title);
    const dialogRef = this.dialog.open(BroadcastStudioEditNameDialogComponent, {
      width: '400px',
      data,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.item.title = dialogResult;
        this.broadcastStudioService.setSourceItemsActive();
        this.saveSourceItem();
        this.broadcastStudioService.notifyOtherPeers({
          type: 'name_change',
          data: {
            id: this.item.id,
            title: dialogResult,
          },
        });
      }
    });
  }

  handleChangeAvatar() {
    const dialogRef = this.dialog.open(BroadcastStudioEditAvatarDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.item.avatar = dialogResult.src;
        this.broadcastStudioService.setSourceItemsActive();
        this.saveSourceItem();
      }
    });
  }

  async toggleChange(value: MatSlideToggleChange) {
    const { displayName } = this.broadcastStudioService.liveStudioSetting;
    this.broadcastStudioService.toggleItem(this.item);
    this.saveSourceItem();
    if (
      value.checked &&
      (this.item.type === LiveDemoSourceType.LOCAL_VIDEO || this.item.type === LiveDemoSourceType.LOCAL_AUDIO)
    ) {
      this.UpdateSyncMedia();
    }
    if (value.checked) {
      const isElementLoaded = async (selector: any) => {
        while (document.querySelector(selector) === null) {
          await new Promise((resolve) => requestAnimationFrame(resolve));
        }
        return document.querySelector(selector);
      };
      isElementLoaded(`#video_sync_${this.item.id}`).then(async () => {
        setTimeout(async () => {
          if (this.item.maximize) {
            const video = document.getElementById(`video_sync_${this.item.id}`) as any;
            video.setAttribute('data-full-screen-flag', 'true');
          }
        }, 0);
      });
    } else {
      this.removeSourceStream(displayName);
    }
  }

  toggleVideo(event: any, videoRef: HTMLMediaElement) {
    if (event.checked) {
      videoRef.play();
      this.isPlay = true;
    } else {
      this.isPlay = false;
      videoRef.pause();
    }
  }

  removeSourceStream(displayName: boolean) {}

  UpdateSyncMedia() {
    const video = document.getElementById(`video_${this.item.id}`) as any;
    let stream;
    const fps = 0;
    if (video.captureStream) {
      stream = video.captureStream(fps);
    } else if (video.mozCaptureStream) {
      stream = video.mozCaptureStream(fps);
    } else {
      console.error('Stream capture is not supported');
      stream = null;
    }
    this.item.stream = stream;
    this.broadcastStudioService.setSourceItemsActive();
  }

  handleMaximize() {
    this.broadcastStudioService.toggleMaximize(this.item);
    if (this.item.active) {
      if (this.item.maximize) {
        const video = document.getElementById(`video_sync_${this.item.id}`) as any;
        video.setAttribute('data-full-screen-flag', 'true');
      }
      this.broadcastStudioService.notifyOtherPeers({
        type: 'maximize',
        data: this.item,
      });
    }
    const { displayName } = this.broadcastStudioService.liveStudioSetting || {};
    this.saveSourceItem();
  }

  getActiveBackground() {
    const { backgroundVideo } = this.broadcastStudioService.liveStudioSetting || {};
    return backgroundVideo && backgroundVideo?.source !== null && backgroundVideo?.source !== '';
  }

  isSettingWebgl() {
    return this.broadcastStudioService.isSettingWebgl();
  }

  removeSource(id: string) {
    const { backgroundVideo, displayName } = this.broadcastStudioService.liveStudioSetting || {};
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete this source item?', '');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '470px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.removeItem.emit(id);
        this.saveSourceItem();
        this.removeSourceStream(displayName);
      }
    });
  }

  ngOnInit(): void {
    this.broadcastStudioService.liveStudioSetting$
      .pipe(
        untilDestroyed(this),
        filter((setting) => !!setting),
        distinctUntilChanged(),
        map((setting) => setting.deviceSetting),
        filter((deviceSetting) => !!deviceSetting),
        distinctUntilChanged(),
        map((deviceSetting) => deviceSetting.audioOutputDevice),
        filter((audioOutputDevice) => !!audioOutputDevice),
        map((audioOutputDevice) => audioOutputDevice.id),
        distinctUntilChanged(),
        tap((audioOutputId) => {
          if (this.item?.type === LiveDemoSourceType.GUEST && this.item?.source?.peer) {
            const media = document.getElementById(`video-item${this.item.source.user_id}`) as HTMLVideoElement;
            (media as any)?.setSinkId(audioOutputId).then(() => {
              console.log(`GUEST Success, audio output device attached`);
            });
          } else if (
            this.item?.type === LiveDemoSourceType.LOCAL_VIDEO ||
            this.item?.type === LiveDemoSourceType.LOCAL_AUDIO
          ) {
            console.log(`video_${this.item.id}`);
            (document.getElementById(`video_${this.item.id}`) as any)?.setSinkId(audioOutputId).then(() => {
              console.log(`LOCAL_VIDEO or LOCAL_AUDIO Success, audio output device attached`);
            });
          }
        })
      )
      .subscribe();
    this.broadcastStudioService.liveStudioSetting$
      .pipe(
        untilDestroyed(this),
        filter((setting) => !!setting),
        distinctUntilChanged(),
        map((setting) => setting.isOpenCamera),
        tap((isOpenCamera) => {
          if (this.item.type === LiveDemoSourceType.SUB_CAMERA) {
            if (this.mediaStream?.getVideoTracks()[0]) {
              this.mediaStream.getVideoTracks()[0].enabled = isOpenCamera;
            }
            if (isOpenCamera) {
              this.item.source = this.mediaStream;
            } else {
              this.item.source = undefined;
            }
          }
        })
      )
      .subscribe();
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      if (this.item.type === LiveDemoSourceType.SUB_CAMERA) {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { deviceId: this.item.deviceSetting.videoDevice.id },
        });
        if (this.mediaStream?.getVideoTracks()[0]) {
          this.mediaStream.getVideoTracks()[0].enabled = this.broadcastStudioService.liveStudioSetting.isOpenCamera;
        }
        if (this.broadcastStudioService.liveStudioSetting.isOpenCamera) {
          this.item.source = this.mediaStream;
        }
      }
      if (this.videoCamera && this.videoCamera.nativeElement) {
        const mainLiveStream = this.videoCameraChromaKeyService.addStreamScreen(
          this.videoCamera.nativeElement,
          this.outputCanvas,
          this.outputCanvasWeb,
          this.item.id,
          Screen.LIVE_ITEM
        );

        if (
          (this.item.type === LiveDemoSourceType.SUB_CAMERA ||
            this.item.type === LiveDemoSourceType.MAIN_CAMERA ||
            this.item.type === LiveDemoSourceType.SCREEN_SHARE) &&
          this.item.source != null &&
          this.getActiveBackground() &&
          mainLiveStream.isStreaming(this.item.id, Screen.LIVE_ITEM) === false
        ) {
          mainLiveStream.startStream(this.item.id, Screen.LIVE_ITEM);
        }

        if (this.item.type === LiveDemoSourceType.MAIN_CAMERA || this.item.type === LiveDemoSourceType.SUB_CAMERA) {
          this.videoCamera.nativeElement.muted = true;
          this.videoCamera.nativeElement.volume = 0;
        }
      }
      //add event update time
      const videoRef = this.videoRef?.nativeElement;
      const _this = this;
      if (videoRef) {
        this.eventControlMedia(videoRef);
      }
      if (this.item.active) {
        videoRef.onloadeddata = function () {
          _this.UpdateSyncMedia();
          videoRef.play();
          setTimeout(function () {
            videoRef.pause();
          }, 50);
          if (_this.item.active && _this.item.maximize) {
            setTimeout(function () {
              const video = document.getElementById(`video_sync_${_this.item.id}`) as any;
              video.setAttribute('data-full-screen-flag', 'true');
            }, 100);
          }
        };
      }
    } catch (e) {
      console.log(e.toString());
    }

    if (this.item?.type === LiveDemoSourceType.GUEST && this.item?.source?.peer) {
      const media = document.getElementById(`video-item${this.item.source.user_id}`) as HTMLVideoElement;
      media.srcObject = this.item.stream;
      media.muted = true;
      media.load();
      media.play().catch(null);
    }
  }

  eventControlMedia(videoRef: HTMLVideoElement) {
    const self = this;
    //  Capture time changes and display current position
    videoRef.muted = !this.item.has_volume;
    videoRef.volume = this.item.volume || 0;
    this.slider.value = this.item.volume || 0;

    videoRef.addEventListener(
      'timeupdate',
      function () {
        self.timeMedia.value = videoRef.currentTime.toFixed(2);
      },
      false
    );
    videoRef.onended = function () {
      self.isPlay = false;
      self.timeMedia.value = 0;
      videoRef.currentTime = 0;
      self.UpdateSyncMedia();
    };
  }

  onChangeVolume(videoRef: HTMLVideoElement, event: MatSliderChange) {
    videoRef.volume = event.value;
    this.item.volume = event.value;
    this.saveSourceItem();
  }

  onChangeSeek(videoRef: HTMLVideoElement, event: MatSliderChange) {
    videoRef.currentTime = event.value;
  }

  onClickVolume(videoRef: HTMLVideoElement) {
    this.item.has_volume = !this.item.has_volume;
    if (this.item.has_volume) {
      videoRef.muted = false;
      videoRef.onloadeddata;
    } else {
      videoRef.muted = true;
    }
    this.saveSourceItem();
  }

  onMediaSeeked(e: any) {
    if (this.item.active) {
      this.broadcastStudioService.notifyOtherPeers({
        type: 'media_action',
        data: {
          id: this.item.id,
          name: 'seeked',
          currentTime: e.target.currentTime,
        },
      });
    }
  }

  play(elementRef: HTMLMediaElement) {
    var isPlaying =
      elementRef.currentTime > 0 &&
      !elementRef.paused &&
      !elementRef.ended &&
      elementRef.readyState > elementRef.HAVE_CURRENT_DATA;
    if (!isPlaying) {
      elementRef.play().catch(null);
    }
  }

  onPlayMedia(elementRef: HTMLMediaElement) {
    this.isPlay = true;
    this.item.play = this.isPlay;
    this.play(elementRef);
    if (this.item.active) {
      this.broadcastStudioService.notifyOtherPeers({
        type: 'media_action',
        data: {
          id: this.item.id,
          name: 'play',
        },
      });
    }
  }

  onPauseMedia(elementRef: HTMLMediaElement) {
    this.isPlay = false;
    this.item.play = this.isPlay;
    elementRef.pause();
    if (this.item.active) {
      this.broadcastStudioService.notifyOtherPeers({
        type: 'media_action',
        data: {
          id: this.item.id,
          name: 'pause',
        },
      });
    }
  }

  saveSourceItem() {
    const { backgroundVideo, uuid, isOpenCamera, isOpenMic, deviceSetting, bannerTickerStyle } =
      this.broadcastStudioService.liveStudioSetting;
    const settings = {
      greenScreenSettings: backgroundVideo?.optionWebgl,
      sourceItems: this.broadcastStudioService.sourceItems.filter((item) => item.type !== LiveDemoSourceType.GUEST),
      sourceItemsActive: this.broadcastStudioService.sourceItemsActive.filter(
        (item) => item.type !== LiveDemoSourceType.GUEST
      ),
      bannerTickerStyle,
      isOpenCamera,
      isOpenMic,
      deviceSetting,
    };
    this.broadcastStudioService.saveSetting(uuid, { settings }).subscribe(
      (rs) => {},
      (err) => {
        this.notify.notify('error', err);
      }
    );
  }

  handleEditCamera() {
    const { uuid } = this.broadcastStudioService.liveStudioSetting;
    const dialogRef = this.dialog.open(AddCamSourceDialogComponent, {
      width: '600px',
      data: {
        studio_uuid: uuid,
        item: this.item,
        isEdit: true,
      },
    });
    dialogRef.afterClosed().subscribe((rs) => {
      if (rs) {
        this.item.source = rs.source;
      }
    });
  }
}
