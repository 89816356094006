import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImporterApiService } from './video-importer.component.service';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { ChannelService } from '@app/shared';
import { filter } from 'rxjs/operators';
import { AuthService } from '@app/auth/services';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmDialogModel, ConfirmDialogComponent } from '@app/shared';
import { environment as env } from '@env/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-video-importer',
  templateUrl: './video-importer.component.html',
  styleUrls: ['./video-importer.component.scss'],
})
export class VideoImporterComponent implements OnInit {
  importSpecificVideo: boolean | false;
  importVideosFromChannel: boolean | false;
  public activeChannel$ = this.channelService.getActiveChannel();
  channelForm: FormGroup;
  videoForm: FormGroup;
  nextPageToken: string | null = null;
  selectedVideos: string[] = [];
  videosPerPage = 6;
  loadingMoreVideos = false;
  isFetching: boolean = false;
  videos: any[] = [];
  showLoadMoreButton = true;
  selectedVideosUrl: any[] = [];
  specificVideoUrl: string | null;
  activeChannelId: string | null = null;
  tenantId: string;
  type: string = null;
  raw_data: any = {};
  public params = {
    next_page_token: null as string | null,
    channel_url: null as string | null,
    channel_id: null as string | null,
    limit: null as number | null,
    oath_token: null as string | null,
    page: null as number | null,
    dbx_token: null as string | null,
    extensions: null as string | null,
    path: null as string | null,
    url: null as string | null,
    with_thumbnail: null as boolean | null,
  };
  file_extensions = 'mp4,mkv,mov,flv';

  ngOnInit(): void {}

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private importerApiService: ImporterApiService,
    private channelService: ChannelService,
    private authService: AuthService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<VideoImporterModel>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
    this.initChannelForm();
    this.initVideoForm();
    this.fetchActiveChannelAndUserTenantId();
  }

  private initChannelForm(): void {
    this.channelForm = this.formBuilder.group({
      channelId: ['', Validators.required],
    });
  }

  private initVideoForm(): void {
    this.videoForm = this.formBuilder.group({
      videoUrl: ['', Validators.required],
    });
  }

  private fetchActiveChannelAndUserTenantId(): void {
    forkJoin({
      activeChannel: this.activeChannel$.pipe(
        filter((channel) => !!channel),
        take(1)
      ),
      user: this.authService.getAccountInfo().pipe(
        filter((user) => !!user),
        take(1)
      ),
    }).subscribe(({ activeChannel, user }) => {
      this.activeChannelId = activeChannel.uuid;
      this.tenantId = user.tenant_id;
    });
  }

  selectImportStyle(choice: string) {
    if (choice == 'channel') {
      this.importSpecificVideo = false;
      this.importVideosFromChannel = true;
    } else {
      this.importSpecificVideo = true;
      this.importVideosFromChannel = false;
    }
  }

  fetchVideos() {
    if (this.channelForm.invalid) {
      return;
    }

    this.isFetching = true;
    const channelId = this.channelForm.value.channelId;

    let channelURL: string | null = null;
    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    if (urlPattern.test(channelId)) {
      channelURL = channelId;
    }
    if (channelURL) {
      this.params.channel_url = channelURL;
    } else {
      this.params.channel_id = channelId;
    }

    this.params.limit = 10;

    if (this.type == 'vimeo') {
      this.params.oath_token = localStorage.getItem('vimeo_token');
      this.params.page = 1;
    } else if (this.type == 'dropbox') {
      if (this.params.channel_url) {
        this.params.channel_url = this.params.channel_url.replace('%20', ' ');
        this.params.url = this.params.channel_url;
        this.params.extensions = this.file_extensions;
        this.params.dbx_token = localStorage.getItem('dropbox_token');
        this.params.with_thumbnail = true;
        console.log(this.params);
        delete this.params.channel_url;
      } else {
        this.params.path = channelId == '/' ? '' : channelId;
        this.params.extensions = this.file_extensions;
        this.params.dbx_token = localStorage.getItem('dropbox_token');
        this.params.with_thumbnail = true;
        delete this.params.channel_id;
      }
    }

    delete this.params.next_page_token;
    this.importerApiService.fetchVideos(this.params, this.type).subscribe(
      (data: any) => {
        this.isFetching = false;
        if (data.code === 200) {
          this.raw_data = data.data;
          // this.videos = data.data.videos;
          if (this.type == 'dropbox') {
            this.videos = data.data.files;
          } else {
            this.videos = data.data.videos;
          }

          if (this.type == 'dropbox' || this.type == 'youtube') {
            this.nextPageToken = data.data.next_page_token;
          }
        } else {
          this.notifier.notify('error', data.message);
        }
      },
      (error) => {
        this.isFetching = false;
        this.notifier.notify('error', error);
      }
    );

    this.params.channel_id = null;
    this.params.channel_url = null;
  }

  loadMoreVideos() {
    if (this.nextPageToken || this.type == 'vimeo') {
      this.params.next_page_token = this.nextPageToken;
      this.loadingMoreVideos = true;

      const channelId = this.channelForm.value.channelId;
      let channelURL: string | null = null;
      const urlPattern = /^(http|https):\/\/[^ "]+$/;

      if (urlPattern.test(channelId)) {
        channelURL = channelId;
      }

      if (channelURL) {
        this.params.channel_url = channelURL;
      } else {
        this.params.channel_id = channelId;
      }

      this.params.limit = 10;

      if (this.type == 'vimeo') {
        this.params.oath_token = localStorage.getItem('vimeo_token');
        this.params.page = this.params.page + 1;
      } else if (this.type == 'dropbox') {
        if (this.params.url) {
          this.params.extensions = this.file_extensions;
          this.params.dbx_token = localStorage.getItem('dropbox_token');
          this.params.with_thumbnail = true;
          console.log(this.params);
          delete this.params.channel_url;
        } else {
          this.params.extensions = this.file_extensions;
          this.params.dbx_token = localStorage.getItem('dropbox_token');
          this.params.with_thumbnail = true;
          delete this.params.channel_id;
        }
      }

      this.importerApiService.fetchVideos(this.params, this.type).subscribe(
        (data: any) => {
          this.loadingMoreVideos = false;
          if (data.code === 200) {
            this.raw_data.page = data.data.page;
            this.raw_data.total = data.data.total;
            let videos = this.type == 'dropbox' ? data.data.files : data.data.videos;
            this.videos.push(...videos);
            this.nextPageToken = data.data.next_page_token;
          } else {
            alert(`Error: ${data.message}`);
          }
        },
        (error) => {
          this.loadingMoreVideos = false;
          console.error('Error:', error);
        }
      );
      this.params.channel_id = null;
      this.params.channel_url = null;
    }
  }

  toggleCardSelection(video: any) {
    if (!video.isSelected) {
      video.isSelected = false;
    }

    video.isSelected = !video.isSelected;
    let video_url = this.type == 'dropbox' ? video.path_display : video.video_url;
    if (video.isSelected) {
      this.selectedVideos.push(video_url);
    } else {
      const index = this.selectedVideos.indexOf(video_url);
      if (index !== -1) {
        this.selectedVideos.splice(index, 1);
      }
    }
  }

  importVideo() {
    if (this.selectedVideos.length > 0) {
      let formData = {};

      if (this.type == 'youtube') {
        formData = {
          video_urls: this.selectedVideos,
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '4000kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'vimeo') {
        formData = {
          video_urls: this.selectedVideos,
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'dropbox') {
        formData = {
          file_paths: this.selectedVideos,
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
          dbx_token: localStorage.getItem('dropbox_token'),
          // 'sl.Bx6BJbXmXAznqdmPqQivO-a4B3QvmabIukWWNB48kcYZimJRqpfhfIKdAfIpw0NhSBrcFLKA3c0OS-PZghhdeg6shvHsxZrxaYpTg8WQXfApEDsWXuojXrvcJWR7IaczHNJELV7eliX9yHg',
        };
      }

      this.importerApiService.importVideos(formData, this.type).subscribe(
        (data: any) => {
          this.notifier.notify('success', data.data);
        },
        (error) => {
          this.notifier.notify('error', error);
        }
      );
    }
  }

  importSpecificVideoFunc() {
    this.specificVideoUrl = this.videoForm.value.videoUrl;
    if (this.specificVideoUrl) {
      let formData = {};

      if (this.type == 'youtube') {
        formData = {
          video_urls: [this.specificVideoUrl],
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '4000kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'vimeo') {
        formData = {
          video_urls: [this.specificVideoUrl],
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
        };
      } else if (this.type == 'dropbox') {
        this.specificVideoUrl = this.transformURL(this.specificVideoUrl);
        if (!this.specificVideoUrl) {
          this.notifier.notify('error', 'Import failed');
          return;
        }
        formData = {
          file_paths: [this.specificVideoUrl],
          channel_uid: this.activeChannelId,
          tenant_uid: this.tenantId,
          encoding_profile: '500kbps',
          env: env.qmsEnvServer,
          dbx_token: localStorage.getItem('dropbox_token'),
          // 'sl.Bx6BJbXmXAznqdmPqQivO-a4B3QvmabIukWWNB48kcYZimJRqpfhfIKdAfIpw0NhSBrcFLKA3c0OS-PZghhdeg6shvHsxZrxaYpTg8WQXfApEDsWXuojXrvcJWR7IaczHNJELV7eliX9yHg',
        };
      }

      this.importerApiService.importVideos(formData, this.type).subscribe(
        (data: any) => {
          this.notifier.notify('success', data.data);
          this.videoForm.reset();
        },
        (error) => {
          this.notifier.notify('error', error);
        }
      );
    }
  }

  handleImportVideos() {
    const dialogData = new ConfirmDialogModel(
      'Note',
      'Kindly note that there may be a delay in imported videos appearing in the video library. We appreciate your patience during this process.',
      'Confirm'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '360px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.importVideosFromChannel) {
          this.importVideo();
        } else {
          this.importSpecificVideoFunc();
        }
      }
    });
  }

  backBtn() {
    this.importSpecificVideo = false;
    this.importVideosFromChannel = false;
  }

  isLoadingDisabled() {
    if (this.type == 'youtube' || this.type == 'dropbox') {
      return !this.nextPageToken;
    } else if (this.type == 'vimeo') {
      return this.videos.length >= this.raw_data.total;
    }
  }

  // for dropbox only
  transformURL(inputURL: string): string {
    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    if (!urlPattern.test(inputURL)) {
      return inputURL;
    }
    console.log('og', inputURL);
    // Remove "https://www.dropbox.com/home" from the URL
    let trimmedURL = '';
    if (inputURL.includes('https://www.dropbox.com/home')) {
      trimmedURL = inputURL.replace('https://www.dropbox.com/home', '');
    } else if (inputURL.includes('https://www.dropbox.com/personal')) {
      trimmedURL = inputURL.replace('https://www.dropbox.com/personal', '');
    } else if (inputURL.includes('https://www.dropbox.com/preview')) {
      trimmedURL = inputURL.replace('https://www.dropbox.com/preview', '');
    } else {
      this.notifier.notify('error', 'Invalid url');
      return;
    }

    trimmedURL = trimmedURL.replace(/%20/g, ' ');
    trimmedURL = trimmedURL.replace('?preview=', '/');
    console.log(trimmedURL);
    return `${trimmedURL}`;
  }
}

export class VideoImporterModel {
  constructor(public type: any) {}
}
