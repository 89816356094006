<h1 mat-dialog-title class="text-center" fxLayoutAlign="center center">Video On Demand Clip Settings</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isFetching"></app-spinner>
  <ng-container *ngIf="!isFetching">
    <div fxLayout="row" fxLayoutGap="16px" class="mb-16">
      <div [fxFlex]="100" fxFlex.gt-sm="45">
        <mat-label fxLayoutAlign="start center" class="text-weight-bold">
          Assign Banner Image:<mat-icon class="icon-grey">help</mat-icon>
        </mat-label>
        <div>
          <mat-label> *Image must be 1920x1080 pixels </mat-label>
        </div>
        <button mat-flat-button color="grey" (click)="fileInput.click()" class="mat-small w-100 flex-m-y">
          Upload File
        </button>
        <input #fileInput type="file" hidden (change)="handleChange($event.target.files)" />
      </div>
      <div [fxFlex]="100" fxFlex.gt-sm="55">
        <img
          class="banner-preview"
          [src]="previewThumbnail || video.banner || '/assets/images/no-image.png'"
          alt="image"
        />
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="16px">
      <div [fxFlex]="100" fxFlex.gt-sm="45">
        <mat-label fxLayoutAlign="start center" class="text-weight-bold">
          Assign Trailer/Preview Clip:<mat-icon class="icon-grey">help</mat-icon>
        </mat-label>
        <button mat-flat-button color="grey" class="mat-small w-100 flex-m-y" (click)="handleOpenAssignTrailer()">
          Select From Library
        </button>
        <div *ngIf="selectedVideo || video.trailer">
          <mat-label class="text-weight-bold">Selected:</mat-label>
          <div>{{ selectedVideo?.title || video?.trailer?.title }}</div>
          <div>Duration {{ selectedVideo?.duration || video?.trailer?.duration }}</div>
        </div>
        <button mat-flat-button class="mat-small flex-m-y" color="primary" (click)="onSave()" [loading]="isLoading">
          Save
        </button>
      </div>
      <div [fxFlex]="100" fxFlex.gt-sm="55">
        <app-videojs-player
          *ngIf="selectedVideo || video?.trailer; else noSelectedMediaTpl"
          [options]="{
            autoplay: video.is_autoplay,
            controls: true,
            controlBar: {
              liveDisplay: true,
              pictureInPictureToggle: false
            },
            sources: [
              {
                src: selectedVideo?.source_file || video?.trailer?.source_file,
                type: 'video/mp4'
              }
            ]
          }"
        >
        </app-videojs-player>
        <ng-template #noSelectedMediaTpl>
          <img class="clip-preview" src="/assets/images/no-image.png" alt="image" />
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions fxLayoutAlign="center center"></div>
