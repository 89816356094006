import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Template } from '@app/shared/domain/template.domain';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { map } from 'lodash';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly initialDomain$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly activeTemplateSubject$: BehaviorSubject<Template | null> = new BehaviorSubject<Template | null>(
    null
  );
  private readonly pageSettings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private readonly generalSettings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private apiService: ApiService) {}

  updatePageSettings(data: any) {
    this.pageSettings$.next(data);
  }

  updateGeneralSettings(data: any) {
    this.generalSettings$.next(data);
  }

  getPageSettings() {
    return this.pageSettings$.value;
  }

  getGeneralSettingsUpdate() {
    return this.generalSettings$.value;
  }

  updateDomain(domain: string) {
    this.initialDomain$.next(domain);
  }

  getDomain() {
    return this.initialDomain$.value;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  getIsLoading() {
    return this.isLoading$.asObservable().pipe(distinctUntilChanged());
  }

  setActiveProject(channel: Template) {
    this.activeTemplateSubject$.next(channel);
  }

  getActiveProject() {
    return this.activeTemplateSubject$.asObservable().pipe(distinctUntilChanged());
  }

  getTemplate(data?: Template): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get(`/api/v1/template/list/`, params);
  }

  getOrCreateTemplate(data?: Template): Observable<any> {
    return this.apiService.get(`/api/v1/template/retrieve-or-create/`);
  }

  getDefaultTemplate(tenantId: string): Observable<any> {
    return this.apiService.get(`/api/v1/template/retrieve-default-site/${tenantId}/`);
  }

  createTemplate(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/template/save-single/`, data);
  }

  updateTemplate(data: any): Observable<any> {
    this.initialDomain$.next(data.domain);
    return this.apiService.put(`/api/v1/template/save-single/`, data);
  }

  setDefaultTemplate(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/template/set-default/`, data);
  }

  getBlocks(): Observable<any> {
    return this.apiService.get(`/api/v1/block/list/`);
  }

  createBlock(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/block/save-single/`, data);
  }

  updateBlock(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/block/save-single/`, data);
  }

  publishWebsite(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/template/publish/`, data);
  }

  deleteTemplate(uuid: string): Observable<any> {
    return this.apiService.delete(`/api/v1/template/delete/${uuid}`);
  }
  uploadFavicon(uuid: string, data: FormData): Observable<any> {
    return this.apiService.post(`/api/v1/template/favicon/${uuid}/`, data);
  }

  getGoogleFonts(): Observable<any> {
    return this.apiService.get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${environment.googleAPIKey} &sort=popularity`
    );
  }

  buildWebsite(data: any): Observable<any> {
    return this.apiService.post(`${environment.sitePublisherUrl}/api/website/build/`, data);
  }

  deployWebsite(data: any): Observable<any> {
    return this.apiService.post(`${environment.sitePublisherUrl}/api/website/deploy/queue/`, data);
  }

  deleteWebsite(data: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.deleteWebsite(`${environment.sitePublisherUrl}/api/website/delete/`, params);
  }
}
