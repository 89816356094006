<h1 mat-dialog-title>Edit thumbnail</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoadingDefault; else tmpThumbnail"></app-spinner>
  <ng-template #tmpThumbnail>
    <div class="sub-label">
      This image will be used as the primary thumbnail for this clip in every location it is displayed within your
      channel website, or apps
      <br />
      <span class="text-black">Recommended image size: </span> 1920x1080px
    </div>

    <div>
      <!-- <ng-template #uploadThumbnailHandler>
        <button mat-flat-button color="primary" (click)="fileInputThumbnail.click()">Upload Thumbnail</button>
        <input #fileInputThumbnail type="file" hidden (change)="handleChangeThumbnail($event.target.files)" />
      </ng-template> -->
      <div class="image-preview-wrapper">
        <img class="image-preview" [src]="model.thumbnail" alt="image" *ngIf="model.thumbnail" />
        <button mat-flat-button color="primary" (click)="fileInputThumbnail.click()">
          {{ model.thumbnail ? 'Replace' : 'Upload ' }} Thumbnail
        </button>
        <input #fileInputThumbnail type="file" hidden (change)="handleChangeThumbnail($event.target.files)" />
        <!-- <button mat-flat-button class="mat-small remove-image" color="danger" (click)="handleRemoveThumbnail()">
          Remove Image
        </button> -->
      </div>
    </div>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="mat-small" [style.margin-left]="'auto'" color="primary" (click)="handleClose()">
    Close
  </button>
</div>
