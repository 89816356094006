import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Category, Playlist, Video } from '@app/shared';
import { environment } from '@env/environment';
import { forEach } from 'lodash';
import slugify from '@sindresorhus/slugify';

export class CommonHelpers {
  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  static validateAllFormArrayFields(form: FormArray) {
    form.controls.forEach((formCtr) => {
      if (formCtr instanceof FormControl) {
        formCtr.markAsTouched({ onlySelf: true });
      }
    });
  }

  static createSlug(alias: string) {
    alias = slugify(alias);
    if (alias == '') {
      alias = 'default-slug';
    }
    let slug = alias.toLowerCase();
    slug = slug.replace(/\\/gi, '');
    slug = slug.replace(/`/gi, '');
    slug = slug.replace(/\u2019/gi, '');
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    slug = slug.replace(/đ/gi, 'd');
    slug = slug.replace(
      /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\{|\}|\[|\]|\+|\=|\,|\.|\/|\?|\>|\<|\'|\’|\"|\:|\;|\/\|_/gi,
      ''
    );
    slug = slug.replace(/ /gi, '-');
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');
    return slug;
  }

  static secondToDuration(secs: number) {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor(secs / 60) % 60;
    const seconds = Math.floor(secs % 60);
    return [hours, minutes, seconds].map((v) => (v < 10 ? '0' + v : v)).join(':');
  }

  static durationToSecond(duration: string = ''): number {
    if (!duration) return 0;
    try {
      // Split days and time
      let [dayPart, timePart] = duration.split(' ');
      let days = 0;
      if (timePart) {
        days = +dayPart;
      } else {
        // If there's no space, the time is in dayPart
        timePart = dayPart;
      }

      // Split time into hours, minutes, and seconds.milliseconds
      const split = timePart.split(':');
      const hours = +split[0];
      const minutes = +split[1];
      const seconds = parseFloat(split[2]);

      // Calculate total seconds
      const totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
      return totalSeconds;
    } catch (error) {
      return 0;
    }
  }

  static isBetweenDate(date: Date, min: Date, max: Date) {
    return date.getTime() >= min.getTime() && date.getTime() <= max.getTime();
  }

  static getEndDate(playlist: Playlist) {
    const { start_date, duration } = playlist;
    return new Date(new Date(start_date).getTime() + this.durationToSecond(duration) * 1000);
  }

  static totalDuration(items: any[]) {
    let totalSec = 0;
    items?.forEach((item) => {
      const { duration } = item;
      if (duration) {
        totalSec += CommonHelpers.durationToSecond(duration);
      }
    });
    return totalSec;
  }

  static mapOrder(array: any[], order: any[], key: any) {
    array.sort(function (a, b) {
      const A = a[key],
        B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });
    return array;
  }

  static listToTree(list: any[]) {
    const map = {};
    const roots: any[] = [];
    for (let i = 0; i < list.length; i += 1) {
      map[list[i].uuid] = i;
      list[i].children = [];
    }
    list.map((node: any) => {
      if (node.parent?.uuid && list[map[node.parent?.uuid]]) {
        list[map[node.parent?.uuid]].children.push(node);
      } else {
        roots.push(node);
      }
    });
    return roots;
  }

  static convertTreeToCategories(tree: Category[]) {
    return tree.reduce(function (acc: any[], o: Category, index: number) {
      acc.push({ uuid: o.uuid, sort_order: index });
      if (o.children) acc = acc.concat(CommonHelpers.convertTreeToCategories(o.children));
      return acc;
    }, []);
  }

  static getBigParent(uuid: string, categories: Category[]) {
    let category = categories.find((i) => i.uuid === uuid);
    // let result = category.sort_order + 1;
    if (!category.parent) return category.sort_order;
    while (category.parent) {
      category = categories.find((i) => i.uuid === category.parent.uuid);
      // result = (category.sort_order + 1) * 10 + result;
    }
    return category.sort_order;
  }

  static getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  static dateNowToString() {
    return new Date().toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
  static sbsPlanSplitField(filed: string) {
    return filed.split(' ');
  }

  static generatePassword() {
    let length = 8,
      charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  static hexToRgbA(hex: string) {
    try {
      let c: any;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgb(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255] + ')';
      }
    } catch (error) {
      return hex;
    }
  }

  static testYoutubeChannelURL(url: string) {
    const reg = RegExp(/(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+/);
    return reg.test(url);
  }

  static testYoutubeVideoURL(url: string) {
    const reg = RegExp(
      /(?:.+?)?(?:\/v\/|watch\/|\?v=|\&v=|youtube\.com\/|\/v=|^youtube\.com\/|watch\%3Fv\%3D)([a-zA-Z0-9_-]{11})+/
    );
    return reg.test(url);
  }

  static getFileName(fileName: string) {
    const index = fileName.lastIndexOf('.');
    if (index === -1) return fileName;
    return fileName.substring(0, index);
  }

  static transformResponseFromZoho(res: any): { success: boolean; message: string; redirect: boolean } {
    if (Array.isArray(res.value)) {
      let result: any = '';
      forEach(res.value, (item) => {
        result += item;
      });

      try {
        const response = JSON.parse(result);
        if (response.code === 0 || response.code === 200) {
          return {
            success: true,
            message: response.message,
            redirect: false,
          };
        } else {
          return {
            success: false,
            message: response.message,
            redirect: false,
          };
        }
      } catch (error) {
        return {
          success: false,
          message: 'Some thing went wrong!',
          redirect: false,
        };
      }
    } else {
      const matchPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      if (matchPattern.test(res.value)) {
        return {
          success: false,
          message: res.value,
          redirect: true,
        };
      } else {
        return {
          success: true,
          message: 'Unlocked successfully !!',
          redirect: false,
        };
      }
    }
  }

  static generateEmbedCodeMedia(template_id: string, suffix: string, domain: string = null) {
    var iframeSource = `${environment.objectStorageUrl}/${template_id}/${suffix}`;

    if (!!domain && domain.trim() !== '') {
      var iframeSource = `https://${domain}/${suffix}`;
    }

    return `<iframe src="${iframeSource}" frameBorder="0" width="100%" height="600" />`;
  }

  static generateEmbedCodeMediaWithExtraParams(
    template_id: string,
    suffix: string,
    extra_params: string = '',
    domain: string = null
  ) {
    var iframeSource = `${environment.objectStorageUrl}/${template_id}/${suffix}`;

    if (!!domain && domain.trim() !== '') {
      var iframeSource = `https://${domain}/${suffix}`;
    }

    return `<iframe src="${iframeSource}" frameBorder="0" width="100%" height="600" ${extra_params} />`;
  }

  static subDurationUnit(duration_unit: string) {
    if (duration_unit.endsWith('s')) {
      duration_unit = duration_unit.slice(0, -1);
    }
    return duration_unit.charAt(0).toUpperCase() + duration_unit.slice(1);
  }
}
