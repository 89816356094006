import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { environment as env } from '@env/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ImporterApiService {
  constructor(private apiService: ApiService) {}

  fetchVideos(data_params: any, type: string) {
    let params = new HttpParams();
    map(data_params, (value: any, key: string) => {
      console.log(value);
      if (!!value) {
        params = params.set(key, value);
      }
    });

    let url = '';
    if (type == 'youtube') {
      url = `${env.qmsUploadUrl}/api/v1/ytb/list`;
    } else if (type == 'vimeo') {
      url = `${env.qmsUploadUrl}/api/v1/vimeo/list`;
    } else if (type == 'dropbox') {
      url = `${env.qmsUploadUrl}/api/v1/dbx/list`;
    }

    return this.apiService.get(url, (params = params));
  }

  importVideos(formData: any, type: any) {
    let url = '';
    if (type == 'youtube') {
      url = `${env.qmsUploadUrl}/api/v1/ytb/import`;
    } else if (type == 'vimeo') {
      url = `${env.qmsUploadUrl}/api/v1/vimeo/import`;
    } else if (type == 'dropbox') {
      url = `${env.qmsUploadUrl}/api/v1/dbx/import`;
    }
    return this.apiService.post(url, formData);
  }
}
