import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  showFooter = true;
  subject = new Subject();

  toggleFooter(footerFlag: boolean) {
    this.showFooter = footerFlag;
    this.subject.next(this.showFooter);
  }
}
