import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '@app/core';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
interface Params {
  channel_id: string;
  parent_id?: string;
  directory?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FolderStructureService {
  constructor(private apiService: ApiService) {}

  private selectedFolderSubject = new BehaviorSubject<any>(null);
  private folderBreadCrumbSubject = new BehaviorSubject<any>(null);
  private isDraggingSubject = new BehaviorSubject<any>(null);
  private selectedItemsSubject = new BehaviorSubject<any>(null);
  selectedFolder$ = this.selectedFolderSubject.asObservable();
  folderBreadCrumb$ = this.folderBreadCrumbSubject.asObservable();
  isDragging$ = this.isDraggingSubject.asObservable();
  selectedItems$ = this.selectedItemsSubject.asObservable();

  selectFolder(folder: any): void {
    if (folder) {
      this.selectedFolderSubject.next(folder);
    } else {
      // do not delete this
      this.selectedFolder$ = this.selectedFolderSubject.asObservable();
      this.selectedFolderSubject.next(null);
    }
  }

  selectItems(items: any): void {
    this.selectedItemsSubject.next(items);
  }

  public getSelectedFolder(): Observable<any> {
    return this.selectedFolderSubject.asObservable().pipe(distinctUntilChanged());
  }

  public getSelectedItems(): Observable<any> {
    return this.selectedItemsSubject.asObservable().pipe(distinctUntilChanged());
  }

  isDraggingListener(state: boolean): void {
    this.isDraggingSubject.next(state);
  }

  // Function to handle errors
  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error);
    return throwError(error);
  }

  // Method to retrieve all folders
  fetchFolders(params?: any): Observable<any> {
    let queryParams = new HttpParams();
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          queryParams = queryParams.set(key, params[key]);
        }
      }
    }
    return this.apiService.get('/api/v3/folders/', queryParams);
  }

  // Method to retrieve a single folder
  getFolder(uuid: string, params?: any): Observable<any> {
    let queryParams = new HttpParams();
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          queryParams = queryParams.set(key, params[key]);
        }
      }
    }
    return this.apiService.get(`/api/v3/folders/${uuid}/`, queryParams).pipe(catchError(this.handleError));
  }

  // Method to create a new folder
  createFolder(folderData: any): Observable<any> {
    return this.apiService.post(`/api/v3/folders/`, folderData).pipe(catchError(this.handleError));
  }

  // Method to update a folder
  updateFolder(uuid: string, folderData: any): Observable<any> {
    return this.apiService.patch(`/api/v3/folders/${uuid}/`, folderData).pipe(catchError(this.handleError));
  }

  // Method to delete a folder
  deleteFolder(uuid: string): Observable<any> {
    return this.apiService.delete(`/api/v3/folders/${uuid}/`).pipe(catchError(this.handleError));
  }

  // Method to add files to a folder
  addFiles(payload: any, create: boolean = false): Observable<any> {
    return this.apiService
      .post(`/api/v3/folders/add-files/?create=${create}`, payload)
      .pipe(catchError(this.handleError));
  }

  // Method to copy folder to another parent folder
  copyFolder(folderId: string, parentId: string): Observable<any> {
    return this.apiService.post(`/api/v3/folders/${folderId}/copy-to/${parentId}/`).pipe(catchError(this.handleError));
  }

  // Method to duplicate folder
  duplicateFolder(folderId: string): Observable<any> {
    return this.apiService.post(`/api/v3/folders/${folderId}/duplicate/`).pipe(catchError(this.handleError));
  }

  // Method to move folder to another parent folder
  moveFolder(folderId: string, parentId: string): Observable<any> {
    return this.apiService.put(`/api/v3/folders/${folderId}/move-to/${parentId}/`).pipe(catchError(this.handleError));
  }

  // Method to get videos in a folder
  getFolderVideos(folderId: string, params?: any): Observable<any> {
    let queryParams = new HttpParams();
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          queryParams = queryParams.set(key, params[key]);
        }
      }
    }
    return this.apiService.get(`/api/v3/folders/${folderId}/videos/`, params).pipe(catchError(this.handleError));
  }

  deleteFilesFromFolder(folderId: string, payload: any): Observable<any> {
    return this.apiService
      .post(`/api/v3/folders/${folderId}/delete-files/`, payload)
      .pipe(catchError(this.handleError));
  }
}
