import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import * as SparkMD5 from 'spark-md5';
import { environment as env } from '@env/environment';

interface ProgressItem {
  title: string;
  progress: number;
}

@Injectable({
  providedIn: 'root',
})
export class UploadChunkService {
  private readonly progressUpload$: BehaviorSubject<ProgressItem[]> = new BehaviorSubject<ProgressItem[]>([]);
  constructor(private apiService: ApiService) {}

  setProgressUpload(value: ProgressItem[]) {
    this.progressUpload$.next(value);
  }

  getProgressUpload() {
    return this.progressUpload$.asObservable().pipe(distinctUntilChanged());
  }

  incrementalMD5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const spark = new SparkMD5.ArrayBuffer();
      const chunkSize = 2097152; // Read in chunks of 2MB
      const chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;

      fileReader.onload = (event) => {
        spark.append(event.target.result as any); // Append array buffer
        ++currentChunk;
        currentChunk < chunks ? loadNext() : resolve(spark.end()); // Compute hash
      };

      fileReader.onerror = () => reject(fileReader.error);

      const loadNext = () => {
        const start = currentChunk * chunkSize;
        const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(File.prototype.slice.call(file, start, end));
      };

      loadNext();
    });
  }

  uploadChunkFile(url: string, offset: number, size: number, total: number, fmData: FormData): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Range': `bytes ${offset}-${offset + size - 1}/${total}`,
    });
    return this.apiService.uploadChunk(url, fmData, headers);
  }

  uploadFinal(url: string, md5: string, duration: string, id?: string): Observable<any> {
    return this.apiService.post(url, {
      md5,
      duration,
      video_id: id,
    });
  }

  uploadFileQMS(data: FormData): Promise<any> {
    let url = `${env.qmsUploadUrl}/api/v1/video/upload/`;
    return this.apiService.uploadChunkQMS(url, data);
  }

  uploadFile(data: FormData): Observable<any> {
    const headers = new HttpHeaders({ 'ngsw-bypass': '' });
    return this.apiService.post(`${env.qmsUploadUrl}/api/v1/video/upload/`, data, {
      reportProgress: true,
      observe: 'events',
      headers,
    });
  }

  uploadNotProgress(data: FormData): Observable<any> {
    return this.apiService.post(`${env.qmsUploadUrl}/api/v1/video/upload/`, data);
  }

  resetUploadProgress() {
    this.progressUpload$.next([]);
  }
}
