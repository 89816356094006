import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { TemplateService } from '../../services/template/template.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '@env/environment';

@Component({
  selector: 'app-deploy-dialog',
  templateUrl: './deploy-dialog.component.html',
  styleUrls: ['./deploy-dialog.component.scss'],
})
export class DeployDialogComponent implements OnInit {
  public submitted = false;
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeployDialogComponent>,
    public templateService: TemplateService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  handleDeploy(): void {
    this.isLoading = true;
    this.templateService.deployWebsite(this.data).subscribe(
      () => {
        this.submitted = true;
        this.isLoading = false;
      },
      (err) => {
        this.notifier.notify('error', err);
        this.isLoading = false;
        this.dialogRef.close();
      }
    );
  }

  isTvsDomain(domain: string) {
    return domain.includes(environment.sitePublisherDomain);
  }

  getNameServers() {
    return `ns3.${environment.sitePublisherDomain}, ns4.${environment.sitePublisherDomain}`;
  }

  getLink() {
    return `http://${this.data.website.domain}`;
  }

  @HostListener('document:click', ['$event'])
  clickedOut(e: any) {
    if (this.submitted && !e.target.closest('mat-dialog-container')) {
      this.dialogRef.close(true);
    }
  }
}
