<div class="flex-p">
  <app-spinner *ngIf="false"></app-spinner>
  <form fxLayout="column" fxLayoutGap="40px" class="deployment-settings-form" [formGroup]="deploymentForm">
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Build Phase</h3>
        <p class="description">Prepare your website for publishing</p>
      </div>
      <div fxFlex="60" class="site-name">
        <button
          mat-flat-button
          color="primary"
          [loading]="isLoading || isLoadingTemplate"
          mat-stroked-button
          color="primary"
          (click)="onBuild()"
          [disabled]="isPublished"
        >
          Build Website
        </button>
      </div>
    </div>

    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Domain</h3>
        <p class="description">Website URL</p>
      </div>
      <div fxFlex="60" class="domain-constant" *ngIf="!isDomainEmpty()">
        <h3>{{ domain }}</h3>
      </div>

      <div fxFlex="60" class="domain-form" *ngIf="!isPublished && isDomainEmpty()">
        <label for="isSubdomainToggle">Use a subdomain?</label>
        <mat-slide-toggle
          id="isSubdomainToggle"
          [checked]="isSubdomain"
          (change)="onSlideToggleChange()"
        ></mat-slide-toggle>
        <hr />
        <br />

        <div fxLayout="row" fxLayoutWrap="nowrap" fxLayoutAlign="space-between center">
          <div class="navigation-fields form-group" fxLayout="column">
            <mat-label class="text-weight-bold" fxLayout="row" for="domainInput">Domain:</mat-label>
            <mat-form-field appearance="outline" fxLayout="row">
              <input
                matInput
                type="text"
                id="domainInput"
                formControlName="domain"
                (change)="onInputChange($event, false)"
                required
              />
              <mat-error *ngIf="f.domain.invalid && !isSubdomain">{{ getDomainInputError() }}</mat-error>
            </mat-form-field>
          </div>

          <div class="navigation-fields form-group" fxLayout="column">
            <mat-label class="text-weight-bold" fxLayout="row" for="subDomainInput">Subdomain:</mat-label>
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  id="subDomainInput"
                  formControlName="subDomain"
                  (change)="onInputChange($event, true)"
                  required
                />
                <span matSuffix style="margin-bottom: 5px">.{{ getSitePublisherDomain() }}</span>
                <mat-error *ngIf="f.subDomain.invalid && isSubdomain">{{ getSubdomainInputError() }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Publish Phase</h3>
        <p class="description">
          Deploy your website and expose it to the internet. <br /><br />For dedicated domains, point them to these name
          servers:<br />{{ getNameServers() }}
        </p>
      </div>
      <div fxFlex="60" class="site-name">
        <button
          mat-flat-button
          color="primary"
          [loading]="isLoading || isLoadingTemplate"
          mat-stroked-button
          color="primary"
          (click)="onDeploy()"
          [disabled]="!isPublished"
        >
          Publish Website
        </button>
      </div>
    </div>

    <hr />
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Request a Certificate</h3>
        <p class="description">Install an SSL certificate for your website</p>
      </div>
      <div fxFlex="60" class="site-name" class="request-certificate">
        <div class="disabled-content">
          <div class="coming-soon-content">
            <h2>Feature Coming Soon</h2>
            <mat-label style="font-size: 15px"
              >For SSL Certificate Installation requests, please contact support.</mat-label
            >
          </div>
        </div>

        <mat-label class="text-weight-bold" fxLayout="row" for="domainInput" [disabled]="!isPublished">
          Request for a certificate installation to enable secure connection for your website:
        </mat-label>
        <br />
        <mat-radio-group [value]="sslChoice" (change)="onCertTypeChange($event)" [disabled]="!isPublished">
          <mat-radio-button value="existingCertificate" style="margin-bottom: 10px"
            >&nbsp;Existing Certificate</mat-radio-button
          ><br />
          <mat-radio-button value="letsEncrypt">&nbsp;Free Let's Encrypt Certificate</mat-radio-button>
        </mat-radio-group>
        <br /><br />

        <button
          mat-flat-button
          color="primary"
          [loading]="isLoading || isLoadingTemplate"
          mat-stroked-button
          color="primary"
          (click)="onCertificateRequest()"
          [disabled]="!isPublished || sslChoice === ''"
        >
          Request Certificate
        </button>
      </div>
    </div>
  </form>
</div>
