import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export interface WindowSize {
  innerWidth: number;
  innerHeight: number;
}

@Injectable({
  providedIn: 'root',
})
export class WindowresizeService {
  readonly currentSize$: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>(null);
  private perfectScroll: any = null;

  getSize(): Observable<WindowSize> {
    return this.currentSize$.asObservable();
  }

  setSize(size: WindowSize): void {
    this.currentSize$.next(size);
  }

  setPerfectScroll(scrollRef: any): void {
    this.perfectScroll = scrollRef;
  }

  scrollTo(position: number = 0, speed: number = 1) {
    this.perfectScroll?.update();
    this.perfectScroll?.scrollToTop(position, speed);
  }
}
