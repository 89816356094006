import { VideoService } from './../../services/video/video.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from '@app/shared/domain';
import { AudioCategoryService, VideoCategoryService } from '@app/shared/services';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-list-category-dialog',
  templateUrl: './list-category-dialog.component.html',
  styleUrls: ['./list-category-dialog.component.scss'],
})
export class ListCategoryDialogComponent implements OnInit, OnDestroy {
  public categories: any[];
  public id: string;
  public type: string;
  public isLoadingDefault: boolean;
  private subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ListCategoryDialogModel,
    public categoryService: VideoCategoryService,
    public dialogRef: MatDialogRef<ListCategoryDialogComponent>,
    private notifier: NotifierService,
    public audioCategoryService: AudioCategoryService
  ) {
    this.id = data.id;
    this.type = data.type;
  }

  ngOnInit(): void {
    this.getListCategories();
  }

  getListCategories() {
    this.isLoadingDefault = true;
    if (this.type === 'video') {
      this.categoryService
        .getCategories({
          page: 1,
          page_size: 100,
          search: '',
          ordering: '-created',
        })
        .subscribe(
          (res) => {
            const { results } = res;
            this.categories = results;
            this.isLoadingDefault = false;
          },
          () => {
            this.isLoadingDefault = false;
          }
        );
    } else {
      this.audioCategoryService
        .getCategories({
          page: 1,
          page_size: 100,
          search: '',
          ordering: '-created',
        })
        .subscribe(
          (res) => {
            const { results } = res;
            this.categories = results;
            this.isLoadingDefault = false;
          },
          () => {
            this.isLoadingDefault = false;
          }
        );
    }
  }

  handleAddToCategory(selected: any[]) {
    const _observables: Array<Observable<any>> = [];
    selected.map(({ value: id }) => {
      if (this.type === 'video') {
        _observables.push(this.categoryService.addVideo(id, this.id));
      } else {
        _observables.push(this.audioCategoryService.addAudio(id, this.id));
      }
    });
    this.subscription = forkJoin(_observables).subscribe(
      (res) => {
        this.notifier.notify('success', `Successfully added ${this.type} to the category`);
        this.dialogRef.close(true);
      },
      (err) => {
        this.notifier.notify('error', err);
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}

export class ListCategoryDialogModel {
  constructor(public type: string, public id: string) {}
}
