import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-broadcast-studio-edit-name-dialog',
  templateUrl: './broadcast-studio-edit-name-dialog.component.html',
  styleUrls: ['./broadcast-studio-edit-name-dialog.component.scss'],
})
export class BroadcastStudioEditNameDialogComponent implements OnInit {
  title: string;

  public inputTitle = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BroadcastStudioEditNameDialogModel,
    public dialogRef: MatDialogRef<BroadcastStudioEditNameDialogComponent>
  ) {
    this.title = data.title;
  }

  ngOnInit(): void {}

  handleSaveItem() {
    if (this.inputTitle.invalid) {
      return;
    }
    this.dialogRef.close(this.title);
  }
}

export class BroadcastStudioEditNameDialogModel {
  constructor(public title: string) {}
}
