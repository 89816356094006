import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getCheckedExistingLogin().pipe(
      tap((checkedLogin) => {
        if (!checkedLogin) {
          this.authService.checkExistingLogin();
        }
      }),
      switchMap((loggedIn) => {
        if (!loggedIn) {
          this.router.navigateByUrl('/auth/login');
        }
        return of(loggedIn);
      })
    );
  }
}
