<div class="main-container">
  <div class="close-button">
    <button mat-icon-button (click)="handleClose()" style="color: white !important">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="showContent">
    <div class="section-header">
      <div>
        <div style="font-size: 24px; color: white; font-weight: bold; letter-spacing: 4px">TIME</div>
        <div style="font-size: 13px; color: white; font-weight: bold; font-weight: bold">REMAINING</div>
      </div>
      <div class="countdown">
        <div class="countdown-section">
          <div class="counter">{{ days }}</div>
          <div class="label">DAYS</div>
        </div>
        <div class="countdown-section">
          <div class="counter">{{ hours }}</div>
          <div class="label">HOURS</div>
        </div>
        <div class="countdown-section">
          <div class="counter">{{ minutes }}</div>
          <div class="label">MINUTES</div>
        </div>
        <div class="countdown-section">
          <div class="counter">{{ seconds }}</div>
          <div class="label">SECONDS</div>
        </div>
      </div>
      <div class="contact-section">
        <mat-icon> phone </mat-icon>
        <span style="font-weight: bold">CALL SALES +1-325-268-0040</span>
      </div>
    </div>

    <div style="position: relative">
      <img
        class="sale-image"
        src="https://tvsw1-vod.secdn.net/tvsw1-vod/play/sestore3/tvsw1/tvs-prod/media/spring-sale-2024.png"
        alt=""
      />
      <div>
        <button (click)="goToExternalPage()" class="action-button">
          <span style="font-weight: bold">Unlock Now!</span>
        </button>
      </div>
    </div>
  </div>
  <div style="display: flex; align-items: center; justify-content: center; height: 400px" *ngIf="!showContent">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
</div>
