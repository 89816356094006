import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(private apiService: ApiService) {}

  getAllPackages(): Observable<any> {
    return this.apiService.get('/api/v1/packages/all-packages/');
  }
}
