import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { TemplateService } from '../../services/template/template.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '@env/environment';
import { DeploymentSettingsComponent } from '@app/shared/components/deployment-settings/deployment-settings.component';

@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss'],
})
export class PublishDialogComponent {
  public submitted = false;
  public isLoading = false;
  @ViewChild(DeploymentSettingsComponent) deploymentSettingsComponent!: DeploymentSettingsComponent;

  constructor(
    public dialogRef: MatDialogRef<PublishDialogComponent>,
    public templateService: TemplateService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  getWebsiteData() {
    return {
      tenantId: this.data.settings.generalSettings.tenantId,
      website: {
        name: this.data.name,
        domain: this.data.domain,
      },
      kubernetes: {
        podReplicas: 1,
        websiteId: this.data.uuid,
        containerPort: 80,
        servicePort: 8080,
        serviceType: 'LoadBalancer',
      },
    };
  }

  handleDeploy(): void {
    var deploy_payload = this.getWebsiteData();
    this.isLoading = true;
    this.templateService.deployWebsite(deploy_payload).subscribe(
      () => {
        this.submitted = true;
        this.isLoading = false;
      },
      (err) => {
        this.notifier.notify('error', err);
        this.isLoading = false;
        this.dialogRef.close();
      }
    );
  }

  isTvsDomain(domain: string) {
    return domain.includes(environment.sitePublisherDomain);
  }

  getNameServers() {
    return `ns3.${environment.sitePublisherDomain}, ns4.${environment.sitePublisherDomain}`;
  }

  getLink() {
    return `http://${this.data.website.domain}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  copyLink() {
    const copyText = document.getElementById('publishLink').textContent;
    navigator.clipboard.writeText(copyText);
  }

  @HostListener('document:click', ['$event'])
  clickedOut(e: any) {
    if (this.submitted && !e.target.closest('mat-dialog-container')) {
      this.dialogRef.close(true);
    }
  }
}
