import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { NotifierService } from 'angular-notifier';
import { AppSettingService } from '@app/shared/services/app-setting/app-setting.service';

@Component({
  selector: 'app-report-bug-dialog',
  templateUrl: './report-bug-dialog.component.html',
  styleUrls: ['./report-bug-dialog.component.scss'],
})
export class ReportBugDialogComponent implements OnInit {
  public reportForm: FormGroup;
  file: File;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ReportBugDialogComponent>,
    private notifier: NotifierService,
    private appSettingService: AppSettingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  get f() {
    return this.reportForm?.controls;
  }

  buildForm() {
    this.reportForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), NoWhitespaceValidator()]],
      email: ['', [Validators.required, Validators.email, NoWhitespaceValidator()]],
      subject: ['', [Validators.required, Validators.maxLength(50), NoWhitespaceValidator()]],
      description: ['', [Validators.required, NoWhitespaceValidator()]],
    });
  }

  handleScreenShots(files: FileList) {
    const file = files[0];
    this.file = file;
  }

  handleSend(): void {
    if (this.reportForm.invalid) {
      CommonHelpers.validateAllFormFields(this.reportForm);
      return;
    }

    const { name, email, subject, description } = this.reportForm.value;

    const data = new FormData();
    data.append('name', name);
    data.append('email', email);
    data.append('subject', subject);
    data.append('description', description);
    if (this.file?.size) {
      data.append('screenshot', this.file);
    }

    this.appSettingService.reportBug(data).subscribe(
      (res) => {
        this.notifier.notify('success', `Request Submitted Successfully`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.notifier.notify('error', error);
      }
    );
  }
}
