import { ElementRef } from '@angular/core';

export * from './videocamerachromakey.service';

export interface Instance {
  [id: string]: {
    [key: string]: {
      video: any;
      width: number;
      height: number;
      context: any;
      webGl: any;
      stop: boolean;
      reqID: any;
      canvasEl: ElementRef<HTMLCanvasElement>;
      canvasElWeb: ElementRef<HTMLCanvasElement>;
    };
  };
}

export class Screen {
  static readonly LIVE_PREVIEW = 'LIVE_PREVIEW';
  static readonly LIVE_MAIN = 'LIVE_MAIN';
  static readonly LIVE_ITEM = 'LIVE_ITEM';
}
