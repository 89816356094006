import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core';
import { Audio, Category, Playlist } from '@app/shared/domain';
import { map } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AudioCategoryService {
  private readonly selectedCategory$: BehaviorSubject<Category> = new BehaviorSubject<Category>(null);
  private readonly changeOrder$: Subject<Category> = new Subject<Category>();
  private readonly aodChangeOrder$: Subject<Category[]> = new Subject<Category[]>();
  private readonly selectedAODPlaylist$: BehaviorSubject<Playlist> = new BehaviorSubject<Playlist>(null);

  private readonly selectedAudio$: BehaviorSubject<Audio> = new BehaviorSubject<Audio>(null);
  constructor(private apiService: ApiService, private router: Router) {}

  setSelectedAudio(selected: Audio) {
    this.selectedAudio$.next(selected);
  }

  getSelectedAudio() {
    return this.selectedAudio$.asObservable().pipe(distinctUntilChanged());
  }

  setSelectedAODPlaylist(selected: Playlist) {
    this.selectedAODPlaylist$.next(selected);
  }

  getSelectedAODPlaylist() {
    return this.selectedAODPlaylist$.asObservable().pipe(distinctUntilChanged());
  }

  setAODChangeOrder(categories: Category[]) {
    this.aodChangeOrder$.next(categories);
  }

  getAODChangeOrder() {
    return this.aodChangeOrder$.asObservable().pipe();
  }

  setChangeOrder(category: Category) {
    this.changeOrder$.next(category);
  }

  getChangeOrder() {
    return this.changeOrder$.asObservable().pipe();
  }

  setSelectedCategory(selected: Category) {
    this.setSelectedAODPlaylist(null);
    this.selectedCategory$.next(selected);
  }

  getSelectedCategory() {
    return this.selectedCategory$.asObservable().pipe(distinctUntilChanged());
  }

  getCategories(data: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/audio-categories/', params);
  }

  updateCategory(fmData: FormData, categoryId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/audio-categories/${categoryId}/`, fmData);
  }

  updateCategoryThumbnail(categoryId: string, fmData: FormData) {
    return this.apiService.put(`/api/v1/audio-categories/${categoryId}/update-category-thumbnail`, fmData);
  }

  deleteCategory(categoryId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/audio-categories/${categoryId}/`);
  }

  createCategory(fmData: FormData): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/`, fmData);
  }

  changeParentCategory(categoryId: string, parentId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/change-parent-category/${categoryId}/${parentId}/`);
  }

  getAudios(categoryId: string): Observable<any> {
    return this.apiService.get(`/api/v1/audio-categories/${categoryId}/`);
  }

  getallAudios(categories_list: any): Observable<any> {
    const requestData = {
      categories_list: categories_list.join(','),
    };
    return this.apiService.post('/api/v1/audio-categories/all-audios-category/', requestData);
  }

  getAudiosByPlaylist(playlistId: string): Observable<any> {
    return this.apiService.get(`/api/v1/audio-playlists/${playlistId}/`);
  }

  addAudio(categoryId: string, audioId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/add-audio/${categoryId}/${audioId}/`);
  }

  removeAudio(categoryId: string, audioId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/remove-audio/${categoryId}/${audioId}/`);
  }

  removeAudioAOD(audioCategoryId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/remove-category-audio/${audioCategoryId}/`);
  }

  changeOrder(category: any, categoryId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/${categoryId}/change-order/`, category);
  }
  addAudioPlaylist(channelId: string, audioCateId: string) {
    return this.apiService.post(`/api/v1/audio-categories/create-audio-playlist/${channelId}/${audioCateId}/`);
  }

  changeOrderCategories(data: any): Observable<any> {
    return this.apiService.post('/api/v1/audio-categories/change-order-categories/', data);
  }

  cloneCategory(category_id: string): Observable<any> {
    return this.apiService.post(`/api/v1/audio-categories/${category_id}/clone-category/`);
  }
}
