<div class="source-item">
  <div [ngSwitch]="item.type">
    <div
      *ngSwitchCase="
        [LiveDemoSourceType.MAIN_CAMERA, LiveDemoSourceType.SUB_CAMERA].includes(item.type) ? item.type : !item.type
      "
    >
      <div fxLayout="row" fxLayoutAlign="space-between center" class="source-item-header">
        <div [style.color]="'#fff'" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>videocam</mat-icon>&nbsp; {{ item.title }}
        </div>
        <div>
          <icon-network-connection></icon-network-connection>
        </div>
      </div>
      <div #webcamFrame class="source-item-content">
        <div
          *ngIf="!item.noAction"
          fxLayout="row"
          fxLayoutAlign="end center"
          class="w-100 screen-action"
          fxLayoutGap="5px"
        >
          <div class="full-screen-icon" [class.bg-maximize]="item.maximize" (click)="handleMaximize()">
            <img width="18px" src="/assets/img/broadcast/full-screen.png" alt="explan" />
          </div>
          <mat-slide-toggle
            class="broadcast-slider-toggle"
            [checked]="item.active"
            (change)="toggleChange($event)"
          ></mat-slide-toggle>
        </div>

        <div class="icon" *ngIf="!item.source">
          <mat-icon *ngIf="!item.avatar" [style.color]="'#939598'" class="mat-24">person</mat-icon>
          <img *ngIf="item.avatar" width="60" class="rounded" [src]="item.avatar" />
        </div>
        <video
          [style.visibility]="!(item.source && !this.getActiveBackground()) ? 'hidden' : 'visible'"
          #videoCamera
          crossOrigin="anonymous"
          autoplay
          muted
          [id]="'video_sync_' + item.id"
          [srcObject]="item.source"
          class="vjs-default-skin w-100 video-cam"
        ></video>
        <canvas
          [hidden]="!(item.source && this.getActiveBackground() && !this.isSettingWebgl())"
          style="background-size: cover; background-repeat: no-repeat; background-position: center center"
          [ngStyle]="{ 'background-image': 'url(' + getBackgroundSrc() + ')' }"
          #outputCanvas
          class="vjs-default-skin w-100"
        ></canvas>
        <canvas
          [hidden]="!(item.source && this.getActiveBackground() && this.isSettingWebgl())"
          [ngStyle]="{ 'background-image': 'url(' + getBackgroundSrc() + ')' }"
          style="background-size: cover; background-repeat: no-repeat; background-position: center center"
          #outputCanvasWeb
          class="vjs-default-skin w-100"
        ></canvas>
        <div *ngIf="!item.noAction" fxLayout="row" fxLayoutAlign="end center" class="w-100 item-action">
          <!-- <mat-icon [style.color]="'#fff'" (click)="handleEditItem()">edit</mat-icon> -->
          <mat-icon [style.color]="'#ffff'" [matMenuTriggerFor]="menu">edit</mat-icon>
          <mat-icon
            *ngIf="item.type !== LiveDemoSourceType.MAIN_CAMERA"
            [style.color]="'#bb0f13'"
            (click)="removeSource(item.id)"
            >delete</mat-icon
          >
        </div>
        <mat-menu #menu="matMenu" class="my-menu" xPosition="before" yPosition="above">
          <div class="button-actions">
            <button mat-menu-item (click)="handleEditItem()">Edit Name</button>
            <button mat-menu-item (click)="handleChangeAvatar()">Change Avatar</button>
            <button *ngIf="item.type === LiveDemoSourceType.SUB_CAMERA" mat-menu-item (click)="handleEditCamera()">
              Edit Camera
            </button>
          </div>
        </mat-menu>
      </div>
      <div *ngIf="!item.noAction" [style.padding]="'0 8px'" fxLayout="row" xLayoutAlign="space-between end">
        <mat-icon [style.margin-top]="'12px'" [style.color]="'#fff'">volume_down</mat-icon>
        <mat-slider class="w-100 broadcast-slider" aria-label="unit(s)"></mat-slider>
      </div>
    </div>
    <ng-container *ngSwitchCase="LiveDemoSourceType.SCREEN_SHARE">
      <div class="source-item-header"></div>
      <div class="source-item-content">
        <div fxLayout="row" fxLayoutAlign="end center" class="w-100 screen-action" fxLayoutGap="5px">
          <div class="full-screen-icon" [class.bg-maximize]="item.maximize" (click)="handleMaximize()">
            <img width="18px" src="/assets/img/broadcast/full-screen.png" alt="explan" />
          </div>
          <mat-slide-toggle
            class="broadcast-slider-toggle"
            [checked]="item.active"
            (change)="toggleChange($event)"
          ></mat-slide-toggle>
        </div>
        <video id="video1" crossOrigin="anonymous" [srcObject]="item.source" class="vjs-default-skin w-100"></video>
      </div>

      <div [style.padding]="'0 8px'" fxLayout="row" xLayoutAlign="space-between end">
        <mat-icon [style.margin-top]="'12px'" [style.color]="'#fff'">volume_down</mat-icon>
        <mat-slider class="w-100 broadcast-slider" aria-label="unit(s)"></mat-slider>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="LiveDemoSourceType.LOCAL_AUDIO">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="source-item-header">
        <div [style.color]="'#fff'" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>video_library</mat-icon>&nbsp; {{ item.title }}
        </div>
      </div>
      <div class="source-item-content">
        <div fxLayout="row" fxLayoutAlign="end center" class="w-100 screen-action" fxLayoutGap="5px">
          <div class="full-screen-icon" [class.bg-maximize]="item.maximize" (click)="handleMaximize()">
            <img width="18px" src="/assets/img/broadcast/full-screen.png" alt="explan" />
          </div>
          <mat-slide-toggle
            class="broadcast-slider-toggle"
            [checked]="item.active"
            (change)="toggleChange($event)"
          ></mat-slide-toggle>
        </div>
        <input type="range" value="0" [id]="'scrub_' + item.id" style="display: none" />
        <div class="icon">
          <!-- TODO: style the thumbnail probably -->
          <mat-icon [style.color]="'#939598'" class="mat-24">volume_up</mat-icon>
          <!-- <mat-icon *ngIf="!item.thumbnail" [style.color]="'#939598'" class="mat-24">volume_up</mat-icon> -->
          <!-- <img *ngIf="item.thumbnail" width="60" class="rounded" [src]="item.thumbnail" /> -->
        </div>
        <div class="icon-play-ctrl">
          <mat-icon *ngIf="isPlay" (click)="onPauseMedia(videoRef)">pause_circle_outline</mat-icon>
          <mat-icon *ngIf="!isPlay" (click)="onPlayMedia(videoRef)">play_circle_outline</mat-icon>
        </div>
        <video
          #videoRef
          preload="none"
          crossOrigin="anonymous"
          [id]="'video_' + item.id"
          [src]="item.source"
          class="vjs-default-skin w-100"
          (seeked)="onMediaSeeked($event)"
        ></video>
        <div *ngIf="!item.noAction" fxLayout="row" fxLayoutAlign="end center" class="w-100 item-action media-ctrl">
          <mat-slider
            #timeMedia
            [min]="0"
            [max]="videoRef.duration"
            value="0"
            step="1"
            class="w-100 broadcast-slider"
            aria-label="unit(s)"
            (change)="onChangeSeek(videoRef, $event)"
          ></mat-slider>
          <mat-icon [style.color]="'#fff'" (click)="handleEditItem()">edit</mat-icon>
          <mat-icon [style.color]="'#bb0f13'" (click)="removeSource(item.id)">delete</mat-icon>
        </div>
      </div>
      <div [style.padding]="'0 8px'" fxLayout="row" xLayoutAlign="space-between end">
        <mat-icon class="icon-video-ctrl" (click)="onClickVolume(videoRef)">{{
          item.has_volume ? 'volume_down' : 'volume_off'
        }}</mat-icon>
        <mat-slider
          #slider
          [min]="0"
          [max]="1"
          step="0.1"
          class="w-100 broadcast-slider"
          aria-label="unit(s)"
          [value]="item.volume"
          [disabled]="!item.has_volume"
          (change)="onChangeVolume(videoRef, $event)"
        ></mat-slider>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="LiveDemoSourceType.LOCAL_VIDEO">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="source-item-header">
        <div [style.color]="'#fff'" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>video_library</mat-icon>&nbsp; {{ item.title }}
        </div>
      </div>
      <div class="source-item-content">
        <div fxLayout="row" fxLayoutAlign="end center" class="w-100 screen-action" fxLayoutGap="5px">
          <div class="full-screen-icon" [class.bg-maximize]="item.maximize" (click)="handleMaximize()">
            <img width="18px" src="/assets/img/broadcast/full-screen.png" alt="explan" />
          </div>
          <mat-slide-toggle
            class="broadcast-slider-toggle"
            [checked]="item.active"
            (change)="toggleVideo($event, videoRef)"
          ></mat-slide-toggle>
        </div>
        <div class="icon-play-ctrl">
          <mat-icon *ngIf="isPlay" (click)="onPauseMedia(videoRef)">pause_circle_outline</mat-icon>
          <mat-icon *ngIf="!isPlay" (click)="onPlayMedia(videoRef)">play_circle_outline</mat-icon>
        </div>
        <video
          #videoRef
          crossOrigin="anonymous"
          [src]="item.source"
          [id]="'video_' + item.id"
          class="vjs-default-skin w-100"
          (seeked)="onMediaSeeked($event)"
          [poster]="item.thumbnail"
        ></video>
        <input type="range" value="0" [id]="'scrub_' + item.id" style="display: none" />
        <div *ngIf="!item.noAction" fxLayout="row" fxLayoutAlign="end center" class="w-100 item-action media-ctrl">
          <mat-slider
            #timeMedia
            [min]="0"
            [max]="videoRef.duration"
            value="0"
            step="1"
            class="w-100 broadcast-slider"
            aria-label="unit(s)"
            (change)="onChangeSeek(videoRef, $event)"
          ></mat-slider>
          <mat-icon [style.color]="'#fff'" (click)="handleEditItem()">edit</mat-icon>
          <mat-icon [style.color]="'#bb0f13'" (click)="removeSource(item.id)">delete</mat-icon>
        </div>
      </div>
      <div [style.padding]="'0 8px'" fxLayout="row" xLayoutAlign="space-between end">
        <mat-icon class="icon-video-ctrl" (click)="onClickVolume(videoRef)">{{
          item.has_volume ? 'volume_down' : 'volume_off'
        }}</mat-icon>
        <mat-slider
          #slider
          [min]="0"
          [max]="1"
          step="0.1"
          class="w-100 broadcast-slider"
          aria-label="unit(s)"
          [value]="item.volume"
          (change)="onChangeVolume(videoRef, $event)"
          [disabled]="!item.has_volume"
        ></mat-slider>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="LiveDemoSourceType.GUEST">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="source-item-header">
        <div [style.color]="'#fff'" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>videocam</mat-icon>&nbsp; {{ item.title }}
        </div>
        <div>
          <icon-network-connection></icon-network-connection>
        </div>
      </div>
      <div class="source-item-content">
        <div fxLayout="row" fxLayoutAlign="end center" class="w-100 screen-action" fxLayoutGap="5px">
          <div class="full-screen-icon" [class.bg-maximize]="item.maximize" (click)="handleMaximize()">
            <img width="18px" src="/assets/img/broadcast/full-screen.png" alt="explan" />
          </div>
          <mat-slide-toggle class="broadcast-slider-toggle" [checked]="item.active" (change)="toggleChange($event)">
          </mat-slide-toggle>
        </div>

        <div class="icon" *ngIf="!item.source">
          <mat-icon *ngIf="!item.avatar" [style.color]="'#939598'" class="mat-24">person</mat-icon>
          <img *ngIf="item.avatar" width="60" class="rounded" [src]="item.avatar" />
        </div>
        <video
          crossOrigin="anonymous"
          *ngIf="item.source"
          autoplay
          muted
          id="video-item{{ item.source.user_id }}"
          class="vjs-default-skin w-100"
        ></video>
      </div>
      <div [style.visibility]="'hidden'" [style.padding]="'0 8px'" fxLayout="row" xLayoutAlign="space-between end">
        <mat-icon [style.margin-top]="'12px'" [style.color]="'#fff'">volume_down</mat-icon>
        <mat-slider class="w-100 broadcast-slider" aria-label="unit(s)"></mat-slider>
      </div>
    </ng-container>
    <div *ngSwitchDefault>output2</div>
  </div>
</div>
