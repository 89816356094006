<div mat-dialog-title style="text-align: center">Change avatar</div>
<div mat-dialog-content [style.padding]="'5px'">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="image-wrapper">
      <mat-icon [style.color]="'#000'" class="mat-24">person</mat-icon>
    </div>

    <button class="image-wrapper" *ngFor="let img of imageSources" [class.selected]="img.active">
      <img width="60" class="rounded" [src]="img.src" />
    </button>

    <button class="image-wrapper" (click)="inputFile.click()">
      <input
        #inputFile
        type="file"
        accept="image/*"
        (change)="handleUploadImage($event.target.files)"
        style="display: none"
      />
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button color="primary" (click)="handleSaveAvatar()">Save</button>
</div>
