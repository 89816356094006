import { NgModule } from '@angular/core';
import { PaginationPipe } from './pagination/pagination.pipe';
import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { ChatPersonSearchPipe } from './search/chat-person-search.pipe';
import { UserSearchPipe } from './search/user-search.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MailSearchPipe } from './search/mail-search.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { MyNumberPipe } from './number/number-pipe.pipe';
import { DurationRoundedPipe } from './round-duration/round-duration.pipe';
import { SafePipe } from './safe/safe.pipe';
import { SecondsTransformPipe } from './secondsTransform/seconds-transform.pipe';
import { ReversePipe } from './reverse/reverse.pipe';

const PIPES = [
  PaginationPipe,
  ProfilePicturePipe,
  ChatPersonSearchPipe,
  UserSearchPipe,
  TruncatePipe,
  MailSearchPipe,
  DurationPipe,
  MyNumberPipe,
  DurationRoundedPipe,
  SafePipe,
  SecondsTransformPipe,
  ReversePipe,
];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES],
})
export class PipesModule {}
