import { Component, OnInit, OnDestroy } from '@angular/core';
import { I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';

interface Language {
  code: string;
  icon: string;
  label: string;
}

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  languages = [
    { code: 'en', label: 'English', icon: 'icons8-usa-48.png' },
    { code: 'es', label: 'Spanish', icon: 'icons8-spain-48.png' },
    { code: 'fr', label: 'French', icon: 'icons8-france-48.png' },
    { code: 'jp', label: 'Japanese', icon: 'icons8-japan-48.png' },
    { code: 'da', label: 'Danish', icon: 'icons8-netherlands-48.png' },
    { code: 'de', label: 'German', icon: 'icons8-germany-48.png' },
    { code: 'ru', label: 'Russian', icon: 'icons8-russia-48.png' },
    { code: 'tw', label: 'Chinese (Traditional)', icon: 'icons8-china-48.png' },
    { code: 'cn', label: 'Chinese (Simplified)', icon: 'icons8-china-48.png' },
  ];
  selectedLanguage: string;
  selectedLanguageIcon: string;
  selectedLanguageLabel: string;
  dropdownOpen = false;
  defaultLanguageIcon = 'icons8-usa-48.png';

  private langChangeSubscription!: Subscription;

  constructor(private i18nService: I18nService) {}

  ngOnInit() {
    this.selectedLanguage = this.i18nService.getCurrentLanguage() ? this.i18nService.getCurrentLanguage() : 'en';
    this.setLanguageDetails(this.selectedLanguage);

    // Subscribe to language changes
    this.langChangeSubscription = this.i18nService.langChange$.subscribe((language: string) => {
      this.setLanguageDetails(language);
    });
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  //   onLanguageChange(language: string): void {
  //     // this.i18nService.language = language;
  //     // const selectElement = event.target as HTMLSelectElement;
  //     // const selectedLanguage = language.value;
  //     console.log('Change language here --------------------------------')
  //     // this.i18nService.setLanguage(language);
  //   }

  onLanguageChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedLanguage = selectElement.value;
    this.i18nService.setLanguage(selectedLanguage);
  }

  selectLanguage(code: string, icon: string, label: string): void {
    this.selectedLanguage = code;
    this.selectedLanguageIcon = icon;
    this.selectedLanguageLabel = label;
    this.dropdownOpen = false;

    // Save the selected language to localStorage
    localStorage.setItem('language', code);
    this.i18nService.language = code;
    this.setLanguageDetails(code);
  }

  private setLanguageDetails(languageCode: string) {
    const selectedLang = this.languages.find((lang) => lang.code === languageCode);
    if (selectedLang) {
      this.selectedLanguage = selectedLang.code;
      this.selectedLanguageIcon = selectedLang.icon;
      this.selectedLanguageLabel = selectedLang.label;
    }
  }

  getFlagUrl(code: string): string {
    const flagMap: any = {
      fr: 'icons8-france-48.png',
      da: 'icons8-netherlands-48.png',
      de: 'icons8-germany-48.png',
      en: 'icons8-usa-48.png',
      jp: 'icons8-japan-48.png',
      es: 'icons8-spain-48.png',
    };

    return `/assets/icons/flags/${flagMap[code]}`;
  }
}
