<div fxLayout="column" fxLayoutGap="15px">
  <p class="text-center text-weight-bold">{{ 'FEATURE_UNAVAILABLE' | translate }}</p>

  <p class="text-center">{{ 'PLEASE_UPGRADE' | translate }}</p>

  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="badges" *ngIf="!isLoading">
    <div class="badge-item" fxLayout="column" fxLayoutGap="10px" *ngFor="let item of myBadges">
      <img class="badge-image" [src]="item.logo" />
      <div class="badge-title">{{ item.name }}</div>

      <!-- <div class="badge-description">{{ item.description }}</div> -->

      <div *ngIf="item.addon_code">
        <span class="text-weight-bold" [style.fontSize.px]="25">{{ item.price_brackets[0]?.price | currency }}/</span>
        <span>{{ item.interval_unit }}</span>
      </div>

      <div *ngIf="item.plan_code">
        <span class="text-weight-bold" [style.fontSize.px]="25">{{ item.setup_fee | currency }}/</span>
        <span>{{ item.interval_unit }}</span>
      </div>

      <button
        *ngIf="item.addon_code"
        mat-flat-button
        class="w-100"
        [loading]="isUnlocking && isUnlocking[item.addon_code]"
        color="primary"
        (click)="clickedUnlock('addon', item.addon_code)"
      >
        PAY TO UNLOCK NOW
      </button>

      <button
        *ngIf="item.plan_code"
        mat-flat-button
        class="w-100"
        [loading]="isUnlocking && isUnlocking[item.plan_code]"
        color="primary"
        (click)="clickedUnlock('plan', item.plan_code)"
      >
        PAY TO UNLOCK NOW
      </button>
    </div>
  </div>
</div>
