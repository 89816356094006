import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonHelpers } from '@app/helpers';

@Component({
  selector: 'app-create-page-settings-dialog',
  templateUrl: './create-page-settings-dialog.component.html',
  styleUrls: ['./create-page-settings-dialog.component.scss'],
})
export class CreatePageSettingsDialogComponent implements OnInit {
  public navigationForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreatePageSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.buildForm({
      name: this.data?.formValue?.name || '',
      title: this.data?.formValue?.meta?.title || '',
      description: this.data?.formValue?.meta?.description || '',
    });
  }

  get f() {
    return this.navigationForm?.controls;
  }

  buildForm(formValue: any) {
    this.navigationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      title: [''],
      description: [''],
    });
    if (formValue) {
      this.navigationForm.setValue(formValue);
    }
  }

  handleCreate(): void {
    if (this.navigationForm.invalid) {
      CommonHelpers.validateAllFormFields(this.navigationForm);
      return;
    }
    this.dialogRef.close({
      ...this.navigationForm.value,
      name: this.f?.name?.value,
    });
  }
}
