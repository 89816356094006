<div mat-dialog-title style="text-align: center">{{ isEdit ? 'Edit Camera' : 'Add Camera' }}</div>
<div mat-dialog-content style="overflow-x: hidden !important">
  <form>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between none" fxLayoutGap="20px">
        <div fxLayout="column" fxFlex>
          <mat-label class="text-weight-bold">Video Input</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select
              placeholder="Select Account Platform"
              [(value)]="defaultVideoDeviceId"
              [disabled]="!liveStudioSetting?.isOpenCamera"
            >
              <mat-option
                *ngFor="let item of videoInputs"
                [value]="item.id"
                (onSelectionChange)="onSelectVideo($event, item)"
                >{{ item.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex>
          <div
            #webcamFrame
            [ngClass]="{ 'black-bg': !source || !liveStudioSetting?.isOpenCamera }"
            fxLayoutAlign="center center"
          >
            <div class="icon">
              <div class="icon-palette" *ngIf="!source || !liveStudioSetting?.isOpenCamera">
                <mat-icon class="mat-40" [style.color]="'#fff'">videocam</mat-icon>
              </div>
              <video
                #videoCamera
                [hidden]="!(liveStudioSetting?.isOpenCamera && source && !this.getActiveBackground())"
                autoplay
                muted
                [id]="'video_sync_' + item.id"
                [srcObject]="source"
                class="vjs-default-skin w-100 lv-video-pls video-cam"
              ></video>
              <canvas
                [hidden]="
                  !(liveStudioSetting?.isOpenCamera && source && this.getActiveBackground() && !this.isSettingWebgl())
                "
                style="
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                  height: 200px;
                "
                [ngStyle]="{ 'background-image': 'url(' + getBackgroundSrc() + ')' }"
                #outputCanvas
                class="vjs-default-skin w-100"
              ></canvas>
              <canvas
                [hidden]="
                  !(liveStudioSetting?.isOpenCamera && source && this.getActiveBackground() && this.isSettingWebgl())
                "
                style="
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                  height: 200px;
                "
                [ngStyle]="{ 'background-image': 'url(' + getBackgroundSrc() + ')' }"
                #outputCanvasWeb
                class="vjs-default-skin w-100"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayout="row" [style.padding]="'0 20px'" [style.margin-top]="'20px'">
  <button fxFlex [style.background]="'#dcd9d9'" (click)="onCancel()" mat-dialog-close mat-flat-button>Cancel</button>
  <button fxFlex color="primary" (click)="onSave()" appPreventDoubleClick [disabled]="loading" mat-flat-button>
    Save
    <mat-icon *ngIf="loading === true">
      <mat-spinner color="warn" diameter="24"> </mat-spinner>
    </mat-icon>
  </button>
</div>
