<div>
  <div class="header-section">
    <h2 *ngIf="action == 'select'">Select a Folder</h2>
    <h2 *ngIf="action == 'move folder'">Move Folder To</h2>
    <h2 *ngIf="action == 'copy folder'">Copy Folder To</h2>
    <h2 *ngIf="action == 'move file'">Move File To</h2>
    <h2 *ngIf="action == 'copy file'">Copy File To</h2>
  </div>

  <form>
    <mat-form-field appearance="outline" class="search-input">
      <input
        matInput
        name="search"
        placeholder="Search folders"
        [(ngModel)]="search_text"
        (ngModelChange)="handleSearchFolders($event)"
        autocomplete="off"
      />
      <mat-icon matSuffix class="cursor-pointer">search</mat-icon>
    </mat-form-field>
  </form>
  <div class="folders">
    <div *ngIf="!loading">
      <!-- <div class="folder" *ngFor="let folder of folders" (click)="select(folder)">
        <div class="icon">
          <mat-icon matSuffix class="cursor-pointer" style="color: #fbc117; user-select: none; font-size: 30px"
            >folder</mat-icon
          >
        </div>
        <div class="folder-name">
          {{ folder.title }}
        </div>
      </div> -->

      <div class="folder-tree">
        <cdk-tree [dataSource]="folders" [treeControl]="treeControl" *ngIf="folders.length != 0">
          <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node">
            <div class="folder-wrapper">
              <div class="folder" (click)="select(node)">
                <div class="btn-wrapper">
                  <button disabled class="btn-icon-con"></button>
                </div>
                <mat-icon style="color: #fbc117">folder</mat-icon>
                <span class="node-title">{{ node.title }}</span>
              </div>
            </div>
          </cdk-nested-tree-node>

          <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node">
            <div class="folder-wrapper">
              <div class="folder" (click)="select(node)">
                <div class="btn-wrapper">
                  <button [attr.aria-label]="'Toggle ' + node.title" cdkTreeNodeToggle class="btn-icon-con">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                  </button>
                </div>
                <mat-icon style="color: #fbc117">folder</mat-icon>
                <span class="node-title">{{ node.title }}</span>
              </div>
            </div>

            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container cdkTreeNodeOutlet></ng-container>
            </div>
          </cdk-nested-tree-node>
        </cdk-tree>
        <div class="empty-folder-tree" *ngIf="folders.length == 0">
          <div class="empty-details" style="font-weight: 500; font-size: 16px; padding: 10px">No Available Folders</div>
          <div *ngIf="action == 'select'">
            <div style="padding: 15px 0px">
              <button mat-raised-button color="primary" (click)="handleViewFileExplorer()">Go to file explorer</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loading-section" *ngIf="loading">
      <mat-spinner color="#125fab" diameter="40"></mat-spinner>
    </div>
    <div class="error-section"></div>
  </div>
</div>
