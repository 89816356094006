<h1 mat-dialog-title *ngIf="!channel">Create external channel stream</h1>
<h1 mat-dialog-title *ngIf="!!channel">Edit external channel stream</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoading; else tplForm"></app-spinner>
  <ng-template #tplForm>
    <div class="channel-thumbnail" class="w-100" *ngIf="channelThumbnail">
      <img
        class="w-100"
        [src]="channelThumbnail"
        alt="channel-thumbnail"
        onerror="this.src='/assets/images/category-default.jpg'"
      />
    </div>
    <input
      type="file"
      accept="image/*"
      [src]="channelImage"
      hidden
      #inputUpload
      (change)="handleUploadChannelThumbnail($event.target.files)"
    />
    <button [style.margin-bottom]="'20px'" mat-flat-button color="primary" (click)="inputUpload.click()" class="w-100">
      UPLOAD NEW LIVE STREAM IMAGE
    </button>
    <form [formGroup]="channelForm">
      <div class="channel-fields" fxLayout="column">
        <mat-label class="text-weight-bold">Title:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="title" matInput placeholder="Please input title" />
          <mat-error *ngIf="f.title.invalid && f.title.touched">Title is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Description:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <textarea formControlName="description" matInput placeholder="Please input description"></textarea>
          <mat-error *ngIf="f.description.invalid && f.description.touched">Description is required</mat-error>
        </mat-form-field>

        <mat-label class="text-weight-bold">Stream Url:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="streamUrl" matInput placeholder="Please input stream url" />
          <mat-error *ngIf="f.streamUrl.invalid && f.streamUrl.touched">Stream url is required</mat-error>
        </mat-form-field>
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <mat-slide-toggle [checked]="isVisible" (change)="doToggleVisible($event)" color="primary">Visible</mat-slide-toggle>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleUpdate()"
  >
    {{ !!channel ? 'Update' : ' Create' }}
  </button>
</div>
