<div mat-dialog-title fxLayout="column" fxLayoutAlign="center center">
  <div class="error-text text-center">ERROR!</div>
  <div class="error-text text-center">The name of the video is already in your video library</div>
</div>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex="8" class="video-thumbnail">
      <img [src]="video.thumbnail" alt="" />
    </div>
    <label [title]="video.title" fxFlex class="video-title">
      {{ video.title }}
    </label>
    <div fxFlex="10">{{ video.duration | durationRounded }}</div>
    <div fxFlex="15">{{ video.job_status }}</div>
    <div fxFlex="21">{{ video.created | date: 'MM-dd-yyyy h:mm a' }}</div>
  </div>
  <div style="margin: 10px 0" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-checkbox [(ngModel)]="isRename" class="text-weight-bold" style="padding-top: 10px" fxFlex="20"
      >Rename:</mat-checkbox
    >
    <mat-form-field appearance="outline" fxFlex="80">
      <input [disabled]="!isRename" [(ngModel)]="title" type="text" matInput />
    </mat-form-field>
  </div>
  <button [disabled]="isRename" mat-flat-button color="primary" class="w-100" (click)="handleReplaceVideo()">
    REPLACE VIDEO ALREADY IN VIDEO LIBRARY
  </button>
</div>

<div mat-dialog-actions fxLayout="row" [style.margin-top]="'20px'" [style.margin-bottom]="'10px'">
  <button fxFlex [style.background]="'#dcd9d9'" mat-flat-button (click)="handleCancel()">CANCEL UPLOAD</button>
  <button
    fxFlex
    color="primary"
    [disabled]="!isRename || !title?.length || title === video.title"
    mat-flat-button
    (click)="handleUploadReName()"
  >
    UPLOAD RENAME VIDEO
  </button>
</div>
