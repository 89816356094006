import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@env/environment';

@Component({
  selector: 'app-website-previewing',
  templateUrl: './website-previewing.view.html',
  styleUrls: ['./website-previewing.view.scss'],
})
export class WebsitePreviewingComponent implements OnInit {
  public sourceUrl: any;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router
  ) {}
  ngOnInit(): void {
    var src = '';
    if (!!this.route.snapshot.queryParams.domain && this.route.snapshot.queryParams.domain.trim() !== '') {
      src = `https://${this.route.snapshot.queryParams.domain}`;
    } else {
      const uuid = this.route.snapshot.params.websiteUUID;
      src = `${environment.objectStorageUrl}/${uuid}/index.html`;
    }
    this.sourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  back() {
    this.location.back();
  }

  preview() {
    const templateId = this.route.snapshot.queryParams.templateId;
    const websiteId = this.route.snapshot.params.websiteUUID;
    if (templateId) {
      this.router.navigateByUrl(`websites/${websiteId}/settings/${templateId}`);
    } else {
      this.router.navigateByUrl(`templates/settings/${websiteId}`);
    }
  }
}
