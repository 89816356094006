<div style="padding: 20px 10px">
  <div id="storage-breakdown">
    <div class="header">
      <div>
        <h1 style="color: #09345e">Storage Breakdown</h1>
        <p style="font-size: 16px">{{ filter.start_date | date }} - {{ filter.end_date | date }}</p>
      </div>
      <div *ngIf="!isLoading">
        <div *ngIf="storageBreakdown.data.length != 0">
          <p>Overage: {{ overage }} GB</p>
          <p>Overage Cost: ${{ cost }}</p>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: column" *ngIf="!isLoading">
      <div class="charts" *ngIf="storageBreakdown.data.length != 0">
        <ngx-charts-bar-horizontal
          [scheme]="colorScheme"
          [results]="single"
          [gradient]="gradient"
          [xAxis]="true"
          [yAxis]="true"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          xAxisLabel="Bandwidth"
          [animations]="true"
          [view]="[600, 150]"
        >
        </ngx-charts-bar-horizontal>
      </div>

      <div *ngIf="storageBreakdown.data.length === 0; else tableContent">
        <div class="noData">
          <strong>Notice:</strong> No data is currently available. Please be advised that data may be delayed.
        </div>
      </div>

      <ng-template #tableContent>
        <div class="table-wrapper">
          <table
            mat-table
            matSort
            [dataSource]="storageBreakdown"
            style="width: 100%"
            (matSortChange)="handleSortChange($event)"
          >
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let element">{{ element.created | date }}</td>
            </ng-container>
            <ng-container matColumnDef="storage">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Storage in GB (Running Total)</th>
              <td mat-cell *matCellDef="let element">{{ element.usage }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['date', 'storage']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['date', 'storage']"></tr>
          </table>
        </div>
      </ng-template>
      <br />
    </div>
  </div>

  <div style="display: flex; justify-content: center" *ngIf="!isLoading">
    <button
      mat-stroked-button
      class="actions-button flex-m-x"
      color="primary"
      (click)="handleDownloadPdfStorageBreakdown()"
    >
      Download
    </button>
  </div>

  <div *ngIf="isLoading" class="loading-spinner-container">
    <mat-progress-spinner
      class="loading-spinner"
      [color]="spinnerColor"
      [mode]="spinnerMode"
      [diameter]="spinnerDiameter"
    ></mat-progress-spinner>
  </div>
</div>
