<div class="flex-p">
  <app-videojs-player
    class="preview-video-player"
    [options]="{
      autoplay: video.is_autoplay,
      controls: true,
      controlBar: {
        liveDisplay: true,
        pictureInPictureToggle: false
      },
      sources: [
        {
          src: videoSource,
          type: 'video/mp4'
        }
      ]
    }"
  >
  </app-videojs-player>
</div>
