import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-folder-dialog',
  templateUrl: './add-folder-dialog.component.html',
  styleUrls: ['./add-folder-dialog.component.scss'],
})
export class AddFolderDialogComponent implements OnInit {
  folderForm: FormGroup;
  dialogTitle: string = null;
  mode: string = null;
  totalVideos: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddFolderModel,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddFolderDialogComponent>
  ) {
    this.dialogTitle = data.dialogTitle;
    this.mode = data.mode;
    this.totalVideos = data.totalVideos;
  }

  ngOnInit(): void {
    this.folderForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(40)]],
      description: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  handleAddFolder() {
    if (this.folderForm.valid) {
      const formData = this.folderForm.value;
      let data = {
        title: formData.title,
        description: formData.description,
        totalVideos: formData.totalVideos,
      };

      this.dialogRef.close(data);
    }
  }
}

export class AddFolderModel {
  constructor(public dialogTitle: string, public mode: string, public totalVideos: number) {}
}
