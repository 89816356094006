<h1 mat-dialog-title>{{ data.action === 'edit' ? 'Edit' : 'Add New' }} Page</h1>
<div mat-dialog-content>
  <form [formGroup]="navigationForm">
    <div class="navigation-fields" fxLayout="column">
      <mat-label class="text-weight-bold">Page Name:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="name" matInput />
        <mat-error *ngIf="f.name.invalid && f.name.touched">Page name is required</mat-error>
      </mat-form-field>
      <mat-label class="text-weight-bold">Page Title:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="title" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold">Page Description:</mat-label>
      <mat-form-field appearance="outline">
        <textarea matInput placeholder="" rows="6" formControlName="description"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="mat-small" color="grey" (click)="dialogRef.close()">Cancel</button>
  <button mat-flat-button class="mat-small" color="primary" (click)="handleCreate()">Save Page</button>
</div>
