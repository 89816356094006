import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { SubUser, UserInfo } from '@app/shared/domain';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public apiService: ApiService) {}

  getProfile(): Observable<UserInfo> {
    return this.apiService.get(`/api/v1/user/profile/`);
  }

  uploadProfile(fmData: FormData): Observable<any> {
    return this.apiService.post(`/api/v1/users/upload-avatar/`, fmData);
  }

  removeAvatar(): Observable<any> {
    return this.apiService.put(`/api/v1/users/remove-avatar/`);
  }

  updateProfile(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/users/update-info/`, data);
  }

  changePassword(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/users/change-password/`, data);
  }

  addSubUser(data: SubUser): Observable<any> {
    return this.apiService.post(`/api/v1/users/add-additional-user/`, data);
  }

  updateSubUser(id: number, data: SubUser): Observable<any> {
    return this.apiService.put(`/api/v1/users/${id}/update-additional-user/`, data);
  }

  removeSubUser(id: number): Observable<any> {
    return this.apiService.delete(`/api/v1/users/${id}/delete-additional-user/`);
  }

  getListSubUsers(): Observable<any> {
    return this.apiService.get(`/api/v1/users/get-additional-user/`);
  }
}
