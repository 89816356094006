<h1 mat-dialog-title>Create New Category</h1>
<div mat-dialog-content>
  <form [formGroup]="categoryForm">
    <div class="category-fields" fxLayout="column">
      <mat-label class="text-weight-bold">Title:</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <input type="text" formControlName="title" matInput placeholder="Please input title" />
        <mat-error *ngIf="f.title.invalid && f.title.touched">Title is required</mat-error>
      </mat-form-field>
      <mat-label class="text-weight-bold">Description:</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <textarea
          type="description"
          formControlName="description"
          matInput
          placeholder="Please input description"
        ></textarea>
      </mat-form-field>
      <mat-label class="text-weight-bold"
        >Status: <i>(Choose if viewers can see this category on your website/apps.)</i></mat-label
      >
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="status" placeholder="Please select status">
          <mat-option *ngFor="let item of configConstans.categoryStatuses | keyvalue" [value]="item.key">{{
            item.value['value']
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.status.invalid && f.status.touched">Status is required</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleCreate()"
  >
    Create
  </button>
</div>
