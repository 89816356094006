import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { Channel, ChannelDefault, ChannelTimezone, Package } from '@app/shared/domain';
import { ChannelService, PackageService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-channel-dialog',
  templateUrl: './create-channel-dialog.component.html',
  styleUrls: ['./create-channel-dialog.component.scss'],
})
export class CreateChannelDialogComponent implements OnInit {
  get f() {
    return this.channelForm?.controls;
  }
  public activeChannel$ = this.channelService.getActiveChannel();
  public channelForm: FormGroup;
  public isLoading: boolean;
  public isLoadingDefault: boolean;
  public channelDefault: ChannelDefault;
  public channelTimezones: ChannelTimezone[] = [];
  public channelPackages: Package[];
  public timezoneSearch: FormControl = new FormControl();
  public filteredTimezone: ReplaySubject<ChannelTimezone[]> = new ReplaySubject<ChannelTimezone[]>(1);
  file: File;
  previewImage: string;

  protected _onDestroy = new Subject<void>();
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateChannelDialogComponent>,
    private notifier: NotifierService,
    private channelService: ChannelService,
    private packageService: PackageService,
    @Inject(MAT_DIALOG_DATA) public data: CreateChannelDialogModel
  ) {}

  ngOnInit() {
    this.isLoadingDefault = true;
    forkJoin([
      this.channelService.getChannelTimezones().pipe(
        map((res) => {
          const usTimezones = res.filter((t) => t.value.startsWith('US/'));
          const anotherTimeZone = res.filter((t) => !t.value.startsWith('US/'));
          return [...usTimezones, ...anotherTimeZone];
        })
      ),
      this.channelService.getChannelDefault(),
      this.packageService.getAllPackages(),
    ]).subscribe(
      ([channelTimezones, channelDefault, channelPackages]) => {
        this.channelTimezones = channelTimezones;
        this.channelDefault = channelDefault;
        this.channelPackages = channelPackages;
        this.buildForm(channelDefault);
        this.isLoadingDefault = false;
        this.searchInit();
      },
      () => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoadingDefault = false;
      }
    );
  }

  searchInit() {
    this.filteredTimezone.next(this.channelTimezones.slice());
    this.timezoneSearch.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterTimezone();
    });
  }

  filterTimezone() {
    if (!this.channelTimezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneSearch.value;
    if (!search) {
      this.filteredTimezone.next(this.channelTimezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezone.next(this.channelTimezones.filter((time) => time.value.toLowerCase().indexOf(search) > -1));
  }

  buildForm(channelDefault: ChannelDefault) {
    this.channelForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50), NoWhitespaceValidator()]],
      description: ['', [Validators.required, NoWhitespaceValidator()]],
      streamKey: [channelDefault.stream_key, [Validators.required, NoWhitespaceValidator()]],
      streamUrl: [channelDefault.stream_url, [Validators.required, NoWhitespaceValidator()]],
      // plan: ['', [Validators.required]],
      channelProfile: [channelDefault.channel_profile, [Validators.required]],
      timezone: ['', [Validators.required]],
      isActive: [true],
      isDefault: [false],
      // isResetStreamKey: [false],
    });
  }

  handleCreate(): void {
    if (this.channelForm.invalid) {
      CommonHelpers.validateAllFormFields(this.channelForm);
      return;
    }
    this.isLoading = true;
    const {
      title,
      description,
      streamKey,
      streamUrl,
      //  plan,
      channelProfile,
      isActive,
      isDefault,
      // isResetStreamKey,
      timezone,
    } = this.channelForm.value;
    const slug = CommonHelpers.createSlug(title);
    const newChannel = {
      title,
      description,
      slug,
      timezone,
      //   package: plan,
      channel_profile: channelProfile,
      stream_key: streamKey,
      stream_url: streamUrl,
      // reset_stream_key: isResetStreamKey,
      active: isActive,
    } as Channel;

    const data = new FormData();
    // fmData.append('title', title);
    // fmData.append('slug', slug);
    // fmData.append('description', description);
    // fmData.append('streamKey', streamKey);
    // fmData.append('streamUrl', streamUrl);
    // fmData.append('package', plan);
    // fmData.append('channelProfile', channelProfile);
    // fmData.append('active', isActive);
    // // fmData.append('isDefault', isDefault);
    // fmData.append('resetStreamKey', isResetStreamKey);
    // fmData.append('timezone', timezone);
    // if (this.file?.size) {
    //   fmData.append('image', this.file);
    // }
    data.append('title', title);
    data.append('slug', slug);
    data.append('description', description);
    data.append('stream_key', streamKey);
    data.append('stream_url', streamUrl);
    data.append('timezone', timezone);
    //  data.append('package', plan);
    data.append('channel_profile', channelProfile);
    // data.append('reset_stream_key', isResetStreamKey);
    data.append('active', isActive);
    if (this.file?.size) {
      data.append('image', this.file);
    }
    this.channelService.createChannel(data).subscribe(
      (res) => {
        this.notifier.notify('success', `Successfully created ${title} channel`);
        this.channelService.getDropDownChannel();
        if (isActive) this.channelService.changeActiveChannel(res);
        this.dialogRef.close(true);
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  handleUploadChannelThumbnail(files: FileList) {
    const file = files[0];
    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.previewImage = e.target.result as string;
    };
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

export class CreateChannelDialogModel {}
