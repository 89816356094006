import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tutorial-list-mat-dialog',
  templateUrl: './tutorial-list-mat-dialog.component.html',
  styleUrls: ['./tutorial-list-mat-dialog.component.scss'],
})
export class TutorialListMatDialogComponent implements OnInit {
  public videoSource: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<TutorialListMatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.videoSource = this.data[0]?.source;
  }

  openTutorial(videoId: number): void {
    this.videoSource = this.data[videoId]?.source;
  }

  handleCancel() {
    this.dialogRef.close(false);
  }
}
