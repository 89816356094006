<app-spinner *ngIf="isLoading"></app-spinner>
<div mat-dialog-title style="text-align: center">
  <div *ngIf="accounts && accounts.length === 0" [style.font-size]="'24px'">ERROR</div>
  <div *ngIf="accounts && accounts.length !== 0">Connected Social Media Accounts</div>
</div>
<div mat-dialog-content>
  <div *ngIf="accounts && accounts.length === 0" fxLayout="column" fxLayoutGap="16px" style="text-align: center">
    <div>There is no connected Social media Accounts</div>
  </div>
  <div *ngIf="accounts && accounts.length !== 0" fxLayout="column" class="socials">
    <div
      *ngFor="let account of accounts; let i = index"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="social-item"
    >
      <div xLayout="row" fxLayoutAlign="start center">
        <img src="/assets/icons/{{ getPathIcon(account.provider) }}" alt="LOGO" />
        <div [style.font-size]="'18px'">{{ account.name }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="openAccountSettingUpdateDialog(account)" [disabled]="reStreamStatus">
          <mat-icon [style.color]="'#000'">edit</mat-icon>
        </button>
        <button mat-icon-button (click)="handleDeleteAccount(account)">
          <mat-icon [style.color]="'#bb0f13'">delete </mat-icon>
        </button>
        <mat-slide-toggle
          [(ngModel)]="account.active"
          (change)="changeStatusAccount(account)"
          [disabled]="reStreamStatus"
        ></mat-slide-toggle>
      </div>
    </div>
    <!-- <form class="form-social">
      <mat-label class="text-weight-bold">Title </mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <input type="text" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold">Description </mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <textarea rows="6" matInput></textarea>
      </mat-form-field>
    </form> -->
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="16px" style="text-align: center">
  <button fxFlex="calc(70% - 16px)" color="primary" mat-stroked-button mat-dialog-close (click)="onAddSocialMedia()">
    + Social Media
  </button>
  <button fxFlex="calc(30% - 16px)" color="primary" mat-flat-button mat-dialog-close>Close</button>
</div>
