<h1 mat-dialog-title class="text-center">ADDING ADDITIONAL CHANNEL</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoadingDefault; else tplForm"></app-spinner>
  <ng-template #tplForm>
    <form [formGroup]="channelForm">
      <div class="channel-fields" fxLayout="column">
        <mat-label class="text-weight-bold">Name:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="name" matInput placeholder="Please input name" />
          <mat-error *ngIf="f.name.invalid && f.name.touched && (f.name.errors?.required || f.name.errors?.whitespace)"
            >Name is required</mat-error
          >
          <mat-error *ngIf="f.name.invalid && f.name.touched && f.name.errors?.maxlength">
            Length from 1 to 50 characters</mat-error
          >
        </mat-form-field>
        <mat-label class="text-weight-bold">Email:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="email" formControlName="email" matInput placeholder="Please input email" />
          <mat-error *ngIf="f.email.invalid && f.email.touched">email is required</mat-error>
        </mat-form-field>
        <!-- <mat-checkbox color="primary" disabled formControlName="isActive">Active Channel Set as active</mat-checkbox> -->
        <!-- <mat-checkbox color="primary" formControlName="isDefault">Default Channel Set as active</mat-checkbox> -->
        <!-- <mat-checkbox color="primary" formControlName="isResetStreamKey"
          >Reset Stream Key Used to reset stream key for a given channel</mat-checkbox
        > -->

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [style.font-size.px]="35" [style.width.px]="45" [style.height.px]="35">cast</mat-icon>
            <span [style.line-height]="1.2">ADDITIONAL<br />CHANNELS</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button
              mat-icon-button
              type="button"
              (click)="handleChannelCount('remove')"
              [disabled]="disableRemoveButton"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <input type="text" formControlName="quantity" matInput [style.width.px]="30" class="item-numbers" />
            <button mat-icon-button type="button" (click)="handleChannelCount('add')">
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </div>
          <span>${{ channelPrice }}/each</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" class="total-price-box">
          <span>Total: {{ f.total_price.value }}</span>
          <input type="hidden" formControlName="total_price" />
        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>cast</mat-icon><span>ADDITIONAL CHANNELS</span>
          </div>

          <button mat-icon-button>
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
          <span></span>

        </div>

        <div fxLayoutAlign="start center">
          <mat-icon>cast</mat-icon><span>ADDITIONAL CHANNELS</span>
        </div>

        <button mat-icon-button>
          <mat-icon>remove_circle_outline</mat-icon>
        </button>

        <button mat-icon-button>
          <mat-icon>add_circle_outline</mat-icon>
        </button> -->
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="w-100" color="primary" [loading]="isLoading" (click)="handleCreate()">
    Submit Request to Add Channel Now
  </button>

  <button mat-button mat-dialog-close class="w-100">Cancel</button>
</div>
