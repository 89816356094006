import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  public breadcrumb: string;
  languages = [
    { code: 'en', label: 'English', icon: 'icons8-usa-48.png' },
    { code: 'es', label: 'Spanish', icon: 'icons8-spain-48.png' },
    { code: 'fr', label: 'French', icon: 'icons8-france-48.png' },
    { code: 'jp', label: 'Japanese', icon: 'icons8-japan-48.png' },
    { code: 'da', label: 'Danish', icon: 'icons8-netherlands-48.png' },
    { code: 'de', label: 'German', icon: 'icons8-germany-48.png' },
    { code: 'ru', label: 'Russian', icon: 'icons8-russia-48.png' },
    { code: 'tw', label: 'Chinese (Traditional)', icon: 'icons8-china-48.png' },
    { code: 'cn', label: 'Chinese (Simplified) ', icon: 'icons8-china-48.png' },
  ];
  selectedLanguage: string;
  selectedLanguageIcon: string;
  selectedLanguageLabel: string;
  dropdownOpen = false;
  defaultLanguageIcon = 'icons8-usa-48.png';

  constructor(public router: Router, private route: ActivatedRoute, private translate: TranslateService) {}

  ngOnInit() {
    if (this.route.children.length) {
      this.route.children[0].data.subscribe((data) => {
        this.breadcrumb = data.breadcrumb;
      });
    }

    const savedLanguage = localStorage.getItem('language');

    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('language', this.selectedLanguage);
    }

    this.selectedLanguage = localStorage.getItem('language') || 'en';
    const selectedLang = this.languages.find((lang) => lang.code === this.selectedLanguage);
    this.selectedLanguageIcon = selectedLang ? selectedLang.icon : this.defaultLanguageIcon;
    this.selectedLanguageLabel = selectedLang ? selectedLang.label : 'Select Language';
    this.translate.setDefaultLang('en');
    this.translate.use(this.selectedLanguage);
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectLanguage(code: string, icon: string, label: string): void {
    this.selectedLanguage = code;
    this.selectedLanguageIcon = icon;
    this.selectedLanguageLabel = label;
    this.dropdownOpen = false;
    // Save the selected language to localStorage
    localStorage.setItem('language', code);
    this.translate.use(this.selectedLanguage);
  }

  onLanguageChange(languageCode: string) {
    this.selectedLanguage = languageCode;
    this.translate.use(languageCode);
    localStorage.setItem('language', languageCode);
  }

  getFlagUrl(code: string): string {
    const flagMap: any = {
      fr: 'icons8-france-48.png',
      da: 'icons8-netherlands-48.png',
      de: 'icons8-germany-48.png',
      en: 'icons8-usa-48.png',
      jp: 'icons8-japan-48.png',
      es: 'icons8-spain-48.png',
    };

    return `/assets/icons/flags/${flagMap[code]}`;
  }
}
