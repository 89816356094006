import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Video } from '@app/shared';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BroadcastLiveService {
  private readonly activeRecording$: BehaviorSubject<Video> = new BehaviorSubject<Video>(null);
  constructor(private apiService: ApiService) {}

  setRecordingMode(channelId: string, mode: string): Observable<any> {
    return this.apiService.put(`/api/v1/channels/${channelId}/save-recording-mode/`, {
      mode,
    });
  }

  startRecording(channelId: string) {
    return this.apiService.get(`/api/v1/livestream/recording/${channelId}/start/`);
  }

  stopRecording(channelId: string) {
    return this.apiService.get(`/api/v1/livestream/recording/${channelId}/stop/`);
  }

  getRecordingStatus(channelId: string) {
    return this.apiService.get(`/api/v1/livestream/recording-check/${channelId}/`);
  }
}
