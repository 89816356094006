import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
@Component({
  selector: 'app-tvstartup-sale-dialog',
  templateUrl: './tvstartup-sale-dialog.component.html',
  styleUrls: ['./tvstartup-sale-dialog.component.scss'],
})
export class TvstartupSaleDialogComponent implements OnInit {
  targetDate: moment.Moment = moment('2024-03-26T00:00:00-05:00'); // March 25th, 2024 at 12:00AM CST
  days: string = '0';
  hours: string = '0';
  minutes: string = '0';
  seconds: string = '0';
  isSaleEnded: boolean = false;
  private countdownInterval: any;
  sale_website: string = 'https://sales.tvstartup.com/customers-spring-sale-2024';
  showContent = false;

  constructor(
    public dialogRef: MatDialogRef<TvstartupSaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.updateCountdown();
    this.countdownInterval = setInterval(() => {
      this.updateCountdown();
    }, 1000);

    if (this.isSaleEnded) {
      this.dialogRef.close(false);
    }

    this.waitForAssetsToLoad();
  }

  handleClose() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    clearInterval(this.countdownInterval);
  }

  updateCountdown(): void {
    const now = moment();
    const difference = moment.duration(this.targetDate.diff(now));

    if (difference.asMilliseconds() <= 0) {
      this.isSaleEnded = true;
      clearInterval(this.countdownInterval);
    } else {
      this.isSaleEnded = false;
      this.days = this.padZero(difference.days());
      this.hours = this.padZero(difference.hours());
      this.minutes = this.padZero(difference.minutes());
      this.seconds = this.padZero(difference.seconds());
    }
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  goToExternalPage(): void {
    window.open(this.sale_website, '_blank');
  }

  waitForAssetsToLoad(): void {
    const images = document.querySelectorAll('img'); // Select all images

    let loadedCount = 0; // Counter for loaded images

    const checkAllImagesLoaded = () => {
      loadedCount++;
      if (loadedCount === images.length) {
        // All images are loaded
        this.showContent = true; // Show the content
      }
    };

    images.forEach((img) => {
      if (img.complete) {
        checkAllImagesLoaded();
      } else {
        img.onload = checkAllImagesLoaded; // Listen for the load event
      }
    });
  }
}

export class TvstartupSaleModel {
  constructor(public image_src: any, public sale_url: any) {}
}
