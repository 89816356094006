<h1 mat-dialog-title>Add Tags</h1>
<div mat-dialog-content>
  <form [formGroup]="tagForm">
    <div class="tag-fields" fxLayout="column">
      <mat-label class="text-weight-bold">Tags: ex: tech,entertainment</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <input type="text" formControlName="name" matInput placeholder="Please input name" />
        <mat-hint>comma separated for multiple tags</mat-hint>
        <mat-error *ngIf="f.name.invalid && f.name.touched">Name is required</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="mat-small" [style.margin-left]="'auto'" color="primary" (click)="handleCreate()">
    Create
  </button>
</div>
