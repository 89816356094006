import { Component, Input, OnInit } from '@angular/core';
import { TemplateService } from '@app/shared/services';
import { Template } from '@app/shared/domain/template.domain';

@Component({
  selector: 'app-project',
  templateUrl: './active-project.component.html',
  styleUrls: ['./active-project.component.scss'],
})
export class ActiveProjectComponent implements OnInit {
  @Input('buttonClass') buttonClass = 'mat-flat-button';
  @Input('color') color = '';
  public projectList$ = this.templateService.getTemplate({ category: 2 });
  public activeProject$ = this.templateService.getActiveProject();
  public projectLoading$ = this.templateService.getIsLoading();

  constructor(private templateService: TemplateService) {}

  ngOnInit() {}

  public changeActiveProject(project: Template) {
    this.templateService.setActiveProject(project);
  }
}
