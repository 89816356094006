import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreatePageSettingsDialogComponent } from '../create-page-settings-dialog/create-page-settings-dialog.component';
import { TemplateService } from '@app/shared/services';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.scss'],
})
export class PageSettingsComponent implements OnInit, OnChanges {
  pages: any[] = [];

  @Input() settings: any;
  @Output() pageSettingsChange: EventEmitter<any> = new EventEmitter();

  constructor(private readonly dialog: MatDialog, private templateService: TemplateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings.currentValue && changes.settings.currentValue.length) {
      this.pages = changes.settings.currentValue;
    }
  }

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);
  }

  openDialogCreate(data: any) {
    const dialogRef = this.dialog.open(CreatePageSettingsDialogComponent, {
      width: '1000px',
      data,
    });

    dialogRef.afterClosed().subscribe((rs: any) => {
      if (!rs) {
        return;
      }
      let callbackData = { ...rs, action: data.action };
      if (data.action === 'edit') {
        callbackData = { ...callbackData, key: data.formValue.name };
      }
      this.pageSettingsChange.emit(callbackData);
      this.templateService.updatePageSettings(callbackData);
    });
  }

  addPageItem() {
    this.openDialogCreate({
      action: 'add',
    });
  }

  editPageItem(value: any, index: number) {
    this.openDialogCreate({
      action: 'edit',
      formValue: value,
      index,
    });
  }

  deletePageItem(value: any, index: number) {
    const filteredPages = this.pages.filter((page) => page.name !== value.name);
    this.pageSettingsChange.emit({
      delete: true,
      data: filteredPages,
    });
    this.templateService.updatePageSettings({
      delete: true,
      data: filteredPages,
    });
  }
}
