import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentType, PurchaseLink } from '@app/app.constants';
import { CommonHelpers } from '@app/helpers';
import { ValidatorFrom } from '@app/helpers/validators';
import { ChannelService, SubscriptionService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { filter } from 'rxjs/operators';

function customValidator(fromControlName: string, toControlName: string) {
  return (formGroup: FormGroup) => {
    const fromControl = formGroup.controls[fromControlName];
    const toControl = formGroup.controls[toControlName];
    if (fromControl.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    const errors: ValidationErrors = {};
    if (!toControl.value || toControl.value === 'None') {
      errors.required = true;
    }

    // set error on matchingControl if validation fails
    if (!fromControl.value && Object.keys(errors).length) {
      toControl.setErrors(errors);
    } else {
      toControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'app-vod-pricing-dialog',
  templateUrl: './vod-pricing-dialog.component.html',
  styleUrls: ['./vod-pricing-dialog.component.scss'],
})
export class VodPricingDialogComponent implements OnInit {
  public pricingForm: FormGroup;
  public isLoading: boolean;
  public subsPlans: any[];
  public publishedSites: any[];
  public channelId: string;
  public appTemplateId: string;
  pricing: any;
  contentType: number;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private channelService: ChannelService,
    private subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: VodPricingDialogModel,
    public dialogRef: MatDialogRef<VodPricingDialogComponent>
  ) {
    this.pricing = data.pricing;
    this.subsPlans = data.subsPlans;
    this.publishedSites = data.publishedSites;
    this.contentType = data.contentType;
    this.appTemplateId = data.appTemplateId;
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.pricingForm = this.formBuilder.group(
      {
        is_require_access: [true],
        subs_plan_uuids: [{ value: this.pricing?.subs_plan_uuids, disabled: !!this.pricing?.price }],
        enablePurchasePrice: [!!this.pricing?.price],
        price: [{ value: this.pricing?.price, disabled: !this.pricing?.price }],
        is_lifetime: [{ value: this.pricing?.is_lifetime, disabled: !this.pricing?.price }],
        isLimitedTimeAccess: [{ value: !!this.pricing?.expires_in, disabled: !this.pricing?.price }],
        expires_in: [{ value: this.pricing?.expires_in, disabled: !this.pricing?.price || this.pricing?.is_lifetime }],
        purchaseLinkInfo: this.formBuilder.group({
          published_site: [this.pricing?.template_uuid || this.publishedSites[0]?.uuid],
          payment_way: [this.pricing?.purchase_link_type || 1],
          payment_forms: [{ value: !this.pricing?.price ? '' : this.pricing?.embedCode, disabled: true }],
        }),
      },
      {
        validator: [
          ValidatorFrom('enablePurchasePrice', 'price'),
          customValidator('enablePurchasePrice', 'subs_plan_uuids'),
          ValidatorFrom('isLimitedTimeAccess', 'expires_in'),
        ],
      }
    );
    this.purchaseLinkTypeChange(this.pricing?.purchase_link_type);
    this.pricingForm?.get('enablePurchasePrice').valueChanges.subscribe((checked) => {
      if (!checked) {
        this.pricingForm?.get('price').disable();
        this.pricingForm?.get('is_lifetime').disable();
        this.pricingForm?.get('isLimitedTimeAccess').disable();
        this.pricingForm?.get('expires_in').disable();
        this.pricingForm?.get('subs_plan_uuids').enable();
        // this.pricingForm?.get('purchaseLinkInfo').get('published_site').disable();
        // this.pricingForm?.get('purchaseLinkInfo').get('payment_way').disable();
        // this.pricingForm?.get('purchaseLinkInfo').get('published_site').setValue('');
        // this.pricingForm?.get('purchaseLinkInfo').get('payment_way').setValue(0);
        // this.pricingForm?.get('purchaseLinkInfo').get('payment_forms').setValue('');
      } else {
        this.pricingForm?.get('price').enable();
        this.pricingForm?.get('is_lifetime').enable();
        this.pricingForm?.get('isLimitedTimeAccess').enable();
        this.pricingForm?.get('expires_in').enable();
        this.pricingForm?.get('subs_plan_uuids').disable();
        // this.pricingForm?.get('purchaseLinkInfo').get('published_site').enable();
        // this.pricingForm?.get('purchaseLinkInfo').get('payment_way').enable();
        // this.pricingForm?.get('purchaseLinkInfo').get('payment_forms').setValue('');
      }
    });

    this.pricingForm?.get('is_lifetime').valueChanges.subscribe((checked) => {
      if (checked) {
        this.pricingForm?.get('isLimitedTimeAccess').setValue(false);
        this.pricingForm?.get('expires_in').disable();
      }
    });

    this.pricingForm?.get('isLimitedTimeAccess').valueChanges.subscribe((checked) => {
      if (checked) {
        this.pricingForm?.get('is_lifetime').setValue(false);
        this.pricingForm?.get('expires_in').enable();
      } else {
        this.pricingForm?.get('expires_in').disable();
      }
    });

    this.pricingForm
      ?.get('purchaseLinkInfo')
      .get('payment_way')
      .valueChanges.subscribe((value) => {
        this.purchaseLinkTypeChange(value);
      });
    this.pricingForm
      ?.get('purchaseLinkInfo')
      .get('published_site')
      .valueChanges.subscribe((value) => {
        if (value) {
          const embeddedCode = this.generateEmbedCodeMedia(value);
          this.pricingForm?.get('purchaseLinkInfo').get('payment_forms').setValue(embeddedCode);
        }
      });
  }

  get f() {
    return this.pricingForm?.controls;
  }

  purchaseLinkTypeChange(value: number) {
    const { purchaseLinkInfo } = this.pricingForm.value;
    const { published_site } = purchaseLinkInfo || {};
    let embeddedCode = '';
    if (value === 1) {
      this.pricingForm?.get('purchaseLinkInfo').get('published_site').enable();
      embeddedCode = this.generateEmbedCodeMedia(published_site);
    }
    if (value === 2) {
      this.pricingForm?.get('purchaseLinkInfo').get('published_site').disable();
      this.pricingForm?.get('purchaseLinkInfo').get('published_site').setValue('');
      embeddedCode = this.generateEmbedCodeMedia(this.appTemplateId);
    }
    this.pricingForm?.get('purchaseLinkInfo').get('payment_forms').setValue(embeddedCode);
  }

  generateEmbedCodeMedia(template_id: string) {
    return template_id && this.pricing?.content_uuid
      ? CommonHelpers.generateEmbedCodeMedia(
          template_id,
          `${PurchaseLink[this.contentType]}?categoryId=${this.pricing?.content_uuid}&isOrderProcessing=true`
        )
      : '';
  }

  handleSave() {
    if (this.pricingForm.invalid) {
      CommonHelpers.validateAllFormFields(this.pricingForm);
      return;
    }

    let data = {};
    const {
      subs_plan_uuids,
      expires_in,
      price,
      is_lifetime,
      is_require_access,
      enablePurchasePrice,
      purchaseLinkInfo,
    } = this.pricingForm.value;
    const { payment_way, published_site } = purchaseLinkInfo || {};
    if (price < 0) {
      this.notifier.notify('error', 'Price must be greater than 0');
      return;
    }

    if (enablePurchasePrice && !expires_in && !is_lifetime) {
      this.notifier.notify('error', 'You need set Lifetime Access or Limited Time Access');
      return;
    }

    if (!enablePurchasePrice) {
      data = {
        subs_plan_uuids,
        is_require_access,
        is_lifetime: false,
        expires_in: '',
        price: 0,
        purchase_link_type: payment_way,
        template_uuid: published_site,
        content_type: this.contentType,
      };
    } else {
      data = {
        price,
        expires_in,
        is_lifetime,
        is_require_access,
        purchase_link_type: payment_way,
        template_uuid: published_site,
        content_type: this.contentType,
      };
    }
    this.dialogRef.close(data);
  }

  handleCancel() {
    this.dialogRef.close(false);
  }
}

export class VodPricingDialogModel {
  constructor(
    public appTemplateId: any,
    public pricing: any,
    public subsPlans: any,
    public publishedSites: any,
    public contentType: number = ContentType.VIDEO
  ) {}
}
