<div style="padding: 10px">
  <div class="section-header" style="display: flex; align-items: center; justify-content: space-between">
    <div class="action-title" style="font-size: 24px; font-weight: bold">Select Action</div>
    <div class="close-btn">
      <button mat-mini-fab color="primary" (click)="onDismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="message" style="padding: 30px 0px; font-size: 16px">
    You've chosen <strong>{{ no_of_items }}</strong> {{ no_of_items > 1 ? 'items' : 'item' }} to move to:
    <strong>{{ folder_destination }}</strong
    >. What action would you like to take?
  </div>

  <div class="button-actions" style="display: flex; align-items: center; justify-content: flex-end; gap: 10px">
    <button mat-stroked-button color="primary" (click)="onConfirm('copy')">Copy Items</button>
    <button mat-flat-button color="primary" (click)="onConfirm('move')">Move Items</button>
  </div>
</div>
