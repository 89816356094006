<div class="unpaid-invoice-dialog">
  <div class="header-section">
    <div class="btn-wrapper">
      <button mat-flat-button color="primary" (click)="logOut()">Log out</button>
    </div>
  </div>
  <div class="main-section" *ngIf="!loading">
    <div class="wrapper">
      <div class="contentCon">
        <div class="logoCon">
          <img src="/assets/images/tvstartup-logo.png" alt="" style="width: 200px" />
        </div>
        <div class="messageCon">
          <h1 class="titleCon">Hello, {{ firstName }} 👋</h1>
          <div class="subtitleCon">
            Your account has been deactivated due to unsettled fees. To restore full access to your channels and resume
            uninterrupted service, please ensure all outstanding fees are paid.
          </div>
        </div>
        <div class="channelWrapper" *ngIf="channels.length != 0">
          <div class="channelsCon">
            <div class="section-title" style="font-weight: bold">Channels</div>
            <div class="channels">
              <div class="channel" *ngFor="let channel of channels" style="position: relative">
                <img [src]="channel.image" alt="" style="width: 100%; height: 100%; object-fit: cover" />
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.507);
                    width: 100%;
                    height: 100%;
                    color: white;
                    text-align: center;
                    top: 0px;
                    flex-direction: column;
                  "
                >
                  <div
                    style="
                      font-size: 22px;
                      font-weight: 500;
                      width: 90%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ channel.title }}
                  </div>
                  <div style="font-size: 16px; font-weight: 500">Disabled</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-message-con">
          <div class="subtitleCon">For assistance, please reach out to our support team</div>
          <div class="phoneCon" style="font-weight: bold">(325) 268-0040 (USA)</div>
        </div>
        <br />
        <div>
          <button mat-mini-fab color="primary" (click)="scrollDown()">
            <mat-icon>arrow_downward</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="wrapper" *ngIf="dataSource?.length != 0">
      <div class="wrapper-form">
        <h2 style="color: rgb(37, 37, 37)">Unsettled Invoice</h2>
        <br />
        <div class="invoices-section" *ngIf="!paymentLoading && !cardLoading">
          <div class="invoice-section-title">Order Summary</div>
          <div class="items">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Item</th>
                <td mat-cell *matCellDef="let item">
                  <div class="item-content">{{ item.name }}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>Quantity</th>
                <td mat-cell *matCellDef="let item">
                  <div class="item-content">{{ item.quantity }}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>Price</th>
                <td mat-cell *matCellDef="let item">
                  <div class="item-content">${{ item.total_price.toFixed(2) }}</div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div class="item-section sub-total-section">
              <div class="sub-total-title">Sub Total</div>
              <div class="amount">${{ subTotal.toFixed(2) }}</div>
            </div>
            <div class="item-section discount">
              <div class="discount-title">Discount</div>
              <div class="amount">-${{ totalDiscount.toFixed(2) }}</div>
            </div>
            <div class="item-section total">
              <div class="total-title">Total</div>
              <div class="amount">${{ totalPrice.toFixed(2) }}</div>
            </div>
          </div>
          <div class="card-note" *ngIf="!has_active_card || noCardError">
            We detected that there is no active card present in your account. Please add new card details to proceed.
          </div>
          <div class="card-note" *ngIf="!noCardError && hasError">
            We are unable to process the request at the moment as, The transaction was unsuccessful
          </div>
          <div class="btns-con">
            <button mat-stroked-button color="primary" (click)="addNewCardDetails()">Add New Card Details</button>
            <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="!has_active_card || noCardError">
              Pay Invoice
            </button>
          </div>
        </div>
        <div class="loading-payment-section" *ngIf="paymentLoading || cardLoading">
          <div>
            <mat-spinner color="primary" diameter="30"> </mat-spinner>
          </div>
          <span *ngIf="paymentLoading">Processing Payment</span>
          <span *ngIf="cardLoading">Retrieving From</span>
        </div>
      </div>
    </div>
    <div class="invoice-error" *ngIf="dataSource?.length == 0">
      <div class="img-wrapper">
        <img src="assets/images/error-cannot-find.png" alt="" />
      </div>
      <div class="content-wrapper">
        <span
          >We are currently unable to retrieve your unpaid invoices. Please reach out to support for assistance.</span
        >
      </div>
    </div>
  </div>
  <div class="loading-section" *ngIf="loading">
    <mat-spinner diameter="50"> </mat-spinner>
  </div>
</div>
