import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { GraphicWebgl, LiveSetting, LiveStudioRq } from '@app/shared';
import {
  Banner,
  BannerTickerStyle,
  Graphic,
  LiveDemoItem,
  LiveDemoSourceType,
  SourceItem,
  ThemeStyle,
} from '@app/shared/domain';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { DeviceSetting } from '@app/cms/broadcast-studio/models/device-setting';
import { ChatMessage } from '@app/cms/broadcast-studio/models/chat-message';
import { LiveScreenIndex } from '@app/app.constants';
import { CameraStreamerService } from '@shared/services/camera-streamer';

@Injectable({
  providedIn: 'root',
})
export class BroadcastStudioService {
  private readonly showChat$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly showBanner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _liveStudioSetting: BehaviorSubject<LiveSetting> = new BehaviorSubject<LiveSetting>(null);
  private readonly _liveStudioSettingPreview: BehaviorSubject<LiveSetting> = new BehaviorSubject<LiveSetting>(null);
  private readonly _overlayBackgrounds: BehaviorSubject<Graphic[]> = new BehaviorSubject<Graphic[]>([]);
  private readonly _logos: BehaviorSubject<Graphic[]> = new BehaviorSubject<Graphic[]>([]);
  private readonly _graphicBackgrounds: BehaviorSubject<Graphic[]> = new BehaviorSubject<Graphic[]>([]);
  private readonly _graphicVideos: BehaviorSubject<Graphic[]> = new BehaviorSubject<Graphic[]>([]);
  private readonly _streamMessages: BehaviorSubject<any> = new BehaviorSubject<any>([
    {
      uuid: uuidv4(),
      avatar: 'assets/img/broadcast/logo-tv-chat.png',
      type: 'all',
      name: 'TvStartup',
      content: 'Comments on your social media will display name here. Click on them to show on the stream',
      is_display: false,
    },
  ]);
  private readonly _streamChat: BehaviorSubject<any> = new BehaviorSubject<any>({
    type: 'all',
    messages: this._streamMessages.getValue(),
  });
  private readonly _sourceItems: BehaviorSubject<SourceItem[]> = new BehaviorSubject<SourceItem[]>([]);
  private readonly _sourceItemsActive: BehaviorSubject<SourceItem[]> = new BehaviorSubject<SourceItem[]>([]);
  private readonly _liveDemoItems: BehaviorSubject<LiveDemoItem[]> = new BehaviorSubject<LiveDemoItem[]>([]);
  readonly liveDemoItems$ = this._liveDemoItems.asObservable();
  readonly streamChat$ = this._streamChat.asObservable();
  readonly graphicVideos$ = this._graphicVideos.asObservable();
  readonly streamMessages$ = this._streamMessages.asObservable();
  readonly liveStudioSetting$ = this._liveStudioSetting.asObservable();
  readonly sourceItems$ = this._sourceItems.asObservable();
  readonly sourceItemsActive$ = this._sourceItemsActive.asObservable();
  readonly overlayBackgrounds$ = this._overlayBackgrounds.asObservable();
  readonly logos$ = this._logos.asObservable();
  readonly graphicBackgrounds$ = this._graphicBackgrounds.asObservable();
  readonly devicesSetting$ = new BehaviorSubject<DeviceSetting>(null);
  readonly privateMessages$ = new BehaviorSubject<ChatMessage[]>([]);
  readonly numUnreadMessage$ = new BehaviorSubject(0);
  peersEstablished: any[];

  get devicesSetting(): DeviceSetting {
    return this.devicesSetting$.getValue();
  }

  set devicesSetting(val: DeviceSetting) {
    this.devicesSetting$.next(val);
  }

  get liveDemoItems(): LiveDemoItem[] {
    return this._liveDemoItems.getValue();
  }

  set liveDemoItems(val: LiveDemoItem[]) {
    this._liveDemoItems.next(val);
  }

  get graphicVideos(): Graphic[] {
    return this._graphicVideos.getValue();
  }

  set graphicVideos(val: Graphic[]) {
    this._graphicVideos.next(val);
  }

  get overlayBackgrounds(): Graphic[] {
    return this._overlayBackgrounds.getValue();
  }

  set overlayBackgrounds(val: Graphic[]) {
    this._overlayBackgrounds.next(val);
  }

  get logos(): Graphic[] {
    return this._logos.getValue();
  }

  set logos(val: Graphic[]) {
    this._logos.next(val);
  }

  get graphicBackgrounds(): Graphic[] {
    return this._graphicBackgrounds.getValue();
  }

  set graphicBackgrounds(val: Graphic[]) {
    this._graphicBackgrounds.next(val);
  }

  get liveStudioSetting(): LiveSetting {
    return this._liveStudioSetting.getValue();
  }

  set liveStudioSetting(val: LiveSetting) {
    this._liveStudioSetting.next(val);
  }

  get streamChat() {
    return this._streamChat.getValue();
  }

  set streamChat(val: any) {
    this._streamChat.next(val);
  }

  get streamMessages() {
    return this._streamMessages.getValue();
  }

  set streamMessages(val: any) {
    this._streamMessages.next(val);
  }

  get privateMessages(): ChatMessage[] {
    return this.privateMessages$.getValue();
  }

  set privateMessages(val: ChatMessage[]) {
    this.privateMessages$.next(val);
  }

  get numUnreadMessage(): number {
    return this.numUnreadMessage$.getValue();
  }

  set numUnreadMessage(val: number) {
    this.numUnreadMessage$.next(val);
  }

  notifyOtherPeers(data: any) {
    if (this.peersEstablished) {
      this.peersEstablished.forEach((peerEstablished) => {
        peerEstablished.peer.send(JSON.stringify(data));
      });
    }
  }

  getDeviceSettingFromStorage(): DeviceSetting {
    const settingStr: string = localStorage.getItem('STUDIO_DEVICE_SETTING');
    if (settingStr) {
      return JSON.parse(settingStr) as DeviceSetting;
    }
    return null;
  }

  updateDeviceSettingToStorage(setting: DeviceSetting) {
    if (setting) {
      localStorage.setItem('STUDIO_DEVICE_SETTING', JSON.stringify(setting));
    }
  }

  async initDeviceSetting() {
    let setting: DeviceSetting = this.getDeviceSettingFromStorage();
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (setting && devices && devices.length) {
      devices.forEach(({ deviceId, kind, label }) => {
        switch (kind) {
          case 'audioinput':
            if (deviceId !== setting.audioInputDevice?.id && label === setting.audioInputDevice?.label) {
              setting = { ...setting, audioInputDevice: { ...setting.audioInputDevice, id: deviceId } };
            }
            break;
          case 'audiooutput':
            if (deviceId !== setting.audioOutputDevice?.id && label === setting.audioOutputDevice?.label) {
              setting = { ...setting, audioOutputDevice: { ...setting.audioOutputDevice, id: deviceId } };
            }
            break;
          case 'videoinput':
            if (deviceId !== setting.videoDevice?.id && label === setting.videoDevice?.label) {
              setting = { ...setting, videoDevice: { ...setting.videoDevice, id: deviceId } };
            }
            break;
        }
      });

      this.devicesSetting = setting;
      this.updateDeviceSettingToStorage(setting);
    }
  }

  isSettingWebgl(): boolean {
    const { backgroundVideo } = this?.liveStudioSetting || {};
    const { similarity = 0, smoothness = 0, spill = 0 } = backgroundVideo?.optionWebgl || {};
    return !(similarity === 0 && smoothness === 0 && spill === 0);
  }

  setChatType(type: string) {
    this.streamChat.type = type;
    if (type === 'all') {
      this.streamMessages = this.streamChat.messages;
    } else {
      this.streamMessages = this.streamChat.messages.filter(
        (message: any) => message.type === 'all' || message.type === type
      );
    }
  }

  setMessage(message: any, index: number) {
    this.streamChat.messages[index] = message;
  }

  addMessage(message: any) {
    this.streamChat.messages.push({ ...message, uuid: uuidv4() });
    this.setChatType(this.streamChat.type);
  }

  addPrivateMessage(message: ChatMessage) {
    this.privateMessages.push(message);
    if (message.type === 'guest') {
      this.numUnreadMessage = this.numUnreadMessage + 1;
    }
  }

  toggleMessage(message: any, index: number) {
    this.streamChat.messages = this.streamChat.messages.map((item: any) =>
      item.uuid === message.uuid ? message : { ...item, is_display: false }
    );
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      message: message.is_display ? message : undefined,
      chatMessage: undefined,
    };

    this.setChatType(this.streamChat.type);
  }

  setBannerTickerStyle(bannerTickerStyle?: BannerTickerStyle) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      bannerTickerStyle,
    };
  }

  setBackgroundVideo(backgroundVideo?: { uuid: string; source: string; optionWebgl?: GraphicWebgl }) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      backgroundVideo,
    };
  }

  setBanner(bannerInput?: Banner) {
    this.streamChat.messages = this.streamChat.messages.map((item: any) => ({ ...item, is_display: false }));
    this.setChatType(this.streamChat.type);
    const { banner = { static: {} as any, ticker: {} as any } } = this.liveStudioSetting || {};
    if (bannerInput.is_title_only) {
      banner.ticker = bannerInput;
    } else {
      banner.static = bannerInput;
    }
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      banner,
      message: undefined,
    };
  }

  setDisplayChat(displayChat: boolean) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      displayChat,
    };
  }

  setTickerStyle(bannerTickerStyle: BannerTickerStyle) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      bannerTickerStyle: {
        ...this.liveStudioSetting.bannerTickerStyle,
        ...bannerTickerStyle,
      },
    };
  }

  setBrandColor(color: string, name: string) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      brandColor: {
        ...this.liveStudioSetting.brandColor,
        [name]: color,
      },
    };
  }

  setDisplayName(displayName: boolean) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      displayName,
    };
  }

  setThemeStyle(themeStyle: ThemeStyle) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      themeStyle,
    };
  }

  setLayoutStyle(layoutStyle: number) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      layoutStyle,
    };
  }

  setLogo(logo?: { id: string; source: string; opacity?: number }) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      logo,
    };
  }

  setLogoOpacity(opacity = 1) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      logo: {
        ...this.liveStudioSetting.logo,
        opacity,
      },
    };
  }

  setOverlay(overlay?: { id: string; source: string; opacity?: number }) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      overlay,
    };
  }

  setVideoClip(videoClip?: { id: string; source: string; opacity?: number }) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      videoClip,
    };
  }

  setBackground(backgroundImage?: { id: string; source: string; opacity?: number }) {
    this.liveStudioSetting = {
      ...this.liveStudioSetting,
      backgroundImage,
    };
  }

  filterActiveItem(graphicItems: Graphic[], filteredItem: Graphic) {
    return graphicItems.map((item) => {
      return item.uuid === filteredItem.uuid
        ? {
            ...item,
            is_active: !item.is_active,
          }
        : {
            ...item,
            is_active: false,
          };
    });
  }

  toggleGraphicItem(graphicItem: Graphic, studioId: string) {
    let data = {
      studio_uuid: studioId,
      media_uuid: graphicItem.uuid,
      type: graphicItem.type,
    };
    if (graphicItem.is_active) {
      data.media_uuid = null;
    }
    const current = graphicItem.is_active
      ? null
      : {
          id: graphicItem.uuid,
          source: graphicItem.source,
          opacity: this.liveStudioSetting?.logo?.opacity,
        };
    if (graphicItem.type === 1) {
      this.setLogo(current);
      this.logos = this.filterActiveItem(this.logos, graphicItem);
    }
    if (graphicItem.type === 2) {
      this.setOverlay(current);
      this.overlayBackgrounds = this.filterActiveItem(this.overlayBackgrounds, graphicItem);
    }
    if (graphicItem.type === 3) {
      this.setVideoClip(current);
      this.graphicVideos = this.filterActiveItem(this.graphicVideos, graphicItem);
    }
    if (graphicItem.type === 4) {
      this.setBackground(current);
      this.graphicBackgrounds = this.filterActiveItem(this.graphicBackgrounds, graphicItem);
    }

    this.setGraphicsDefault(data).subscribe((res) => {});
  }

  setLiveStudioSettingPreview(val: LiveSetting) {
    this._liveStudioSettingPreview.next(val);
  }

  getLiveStudioSettingPreview(): LiveSetting {
    return this._liveStudioSettingPreview.getValue();
  }

  setLiveStudioSetting(liveStudioSetting: LiveSetting) {
    this.liveStudioSetting = liveStudioSetting;
  }

  get sourceItems(): SourceItem[] {
    return this._sourceItems.getValue();
  }

  set sourceItems(val: SourceItem[]) {
    this._sourceItems.next(val);
  }

  get sourceItemsActive(): SourceItem[] {
    return this._sourceItemsActive.getValue();
  }

  set sourceItemsActive(val: SourceItem[]) {
    this._sourceItemsActive.next(val);
  }

  addSourceItem(item: SourceItem) {
    if (item.type === LiveDemoSourceType.GUEST) {
      item.source.peer.on('stream', (stream: any) => {
        item.stream = stream;
        this.sourceItems = [...this.sourceItems, item];
      });
    } else {
      this.sourceItems = [...this.sourceItems, item];
    }
  }

  removeSourceItem(id: string) {
    this.sourceItems = this.sourceItems.filter((item) => item.id !== id);
    this.sourceItemsActive = this.sourceItemsActive.filter((item) => item.id !== id);
  }

  stopScreenShare() {
    this.sourceItems = this.sourceItems.filter((item) => item.type !== LiveDemoSourceType.SCREEN_SHARE);
  }

  setSourceItemsActive() {
    this.sourceItems.forEach((sourceItem: SourceItem) => {
      const index = this.sourceItemsActive.findIndex((item) => item.id === sourceItem.id);
      if (index >= 0) {
        // check isExist
        if (sourceItem.active) {
          // active to update value source item
          this.sourceItemsActive[index] = sourceItem;
        } else {
          // inactive to remove source item
          this.sourceItemsActive = this.sourceItemsActive.filter((item) => item.id !== sourceItem.id);
        }
      } else {
        // is not exist
        if (sourceItem.active) {
          // active to update value source item
          this.sourceItemsActive = [...this.sourceItemsActive, sourceItem].slice(0, 6);
        }
      }
    });
  }

  toggleCamera(source?: MediaStream) {
    this.eventStreamerService.audioStream = null;
    this.eventStreamerService.videoStream = null;
    if (source) {
      this.eventStreamerService.videoStream = new MediaStream(source.getVideoTracks());
      this.eventStreamerService.audioStream = new MediaStream(source.getAudioTracks());
    }
    this.sourceItems.forEach((item, i) => {
      if (item.type === LiveDemoSourceType.MAIN_CAMERA) {
        this.sourceItems[i] = {
          ...item,
          source: source,
        };
      }
    });
    this.setSourceItemsActive();
  }

  toggleItem(item: SourceItem) {
    this.sourceItems.forEach((sourceItem, i) => {
      if (item.id === sourceItem.id) {
        this.sourceItems[i].active = !item.active;
      }
    });
    this.setSourceItemsActive();
  }

  toggleMaximize(item: SourceItem) {
    this.sourceItems.forEach((i, index) => {
      if (this.sourceItems[index].maximize && i.id !== item.id) {
        this.sourceItems[index].maximize = false;
      }
    });
    this.sourceItems.forEach((i, index) => {
      if (i.id === item.id) {
        this.sourceItems[index].maximize = !this.sourceItems[index].maximize;
      }
    });
    this.setSourceItemsActive();
  }

  changeNameSourceItem(item: SourceItem) {
    this.sourceItems = this.sourceItems.map((i) => ({ ...i, title: item.title }));
    this.setSourceItemsActive();
  }

  setShowChat(value: boolean) {
    this.showChat$.next(value);
  }

  getShowChat() {
    return this.showChat$.asObservable().pipe(distinctUntilChanged());
  }

  setShowBanner(value: boolean) {
    this.showBanner$.next(value);
  }

  getShowBanner() {
    return this.showBanner$.asObservable().pipe(distinctUntilChanged());
  }

  constructor(private apiService: ApiService, private eventStreamerService: CameraStreamerService) {}

  getListStudios(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/studio/list/?channel=${channelId}`);
  }

  saveStudio(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/studio/save-single/`, data);
  }

  saveSetting(studioId: string, reqBody: LiveStudioRq): Observable<any> {
    if (reqBody.settings?.sourceItems?.length) {
      reqBody.settings.sourceItems = reqBody.settings.sourceItems.map((item) => ({ ...item, play: false }));
    }
    if (reqBody.settings?.sourceItemsActive?.length) {
      reqBody.settings.sourceItemsActive = reqBody.settings.sourceItemsActive.map((item) => ({ ...item, play: false }));
    }
    return this.apiService.put(`/api/v1/studio/${studioId}/`, reqBody);
  }

  getListStudioBanners(studioId: string): Observable<any> {
    return this.apiService.get(`/api/v1/studio/${studioId}/banners/`);
  }

  getListStudioMaterials(studioId: string): Observable<any> {
    return this.apiService.get(`/api/v1/studio/${studioId}/materials/`);
  }

  addBanner(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/banner/save-single/`, data);
  }

  updateBanner(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/banner/change-status/`, data);
  }

  updateChatMessage(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/banner/save-single/`, data);
  }

  removeBanner(bannerId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/banner/remove-single/${bannerId}/`);
  }

  uploadGraphic(data: FormData): Observable<any> {
    return this.apiService.post('/api/v1/material/save-single/', data);
  }

  updateGraphicMetaData(data: any): Observable<any> {
    return this.apiService.post('/api/v1/material/save-single/', data);
  }

  removeSingleGraphic(materialId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/material/remove-single/${materialId}`);
  }

  setGraphicsDefault(data: any): Observable<any> {
    return this.apiService.put('/api/v1/material/default/', data);
  }

  doInviteGuest(data: any): Observable<any> {
    return this.apiService.post('/api/v1/participant/invite/', data);
  }

  startBroadcast(data: any): Observable<any> {
    return this.apiService.put('/api/v1/broadcast/start/', data);
  }

  stopBroadcast(data: any): Observable<any> {
    return this.apiService.put('/api/v1/broadcast/stop/', data);
  }

  updateStudioInfo(studioId: string, data: any): Observable<any> {
    return this.apiService.put(`/api/v1/studio/${studioId}/`, data);
  }

  getStudioInfo(studioId: string): Observable<any> {
    return this.apiService.get(`/api/v1/studio/${studioId}/info/`);
  }
}
