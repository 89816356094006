<h1 mat-dialog-title>Build Website</h1>
<div *ngIf="!submitted" mat-dialog-content>
  <div class="info">Are you sure you want to build this website?</div>
</div>
<div *ngIf="!submitted" mat-dialog-actions class="action-btn">
  <button [disabled]="isLoading" mat-flat-button class="mat-small" color="grey" (click)="dialogRef.close(submitted)">
    Cancel
  </button>
  <button [loading]="isLoading" mat-flat-button class="mat-small" color="primary" (click)="handleBuild()">Build</button>
</div>
<div *ngIf="submitted" class="pl-result">
  <div class="info">
    Build process executed. The website can be published successfully via the <b>deployment</b> process.<br /><br />
    <button mat-flat-button color="primary" (click)="dialogRef.close(submitted)">Close</button>
  </div>
</div>
