import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { NotifierService } from 'angular-notifier';
import { TemplateService } from '../../services/template/template.service';
import { Template, TemplateGeneralSettings } from '../../domain/template.domain';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/services';
import { ChannelService } from '@app/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { BuildDialogComponent } from '../build-dialog/build-dialog.component';
import { DeployDialogComponent } from '../deploy-dialog/deploy-dialog.component';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-deployment-settings',
  templateUrl: './deployment-settings.component.html',
  styleUrls: ['./deployment-settings.component.scss'],
})
export class DeploymentSettingsComponent implements OnInit {
  public deploymentForm: FormGroup;
  channelId: string;
  tenantId: string;
  projectId = '';
  selected = 0;
  project: any;
  isLoading = false;
  isLoadingTemplate = false;
  templateId = '';
  template: Template;
  generalSettings: TemplateGeneralSettings;
  pageSettings: any = {};
  settings: any = {};
  configs: any = [];
  disableEdit = false;
  isDefaultSite: boolean;
  siteName: string;
  supportEmail: string;
  isPublished: boolean;
  sslChoice: string;
  @Output() domainChange = new EventEmitter<string>();
  @Input() domain: string;
  public isSubdomain: boolean = true;

  constructor(
    private templateService: TemplateService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private channelService: ChannelService,
    private readonly dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    this.sslChoice = 'existingCertificate';
    this.loadSiteTemplate();
  }

  onInputChange(event: Event, isSubdomain: boolean) {
    if (isSubdomain) {
      this.domain = (event.target as HTMLInputElement).value + '.' + environment.sitePublisherDomain;
    } else {
      this.domain = (event.target as HTMLInputElement).value;
    }
    this.domainChange.emit(this.domain);
  }

  isDomainEmpty() {
    return !this.templateService.getDomain() || this.templateService.getDomain().trim() === '';
  }

  ngOnInit(): void {
    this.deploymentForm = this.formBuilder.group({
      domain: [{ value: '', disabled: this.isSubdomain }, [Validators.required, this.domainValidator()]],
      subDomain: [{ value: '', disabled: !this.isSubdomain }, [Validators.required, this.subDomainValidator()]],
    });
  }

  get f() {
    return this.deploymentForm?.controls;
  }

  domainValidator() {
    return (control: AbstractControl) => {
      const isValid = /^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]+?\.[a-zA-Z]{2,}$/.test(control.value);
      const withDot = control.value.includes('.');
      return isValid && withDot ? null : { invalidDomain: true };
    };
  }

  subDomainValidator() {
    return (control: AbstractControl) => {
      const isValid = /^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]+$/.test(control.value);
      return isValid ? null : { invalidSubdomain: true };
    };
  }

  getDomainInputError() {
    return this.f.domain != null
      ? this.f.domain.hasError('required')
        ? 'Domain is required'
        : this.f.domain.hasError('invalidDomain')
        ? 'Invalid domain format'
        : ''
      : '';
  }

  getSubdomainInputError() {
    return this.f.subDomain != null
      ? this.f.subDomain.hasError('required')
        ? 'Subdomain is required'
        : this.f.subDomain.hasError('invalidSubdomain')
        ? 'Invalid subdomain format'
        : ''
      : '';
  }

  onSlideToggleChange() {
    this.isSubdomain = !this.isSubdomain;
    const controls = this.deploymentForm?.controls;
    if (!this.isSubdomain) {
      controls['domain'].enable();
      controls['subDomain'].disable();
    } else {
      controls['domain'].disable();
      controls['subDomain'].enable();
    }
  }

  getSitePublisherDomain() {
    return environment.sitePublisherDomain;
  }

  loadSiteTemplate() {
    this.templateId = this.route.snapshot.params.templateId;
    this.projectId = this.route.snapshot.params.projectId;
    this.isLoadingTemplate = true;

    combineLatest([
      this.templateService.getTemplate({ uuid: this.projectId }),
      this.channelService.getActiveChannel().pipe(filter((s) => !!s)),
      this.authService.getAccountInfo().pipe(filter((s) => !!s)),
    ]).subscribe(
      (data: any[]) => {
        const [[project], channel, acc] = data;
        this.isPublished = project.is_published;
        this.isDefaultSite = project.settings.isDefault;
        this.siteName = project.name;
        this.supportEmail = project.supportEmail;
        this.domain = project.domain;
        this.templateService.updateDomain(this.domain);
        if (project && project.settings.selectedTemplate) {
          this.project = project;
          this.settings = this.project?.settings;
          this.configs = this.project?.configs;
          this.disableEdit = false;
          this.pageSettings = [...this.configs];
          this.channelId = channel.uuid;
          this.tenantId = acc.tenant_id;
          this.isLoadingTemplate = false;
        } else {
          this.templateService.getTemplate({ uuid: this.templateId }).subscribe(
            (template) => {
              this.project = template && template.length && template[0];
              this.settings = this.project?.settings;
              this.configs = this.project?.configs;
              this.disableEdit = true;
              this.pageSettings = [...this.configs];
              this.channelId = channel.uuid;
              this.tenantId = acc.tenant_id;
              this.isLoadingTemplate = false;
            },
            () => {},
            () => {
              this.isLoadingTemplate = false;
            }
          );
        }
      },
      () => {
        this.isLoadingTemplate = false;
      }
    );
  }

  generalSettingsChange(values: any) {
    this.generalSettings = values;
    return this.generalSettings;
  }

  pageSettingsChange(values: any) {
    if (values.delete != undefined && values.delete) {
      this.pageSettings = values.data;
    } else {
      if (values.action === 'edit') {
        this.pageSettings.forEach((el: any) => {
          if (el.name === values.key) {
            if (!el.meta) {
              el.meta = {} as any;
            }
            el.name = values.name;
            el.meta.title = values.title;
            el.meta.description = values.description;
          }
        });
      } else {
        this.pageSettings.push({
          name: values.name,
          content: {},
          meta: {
            title: values.title,
            description: values.description,
          },
        });
      }
    }
    return this.pageSettings;
  }

  getEditBtnState() {
    if (this.isLoading) {
      return true;
    }
    return this.disableEdit;
  }

  getNameServers() {
    return `ns3.${environment.sitePublisherDomain}, ns4.${environment.sitePublisherDomain}`;
  }

  getTemplateData(onlySave: boolean = false) {
    this.generalSettings = this.generalSettingsChange(this.templateService.getGeneralSettingsUpdate());
    const generalSettings = {
      channelId: this.channelId,
      tenantId: this.tenantId,
      ...this.generalSettings,
    };

    if (onlySave) {
      this.isLoading = true;
    }

    return {
      uuid: this.projectId,
      name: this.siteName,
      supportEmail: this.supportEmail,
      base_url: `${environment.serverUrl}/api/v1`,
      domain: this.domain,
      settings: {
        ...this.settings,
        selectedTemplate: this.templateId,
        generalSettings: {
          videoStyle: this.settings.generalSettings.videoStyle || 'standard',
          showContinueWatching: this.settings.generalSettings.showContinueWatching || true,
          ...generalSettings,
        },
      },
      configs: this.templateService.getPageSettings()
        ? this.pageSettingsChange(this.templateService.getPageSettings())
        : this.pageSettings,
    };
  }

  getPublishData(onlySave: boolean = false) {
    if (onlySave) {
      this.isLoading = true;
    }

    return {
      tenantId: this.tenantId,
      website: {
        name: this.siteName,
        domain: this.domain,
      },
      kubernetes: {
        podReplicas: 1,
        websiteId: this.projectId,
        containerPort: 80,
        servicePort: 8080,
        serviceType: 'LoadBalancer',
      },
    };
  }

  onBuild() {
    if (this.isDomainEmpty()) {
      this.notifier.notify(
        'error',
        'Website URL (domain/subdomain) is required. Please update it in Deployment Settings.'
      );
      return;
    }

    const rq = this.getTemplateData();
    const dialogRef = this.dialog.open(BuildDialogComponent, {
      width: '450px',
      data: rq,
    });
    dialogRef.afterClosed().subscribe((rs: any) => {
      if (!rs) {
        return;
      }
      this.loadSiteTemplate();
    });
  }

  onDeploy() {
    if (this.isDomainEmpty()) {
      this.notifier.notify(
        'error',
        'Website URL (domain/subdomain) is required. Please update it in Deployment Settings.'
      );
      return;
    }

    const rq = this.getPublishData();
    const dialogRef = this.dialog.open(DeployDialogComponent, {
      width: '450px',
      data: rq,
    });
    dialogRef.afterClosed().subscribe((rs: any) => {
      if (!rs) {
        return;
      }
      this.loadSiteTemplate();
    });
  }

  onCertTypeChange(event: MatRadioChange) {
    this.sslChoice = event.value;
  }

  onCertificateRequest() {
    // Initialize Data: Tenant Name, Channel Name, Website Name, Website Domain, Requested Date (date now)

    switch (this.sslChoice) {
      // Set the message for the request regarding the installation of an existing certificate to specific website depending on the request type
      case 'existingCertificate':
        break;
      case 'letsEncrypt':
        break;
    }

    // Send a support request for the SSL certificate installation request (probably email or slack channel?)
    return this.sslChoice;
  }
}
