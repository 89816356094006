import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TutorialType } from '@app/app.constants';
import { ConfirmDialogComponent, TutorialListMatDialogComponent } from '@app/shared/components';
import { Tutorial } from '@app/shared/domain/tutorial.domain';
import { Tutorials } from './tutorial.constants';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  tutorialList: Tutorial[];

  constructor(private router: Router, private readonly dialog: MatDialog) {
    this.tutorialList = Tutorials;
  }

  getAllTutorials() {
    return this.tutorialList;
  }

  getTutorialById(id: number) {
    return this.tutorialList.find((tut) => tut.id === id);
  }

  showTutorial(type: TutorialType) {
    const filteredList = this.tutorialList.filter((tut) => tut.tags && tut.tags.indexOf(type) >= 0);
    if (filteredList.length === 0) {
      return;
    }
    // if (filteredList.length === 1) {
    //   this.router.navigate(['/video-tutorials'], {
    //     queryParams: {
    //       videoId: filteredList[0].id,
    //     },
    //   });
    //   return;
    // }
    const dialogRef = this.dialog.open(TutorialListMatDialogComponent, {
      maxWidth: '750px',
      data: filteredList,
      panelClass: 'responsive-tutorial-dialog',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {});
    return;
  }
}
