import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { StreamAccount } from '@app/shared/domain';
import { StreamAccountService } from '@app/shared/services/stream-account/stream-account.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-account-setting-dialog',
  templateUrl: './account-setting-dialog.component.html',
  styleUrls: ['./account-setting-dialog.component.scss'],
})
export class AccountSettingDialogComponent implements OnInit {
  public accountSettingForm: FormGroup;
  public isLoading: boolean;
  constructor(
    public dialogRef: MatDialogRef<AccountSettingDialogComponent>,
    private streamAccountService: StreamAccountService,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AccountSettingDialogModel
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.accountSettingForm = this.formBuilder.group({
      channelName: ['', [Validators.required, NoWhitespaceValidator()]],
      streamKey: ['', [Validators.required, NoWhitespaceValidator()]],
      serverUrl: ['', [Validators.required, NoWhitespaceValidator()]],
      provider: ['', [Validators.required, NoWhitespaceValidator()]],
    });
  }

  get f() {
    return this.accountSettingForm.controls;
  }

  handleCreate(): void {
    if (this.accountSettingForm.invalid) {
      CommonHelpers.validateAllFormFields(this.accountSettingForm);
      return;
    }
    this.isLoading = true;
    const { channelName, streamKey, serverUrl, provider } = this.accountSettingForm.value;
    const slug = CommonHelpers.createSlug(channelName);
    const newStreamAccount = {
      name: channelName,
      provider,
      stream_key: streamKey,
      server_url: serverUrl,
      slug,
    } as StreamAccount;
    this.streamAccountService.createAccount(newStreamAccount).subscribe(
      (res) => {
        this.notifier.notify('success', `Successfully created account`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
export class AccountSettingDialogModel {}
