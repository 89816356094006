<div class="flex-p">
  <!-- <mat-advanced-audio-player -->
  <!-- <ngx-audio-player
    [playlist]="tracks"
    (trackEnded)="triggerOnEnded($event)"
    [autoPlay]="audio.is_autoplay"
    allow="autoplay"
  ></ngx-audio-player> -->

  <app-videojs-player
    [options]="{
      autoplay: true,
      controls: true,
      controlBar: {
        liveDisplay: true,
        pictureInPictureToggle: false
      },
      sources: [
        {
          src: audioSource,
          type: 'video/mp4'
        }
      ]
    }"
    [videoParameters]="{
      height: 200,
      width: 800
    }"
  >
  </app-videojs-player>
</div>
