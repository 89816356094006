import { Component, Input } from '@angular/core';
import { TutorialType } from '@app/app.constants';
import { TutorialService } from '@app/shared/services';

@Component({
  selector: 'app-tutorial-button',
  templateUrl: './tutorial-button.component.html',
  styleUrls: ['./tutorial-button.component.scss'],
})
export class TutorialButtonComponent {
  @Input() customStyle: string | null;
  @Input() tutorialType: TutorialType;
  @Input() relative: boolean;

  constructor(private tutorialService: TutorialService) {}

  showTutorial() {
    this.tutorialService.showTutorial(this.tutorialType);
  }

  parseStyle(styleString: string): { [key: string]: string } {
    const styles = {};
    const pairs = styleString.split(';').filter((pair) => pair.trim() !== '');
    for (const pair of pairs) {
      const [property, value] = pair.split(':').map((part) => part.trim());
      styles[property] = value;
    }
    return styles;
  }
}
