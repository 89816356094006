import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from '@app/auth/services';
import videojs from 'video.js';

declare var Clappr: any;
declare var DashShakaPlayback: any;

@Component({
  selector: 'app-videojs-player',
  templateUrl: './videojs-player.component.html',
  styleUrls: ['./videojs-player.component.scss'],
})
export class VideojsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() isLivePreview = false;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    showRefresh: boolean;
    startTime?: number;
    sources: {
      src: string;
      type: string;
    }[];
  };
  @Input() videoParameters: {
    height: number;
    width: any;
  } = {
    height: 600,
    width: 800,
  };
  storageInfo: any = {};
  player: videojs.Player;
  constructor(private readonly authService: AuthService) {}

  ngOnInit() {
    this.storageInfo = JSON.parse(this.authService.getStorageInfo());
    this.initPlayer();
  }

  initPlayer() {
    let width: any;
    width = 800;
    let height = 600;
    if (this.isLivePreview) {
      this.videoParameters.width = 'auto';
      this.videoParameters.height = 523;
    }

    let source = this.storageInfo.is_use_scale_engine
      ? this.options.sources[0].src + '/playlist.m3u8'
      : this.options.sources[0].src;

    new Clappr.Player({
      source: source,
      plugins: [DashShakaPlayback],
      height: this.videoParameters.height,
      width: this.videoParameters.width,
      shakaConfiguration: {
        preferredAudioLanguage: 'en-US',
        streaming: {
          rebufferingGoal: 15,
        },
      },
      shakaOnBeforeLoad: function (shaka_player: any) {
        var wvlicenseServer = 'https://wv-keyos.licensekeyserver.com/'; //header
        var playreadylicenseServer = 'https://pr-keyos.licensekeyserver.com/core/rightsmanager.asmx'; //header

        shaka_player.configure({
          drm: {
            servers: { 'com.widevine.alpha': wvlicenseServer, 'com.microsoft.playready': playreadylicenseServer },
          },
        });

        shaka_player.getNetworkingEngine().registerRequestFilter(function (type: any, request: any) {
          // Only add headers to license requests:
          console.log('DRM');
          if (request.licenseRequestType == 'license-request') {
            console.log('DRM Confirmed');
            // This is the specific header name and value the server wants:
            //Classic
            request.headers['customdata'] = 'token';
            //Upfront
            // request.headers['PreAuthorization'] = token
            console.log('Token :', 'token');
          }
        });
      },
      parentId: '#player',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      if (changes.options.previousValue && changes.options.previousValue !== this.options) {
        window.location.reload();
      }
    }
  }

  reset() {}

  getStyle() {
    if (this.isLivePreview) {
      return {
        width: '97%',
      };
    } else {
      return '';
    }
  }

  ngOnDestroy() {}
}
