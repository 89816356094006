import { Component, Inject, OnInit } from '@angular/core';
import { BroadcastStudioService } from '@shared/services';
import { InviteGuestDialogModel } from '@shared/components';
import { NotifierService } from 'angular-notifier';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-email-guest-dialog',
  templateUrl: './email-guest-dialog.component.html',
  styleUrls: ['./email-guest-dialog.component.scss'],
})
export class EmailGuestDialogComponent implements OnInit {
  studioId: string;
  emailGuests: string[] = [];
  email: string;
  isLoading: boolean;

  constructor(
    private notify: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: InviteGuestDialogModel,
    private broadcastStudioService: BroadcastStudioService,
    private dialogRef: MatDialogRef<EmailGuestDialogComponent>
  ) {
    this.studioId = data.studioId;
  }

  ngOnInit(): void {}

  handleInviteGuest() {
    this.isLoading = true;
    const data = {
      emails: this.emailGuests,
      invite_url: `${window.location.origin}/guest/broadcasts-studio`,
      studio_uuid: this.studioId,
    };
    this.broadcastStudioService.doInviteGuest(data).subscribe(
      () => {
        this.isLoading = false;
        this.notify.notify('success', `send email successfully`);
        this.dialogRef.close();
      },
      () => {
        this.isLoading = false;
        this.notify.notify('error', `cannot send email `);
      }
    );
  }

  removeEmail(email: string) {
    this.emailGuests = this.emailGuests.filter((e) => e !== email);
  }

  addEmail() {
    this.emailGuests.push(this.email);
    this.email = '';
  }
}
