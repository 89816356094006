import { Injectable } from '@angular/core';
import { MonitorConfig, ResponseNetworkConnection } from '@shared';
import * as events from 'events';
import { Utils } from '@shared/services/pingMonitor/utils';
import ReconnectingWebSocket from 'reconnecting-websocket';

@Injectable({
  providedIn: 'root',
})
export class PingMonitorService extends events.EventEmitter {
  monitorConfig: MonitorConfig;
  handle: any;
  ws: ReconnectingWebSocket;
  result: { roundTripTimes: number[] } = { roundTripTimes: [] };
  responseNetworkConnection: ResponseNetworkConnection = {
    avg: 0,
    lastTime: 0,
    percent: 0,
    alive: false,
  };

  constructor() {
    super();
  }

  public start(monitorConfig: MonitorConfig) {
    this.mergeConfig(monitorConfig);
    const self = this;
    const { interval, intervalUnits } = this.monitorConfig.config;
    const INTERVAL = Utils.intervalUnits(interval, intervalUnits);
    this.init();
    window.addEventListener('offline', function (e) {
      self.emit('down', self.responseNetworkConnection);
    });
    this.handle = setInterval(async () => {
      for (let i = 0; i < this.monitorConfig.config.packetsNumber; i++) {
        await self.ping();
      }
    }, INTERVAL);
  }

  async init() {
    const { url } = this.monitorConfig;
    const self = this;
    try {
      const options = {
        connectionTimeout: 5000,
        maxRetries: 20,
      };
      this.ws = new ReconnectingWebSocket(url, [], options);
    } catch (e) {
      self.emit('down', self.responseNetworkConnection);
    }
    // Connection opened
    this.ws.addEventListener('open', (event: any) => {
      console.log('Connected');
      self.emit('up', {
        percent: 1,
        alive: true,
      });
    });

    this.ws.addEventListener('message', (event: any) => {
      // console.log('message received: ' + event.data);
      const mgs = JSON.parse(event.data);
      if (mgs.type !== 'heartbeat') return;
      const roundTripTime = Utils.getNow() - mgs.date;
      self.result.roundTripTimes.push(roundTripTime);
      self.responseNetworkConnection.lastTime = roundTripTime;
      if (self.result.roundTripTimes.length === self.monitorConfig.config.packetsNumber) {
        const average = (arr: any) =>
          arr.reduce((a: any, b: any) => a + b, 0) / self.monitorConfig.config.packetsNumber;
        self.responseNetworkConnection.avg = Number(Math.round(average(self.result.roundTripTimes)));
        self.responseNetworkConnection.alive = true;
        self.result.roundTripTimes = [];
        // console.log(`RoundTripTime =  ${roundTripTime.toFixed(1)} ms`);
        // console.log(`Average =  ${self.responseNetworkConnection.avg} ms`);
        if (roundTripTime > Utils.MAX_ROUND_TRIP_TIME) {
          self.responseNetworkConnection.percent = 1 - (roundTripTime + Utils.MAX_ROUND_TRIP_TIME) / 1000;
          self.emit('lower', self.responseNetworkConnection);
          return;
        }
        self.responseNetworkConnection.percent = 1 - roundTripTime / 1000;
        self.handleResponse(self.responseNetworkConnection);
      }
    });

    this.ws.addEventListener('close', (event: any) => {
      self.emit('down', self.responseNetworkConnection);
    });

    this.ws.addEventListener('error', (event: any) => {
      console.log('error');
      self.emit('down', self.responseNetworkConnection);
    });

    this.ws.onerror = (e: any) => {
      console.log(e);
    };
  }

  async ping() {
    return this.ws.send(JSON.stringify({ type: 'heartbeat', date: Utils.getNow() }));
  }

  public handleResponse(pingResponse: any) {
    if (!pingResponse.alive) {
      this.emit('down', pingResponse);
    } else {
      this.emit('up', pingResponse);
    }
  }

  public stop() {
    this.clearInterval();
  }

  public clearInterval() {
    clearInterval(this.handle);
    this.handle = null;
  }

  private mergeConfig(monitorConfig: MonitorConfig) {
    const { url, config } = monitorConfig;
    const { packetsNumber, interval, intervalUnits } = config || {};
    this.monitorConfig = {
      url,
      config: { packetsNumber, interval, intervalUnits },
    };
  }
}
