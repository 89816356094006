<h1 mat-dialog-title>Report Bugs or Request New Features</h1>
<div mat-dialog-content>
  <form [formGroup]="reportForm">
    <div class="report-fields" fxLayout="column">
      <mat-label class="text-weight-bold">Name:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="name" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold">Email:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="email" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold">Subject:</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" formControlName="subject" matInput />
      </mat-form-field>
      <mat-label class="text-weight-bold"> Bug Description or Feature Request Details:</mat-label>
      <mat-form-field appearance="outline">
        <textarea matInput placeholder="" rows="6" formControlName="description"></textarea>
      </mat-form-field>
      <div fxFlex="60">
        <button mat-flat-button color="primary" (click)="fileInput.click()" type="button" class="button-200">
          Attach Images/Screenshots
        </button>
        <input #fileInput type="file" accept="image/*" hidden (change)="handleScreenShots($event.target.files)" />
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-flat-button class="mat-small" color="primary" (click)="handleSend()">Send</button>
</div>
