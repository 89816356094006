import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { configConstans } from '@app/core';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { Category } from '@app/shared/domain';
import { AudioCategoryService, VideoCategoryService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { map } from 'lodash';

type CreateType = 'AUDIO' | 'VIDEO';

@Component({
  selector: 'app-create-category-dialog',
  templateUrl: './create-category-dialog.component.html',
  styleUrls: ['./create-category-dialog.component.scss'],
})
export class CreateCategoryDialogComponent implements OnInit {
  public categoryForm: FormGroup;
  public isLoading: boolean;
  public configConstans = configConstans;
  public type: CreateType;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateCategoryDialogComponent>,
    private videoCategoryService: VideoCategoryService,
    private audioCategoryService: AudioCategoryService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: CreateCategoryDialogModel
  ) {
    this.type = data.type;
  }

  ngOnInit() {
    this.buildForm();
  }

  get f() {
    return this.categoryForm.controls;
  }

  buildForm() {
    this.categoryForm = this.formBuilder.group({
      title: ['', [Validators.required, NoWhitespaceValidator()]],
      description: [''],
      status: [configConstans.categoryStatuses.VISIBLE.value, Validators.required],
    });
  }

  handleCreate(): void {
    if (this.categoryForm.invalid) {
      CommonHelpers.validateAllFormFields(this.categoryForm);
      return;
    }
    this.isLoading = true;
    const { title, description, status } = this.categoryForm.value;
    const slug = CommonHelpers.createSlug(title);
    const fmData = new FormData();
    const newCategory = {
      title,
      description,
      status,
      slug,
      active: true,
    } as Category;
    map(newCategory, (value: string, key) => {
      fmData.append(key, value);
    });
    const observable =
      this.type === 'AUDIO'
        ? this.audioCategoryService.createCategory(fmData)
        : this.videoCategoryService.createCategory(fmData);
    observable.subscribe(
      (res) => {
        this.notifier.notify('success', `Successfully created ${title} category`);
        this.dialogRef.close(res);
      },
      (error) => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}

export class CreateCategoryDialogModel {
  constructor(public type: CreateType = 'VIDEO') {}
}
