<div>
  <h2>Add New Folder</h2>
  <div *ngIf="mode == 'new'" class="card">
    <form [formGroup]="folderForm">
      <mat-form-field appearance="outline" style="width: 100%">
        <input matInput formControlName="title" placeholder="Title" />
        <mat-error *ngIf="folderForm.get('title').hasError('required')"> Title is required </mat-error>
        <mat-error *ngIf="folderForm.get('title').hasError('maxlength')"> Title cannot exceed 40 characters </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%">
        <textarea
          matInput
          formControlName="description"
          placeholder="Description"
          style="height: 100px; resize: none"
        ></textarea>
        <mat-error *ngIf="folderForm.get('description').hasError('required')"> Description is required </mat-error>
        <mat-error *ngIf="folderForm.get('description').hasError('maxlength')">
          Description cannot exceed 100 characters
        </mat-error>
      </mat-form-field>
      <div class="note">Total {{ totalVideos }} items will be added to this folder</div>
      <br />
      <div class="form-actions">
        <button
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="folderForm.invalid"
          (click)="handleAddFolder()"
        >
          <mat-icon>add</mat-icon>Add Folder
        </button>
      </div>
    </form>
  </div>
</div>
