<div mat-dialog-content>
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div fxLayout="column" fxLayoutGap="16px" style="text-align: center">
    <div class="text-weight-bold" [style.font-size]="'18px'">Invite guests to your Live Broadcast</div>
    <div>Send the link bellow to your guests. You can up to 6 people on screen at one</div>

    <div fxLayout="row" *ngIf="!!emailGuests.length">
      <div [style.margin-right.px]="10">
        <mat-icon> email</mat-icon>
      </div>
      <div fxFlex>
        <mat-chip-list aria-label="Fruit selection">
          <mat-chip *ngFor="let email of emailGuests" [removable]="true" (removed)="removeEmail(email)">
            {{ email }}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="w-100">
      <mat-label>Email</mat-label>
      <mat-form-field [style.width]="'80%'" appearance="outline">
        <input
          id="name"
          name="name"
          type="text"
          matInput
          placeholder="please input a email"
          email
          [(ngModel)]="email"
          #name="ngModel"
          (keyup.enter)="email && !name.invalid && emailGuests?.length < 6 && addEmail()"
        />
      </mat-form-field>
      <button
        mat-raised-button
        (click)="addEmail()"
        color="primary"
        [disabled]="!email || name.invalid || !name || emailGuests?.length >= 6"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>
<div *ngIf="!!emailGuests.length" mat-dialog-actions fxLayout="column" fxLayoutGap="16px" style="text-align: center">
  <div fxLayout="row" class="w-100">
    <button fxFlex color="primary" mat-flat-button (click)="handleInviteGuest()">
      INVITE {{ emailGuests.length }} GUESTS
    </button>
  </div>
</div>
