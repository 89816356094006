import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services';
import { UnpaidInvoiceDialogComponent } from '../unpaid-invoice-dialog';

@Component({
  selector: 'app-card-checker-dialog',
  templateUrl: './card-checker-dialog.component.html',
  styleUrls: ['./card-checker-dialog.component.scss'],
})
export class CardCheckerDialogComponent implements OnInit {
  @ViewChild('paymentFrame') paymentFrame: ElementRef;
  public hostedPageUrl: SafeResourceUrl;
  public from_invoice_checker: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    public dialogRef: MatDialogRef<CardCheckerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.from_invoice_checker = data.from_invoice_checker;
  }

  ngOnInit(): void {
    let url = localStorage.getItem('hosted_page_update_card');
    if (url && url != 'null') {
      this.hostedPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  reloadForm() {
    localStorage.removeItem('hasActiveCard');
    localStorage.removeItem('hosted_page_update_card');
    localStorage.removeItem('lastShowZohoPageTimestamp');
    localStorage.removeItem('lastCheckedTimestamp');
    window.location.reload();
  }

  logOut() {
    this.authService.logout();
    this.dialogRef.close(true);
  }

  backToInvoice() {
    this.dialogRef.close(true);
    this.openUnpaidInvoiceDialog();
  }

  openUnpaidInvoiceDialog() {
    this.dialogRef.close(true);
    const dialogRef = this.dialog.open(UnpaidInvoiceDialogComponent, {
      width: '800px',
      height: '700px',
      data: {},
      disableClose: true,
      panelClass: 'app-full-bleed-dialog',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {});
  }
}
