import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from 'angular-notifier';
import {
  Video,
  VideoService,
  AudioService,
  ConfirmDialogModel,
  ConfirmDialogComponent,
  ConfirmRestoreDialogModel,
  ConfirmRestoreDialogComponent,
} from '@app/shared';
import { catchError } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

@Component({
  selector: 'app-video-trash',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() reloadTrash: boolean;
  @Input() searchParams: string;
  @Input() libraryType: string;
  @Input() parentLoading: boolean;
  @Output() selectionChange = new EventEmitter<SelectionModel<Video>>();
  public dataSource: MatTableDataSource<Video> = new MatTableDataSource([]);
  public selection = new SelectionModel<Video>(true, []);
  public combinedResults: Video[] = [];
  public isLoading: boolean;
  public params = {
    page: 1,
    pageSize: 20,
    total: 0,
    totalPage: 0,
    search: '',
    ordering: '-updated',
    pageSizeOptions: [20, 50, 100, 150, 200],
    jobstatus: 'DELETED',
  };
  public displayedColumns: string[] = [
    'select',
    'type',
    'thumbnail',
    'title',
    'description',
    'duration',
    'created',
    'actions',
  ];

  constructor(
    private videoService: VideoService,
    private audioService: AudioService,
    private notifier: NotifierService,
    private dialog: MatDialog
  ) {
    this.selection.changed.subscribe(() => {
      // Emit the updated selection to the parent component
      this.selectionChange.emit(this.selection);
    });
  }

  ngOnInit(): void {
    if (this.libraryType === 'Video') this.getVideos();
    else this.getAudios();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.params.search = this.searchParams;

    if (changes.parentLoading) this.isLoading = this.parentLoading;

    if (changes.reloadTrash) this.getMedia();
  }

  ngOnDestroy() {
    this.dataSource = new MatTableDataSource([]);
  }

  getVideos() {
    this.isLoading = true;
    const { page, pageSize, search, ordering, jobstatus } = this.params;
    this.videoService
      .getVideos({
        page,
        page_size: pageSize,
        search,
        ordering,
        jobstatus,
      })
      .subscribe(
        (res) => {
          const { results, total } = res;
          this.params.total = total;
          // Add type
          results.forEach((item: any) => {
            item.type = 'Video';
          });
          this.dataSource = new MatTableDataSource(results);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  getAudios() {
    this.isLoading = true;
    const { page, pageSize, search, ordering, jobstatus } = this.params;
    this.audioService
      .getAudios({
        page,
        page_size: pageSize,
        search,
        ordering,
        jobstatus,
      })
      .subscribe(
        (res) => {
          const { results, total } = res;
          this.params.total = total;
          // Add type
          results.forEach((item: any) => {
            item.type = 'Audio';
          });

          this.dataSource = new MatTableDataSource(results);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  handleSortChange(event: any) {
    const { active, direction } = event;
    if (direction === 'asc') {
      this.params.ordering = active;
    } else {
      this.params.ordering = `-${active}`;
    }
    this.getMedia();
  }

  pageChanged(event: any) {
    this.selection.clear();
    this.params.pageSize = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getMedia();
  }

  getThumbnail(row: any) {
    if (row.thumbnail) {
      let f_thumbnail = row.thumbnail;
      // if (row.video_type == 'RECORDED' && f_thumbnail.includes('/' + environment.recordingSEServerOld + '/')) {
      //   f_thumbnail = environment.recordingUrl + f_thumbnail.split('/' + environment.recordingSEServerOld + '/')[1];
      // }
      return f_thumbnail;
    } else {
      return 'assets/images/no-image.png';
    }
  }

  handleDelete(video: Video) {
    const dialogData = new ConfirmDialogModel(
      `Are you sure you want to delete this ${this.libraryType.toLowerCase()}?`,
      ''
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '470px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const { uuid: videoId, type: type } = video;
        this.isLoading = true;
        if (type === 'Video') this.handleDeleteVideo(videoId);
        else this.handleDeleteAudio(videoId);
      }
    });
  }

  handleDeleteVideo(videoId: string) {
    this.videoService.deleteVideo(videoId).subscribe(
      () => {
        this.notifier.notify('success', 'Video successfully deleted');
        this.isLoading = false;
        this.params.page = 1;
        this.getVideos();
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      }
    );
  }

  handleDeleteAudio(videoId: string) {
    this.audioService.deleteAudio(videoId).subscribe(
      () => {
        this.notifier.notify('success', 'Audio successfully deleted');
        this.isLoading = false;
        this.params.page = 1;
        this.getAudios();
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      }
    );
  }

  handleRestore(video: Video) {
    const dialogData = new ConfirmRestoreDialogModel(
      `Are you sure you want to restore this ${this.libraryType.toLowerCase()}?`,
      ''
    );
    const dialogRef = this.dialog.open(ConfirmRestoreDialogComponent, {
      width: '470px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        const { uuid: videoId, type: type } = video;
        this.isLoading = true;
        if (type === 'Video') this.restoreVideo(video, videoId);
        else this.restoreAudio(video, videoId);
      }
    });
  }

  restoreVideo(video: Video, assetId: string) {
    this.videoService.updateVideoPartial({ job_status: video.prev_job_status }, assetId).subscribe(
      () => {
        this.notifier.notify('success', 'Video successfully restored');
        this.isLoading = false;
        this.params.page = 1;
        this.getVideos();
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      }
    );
  }

  restoreAudio(video: Video, assetId: string) {
    this.audioService.updateAudioPartial({ job_status: video.prev_job_status }, assetId).subscribe(
      () => {
        this.notifier.notify('success', 'Audio successfully restored');
        this.isLoading = false;
        this.params.page = 1;
        this.getAudios();
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      }
    );
  }

  getMedia() {
    if (this.libraryType === 'Video') this.getVideos();
    else this.getAudios();
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
}
