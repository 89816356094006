import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Channel } from '@app/shared/domain';
import { ChannelService } from '@app/shared/services';
import { AdditionalChannelRequestDialogComponent } from '../additional-channel-request-dialog';
import { CreateChannelDialogComponent } from '../create-channel-dialog';

@Component({
  selector: 'app-active-channel',
  templateUrl: './active-channel.component.html',
  styleUrls: ['./active-channel.component.scss'],
})
export class ActiveChannelComponent implements OnInit {
  @Input('buttonClass') buttonClass = 'mat-flat-button';
  @Input('color') color = '';
  public channelList$ = this.channelService.getChannelList();
  public activeChannel$ = this.channelService.getActiveChannel();
  public channelLoading$ = this.channelService.getDropDownChannelLoading();

  constructor(private channelService: ChannelService, private dialog: MatDialog) {}

  ngOnInit() {}

  public changeActiveChannel(channel: Channel) {
    this.channelService.changeActiveChannel(channel);
  }

  handleCreateChannel() {
    const dialogRef = this.dialog.open(AdditionalChannelRequestDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
      }
    });
  }
}
