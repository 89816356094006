import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '@app/auth/services';
import { ChannelService, ZohoService } from '@app/shared/services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CardCheckerDialogComponent } from '../card-checker-dialog';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-unpaid-invoice-dialog',
  templateUrl: './unpaid-invoice-dialog.component.html',
  styleUrls: ['./unpaid-invoice-dialog.component.scss'],
})
export class UnpaidInvoiceDialogComponent implements OnInit {
  channels: any[] = [];
  loading: boolean = false;
  tenantId: string = '';
  firstName: string = '';
  public hostedPageUrl: SafeResourceUrl;
  unpaid_invoices_data: any[] = [];
  has_active_card: boolean = false;
  displayedColumns: string[] = ['name', 'quantity', 'price'];
  subTotal: number = 0.0;
  totalPrice: number = 0.0;
  totalDiscount: number = 0.0;
  dataSource: any = [];
  noCardError: boolean = false;
  paymentLoading: boolean = false;
  cardLoading: boolean = false;
  hasError: boolean = false;

  constructor(
    private channelService: ChannelService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<UnpaidInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zohoService: ZohoService,
    private dialog: MatDialog,
    private notifier: NotifierService
  ) {
    try {
      this.unpaid_invoices_data = JSON.parse(localStorage.getItem('xx_inv_data'));
    } catch (error) {}

    this.has_active_card = localStorage.getItem('hasActiveCard') == 'true';
    if (Array.isArray(this.unpaid_invoices_data)) {
      this.unpaid_invoices_data.forEach((unpaid_invoice: any) => {
        if (unpaid_invoice.invoice_items) {
          unpaid_invoice.invoice_items.forEach((item: any) => {
            let unpaid_item = {
              name: item.name,
              description: item.description,
              quantity: item.quantity,
              total_price: item.price * item.quantity,
            };
            this.totalDiscount += item.discount_amount;
            this.dataSource.push(unpaid_item);
          });
          this.totalPrice += unpaid_invoice.total;
        }
      });
      this.subTotal = this.totalPrice + this.totalDiscount;
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.getAccountInfo().subscribe((account: any) => {
      this.tenantId = account.tenant_id;
      this.firstName = account.first_name;
      if (this.firstName) {
        this.firstName = this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1);
      }
    });

    this.channelService.getAllChannelsByTenantId(this.tenantId).subscribe((res: any) => {
      this.channels = res.channels;
      let url = localStorage.getItem('hosted_page_for_unpaid_invoice');
      if (url && url != 'null') {
        this.hostedPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
      this.loading = false;
    });
  }

  reloadForm() {
    localStorage.removeItem('hosted_page_for_unpaid_invoice');
    localStorage.removeItem('lastShowZohoPageInvoiceTimestamp');
    localStorage.removeItem('lastCheckedUnpaidInvoiceTimestamp');
    window.location.reload();
  }

  addNewCardDetails(): void {
    this.cardLoading = true;
    this.zohoService.checkActiveCards(true).subscribe((res) => {
      if (res) {
        this.cardLoading = false;
        this.dialogRef.close(true);
        localStorage.setItem('hosted_page_update_card', res.hosted_page_url);
        this.openCardUpdateDialog();
      }
    });
  }

  openCardUpdateDialog() {
    const dialogRef = this.dialog.open(CardCheckerDialogComponent, {
      width: '700px',
      height: '680px',
      data: {
        from_invoice_checker: true,
      },
      disableClose: true,
      panelClass: 'app-full-bleed-dialog',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {});
  }

  onSubmit() {
    this.paymentLoading = true;
    this.zohoService.makePaymentForUnpaidInvoices(this.tenantId).subscribe(
      (res) => {
        if (res) {
          if (res.message == 'The customer payment has been recorded.') {
            this.paymentLoading = false;
            window.location.href = `/auth/process-hibernated-account-page?tenantId=${this.tenantId}`;
          }
        }
      },
      (error: any) => {
        if (error == 'No active card available. Please add new card details.') {
          this.noCardError = true;
        } else {
          this.hasError = true;
        }
        this.notifier.notify('error', error);
        this.paymentLoading = false;
      }
    );
  }

  scrollDown() {
    const modalContent = document.querySelector('.mat-dialog-container');
    if (modalContent) {
      modalContent.scrollTo({
        top: modalContent.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  logOut() {
    this.authService.logout();
    this.dialogRef.close(true);
  }
}
