import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Channel, ChannelDefault, Package } from '@app/shared/domain';
import { ChannelService, PackageService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { CommonHelpers } from '@app/helpers/common';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-adding-additional-channel-dialog',
  templateUrl: './adding-additional-channel-dialog.component.html',
  styleUrls: ['./adding-additional-channel-dialog.component.scss'],
})
export class AddingAdditionalChannelDialogComponent implements OnInit {
  get f() {
    return this.channelForm?.controls;
  }
  public activeChannel$ = this.channelService.getActiveChannel();
  public channelForm: FormGroup;
  public isLoading: boolean;
  public isLoadingDefault: boolean;
  public disableRemoveButton: boolean = true;
  public channelDefault: ChannelDefault;
  public channelPackages: Package[];
  public numberOfChannels: Number = 1;
  public channelPrice: String = '49';

  protected _onDestroy = new Subject<void>();
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddingAdditionalChannelDialogComponent>,
    private notifier: NotifierService,
    private channelService: ChannelService,
    private packageService: PackageService,
    @Inject(MAT_DIALOG_DATA) public data: AddingAdditionalChannelDialogModel
  ) {}

  ngOnInit() {
    this.isLoadingDefault = true;
    forkJoin([this.channelService.getChannelDefault(), this.packageService.getAllPackages()]).subscribe(
      ([channelDefault, channelPackages]) => {
        this.channelDefault = channelDefault;
        this.channelPackages = channelPackages;
        this.buildForm(channelDefault);
        this.isLoadingDefault = false;
      },
      () => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoadingDefault = false;
      }
    );
  }

  buildForm(channelDefault: ChannelDefault) {
    this.channelForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50), NoWhitespaceValidator()]],
      email: ['', [Validators.required, Validators.email, NoWhitespaceValidator()]],
      // plan: ['', [Validators.required]],
      quantity: ['1', [Validators.required, Validators.min(1), NoWhitespaceValidator()]],
      total_price: [this.calculatePrice(1), [Validators.required]],
    });
  }

  calculatePrice(quantity: Number) {
    let total_price = Number(quantity) * Number(this.channelPrice);
    // console.log(formatCurrency(total_price, 'en', '$'));
    return formatCurrency(total_price, 'en', '$');
  }

  handleChannelCount(eventType: string) {
    // console.log(eventType);
    let numberOfItems = Number(this.channelForm.get('quantity').value); // setValue(100);
    if (eventType === 'remove' && numberOfItems > 1) {
      numberOfItems = numberOfItems - 1;
      this.channelForm.get('quantity').setValue(numberOfItems);
      this.channelForm.get('total_price').setValue(this.calculatePrice(numberOfItems));

      if (numberOfItems == 1) {
        this.disableRemoveButton = true;
      }
    } else if (eventType === 'add') {
      numberOfItems = numberOfItems + 1;
      this.channelForm.get('quantity').setValue(numberOfItems);
      this.channelForm.get('total_price').setValue(this.calculatePrice(numberOfItems));
      if (numberOfItems > 1) {
        this.disableRemoveButton = false;
      }
    }
  }

  handleCreate(): void {
    if (this.channelForm.invalid) {
      CommonHelpers.validateAllFormFields(this.channelForm);
      return;
    }
    this.isLoading = true;
    const { name, email, quantity, total_price } = this.channelForm.value;

    const data = new FormData();
    data.append('name', name);
    data.append('email', email);
    data.append('quantity', quantity);
    data.append('total_price', total_price);

    // console.log(data);

    // this.notifier.notify('success', `Request Submitted Successfully`);
    // this.dialogRef.close(true);
    // this.isLoading = false;

    this.channelService.additionalChannelRequest(data).subscribe(
      (res) => {
        this.notifier.notify('success', `Request Submitted Successfully`);
        this.dialogRef.close(true);
      },
      (error) => {
        this.notifier.notify('error', error);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

export class AddingAdditionalChannelDialogModel {
  constructor() {}
}
