import { Menu } from './menu.model';

export const verticalMenuItems = [
  new Menu(1, 'Dashboard', '/dashboard', null, 'dashboard', null, false, 0),
  new Menu(41, 'Video Tutorials', '/video-tutorials', null, 'help', null, false, 0),
  // new Menu(28, 'My TV Channel', '/my-tv-channel', null, 'tv', null, false, 0),
  new Menu(2, 'Video Library', '/video-library', null, 'video_library', null, false, 0),
  new Menu(2, 'Audio Library', '/audio-library', null, 'audiotrack', null, false, 0),
  new Menu(5, 'Channels', '/channels', null, 'live_tv', null, false, 0),
  new Menu(7, 'Video Playlists', '/playlist', null, 'theaters', null, true, 0),
  new Menu(9, 'Manage Video Playlists', '/video-playlists/manage-playlists', null, 'theaters', null, false, 7),
  new Menu(10, 'Video Playlist Queue', '/video-playlists/playlist-queue', null, 'queue_play_next', null, false, 7),
  // new Menu(11, 'Video Playlist Checkup', '/video-playlists/playlist-checkup', null, 'view_list', null, false, 7),
  // new Menu(44, 'Audio Playlists', '/playlist', null, 'queue_music', null, true, 0),
  new Menu(45, 'Manage Audio Playlists', '/audio-playlists/manage-playlists', null, 'queue_music', null, false, 44),
  new Menu(46, 'Audio Playlist Queue', '/audio-playlists/playlist-queue', null, 'queue_play_next', null, false, 44),
  // new Menu(47, 'Audio Playlist Checkup', '/audio-playlists/playlist-checkup', null, 'view_list', null, false, 44),
  // new Menu(57, 'Video Editor', '/video-editor', null, 'ondemand_video', null, false, 0),
  new Menu(12, 'Video On Demand', '/video-on-demand', null, 'ondemand_video', null, false, 0),
  new Menu(19, 'Audio On Demand', '/audio-on-demand', null, 'library_music', null, false, 0),
  new Menu(15, 'Broadcast Live', '/video-library', null, 'videocam', null, true, 0),
  new Menu(16, 'Start Broadcast', '/start-broadcast', null, 'play_circle_outline', null, false, 15),
  new Menu(17, 'Live Recorder', '/live-recorder', null, 'radio_button_checked', null, false, 15),
  new Menu(18, 'Restream to Social', '/restreamer', null, 'facebook', null, false, 15),
  // new Menu(44, 'Broadcast Studio', '/broadcast-studio', null, 'play_circle_outline', null, false, 0),
  new Menu(45, 'Subscription', '/subscription', null, 'monetization_on', null, false, 0),
  new Menu(50, 'PPV Events', '/ppv-events', null, 'event', null, false, 0),
  new Menu(30, 'Analytics', '/analytics', null, 'bar_chart', null, false, 0),
  // new Menu(21, 'Toolbox', '/video-library', null, 'build', null, true, 0),
  // new Menu(22, 'Preview Stream', '/video-library/1', null, 'search', null, false, 21),
  new Menu(23, 'Push Notifications', '/video-library/1', null, 'smartphone', null, false, 21),
  new Menu(24, 'Embed Codes', '/video-library/1', null, 'code', null, false, 21),
  new Menu(11, 'Video Playlist Checkup', '/video-playlists/playlist-checkup', null, 'view_list', null, false, 21),
  new Menu(47, 'Audio Playlist Checkup', '/audio-playlists/playlist-checkup', null, 'view_list', null, false, 21),
  // new Menu(25, 'Playlist Checkup', '/video-library/1', null, 'list', null, false, 21),
  new Menu(26, 'Scheduling History', '/video-library/1', null, 'history', null, false, 21),
  new Menu(27, 'Event Log', '/video-library/1', null, 'event', null, false, 21),
  // new Menu(28, 'Distribution Platforms', '/video-library', null, 'devices_other', null, true, 0),
  new Menu(29, 'Supported Platforms', '/video-library/1', null, 'important_devices', null, false, 28),
  // new Menu(30, 'Analytics', '/video-library', null, 'pie_chart', null, true, 0),

  // new Menu(31, 'Video Report', '/video-library/1', null, 'play_circle_filled', null, false, 30),
  // new Menu(32, 'Meta Download Report', '/video-library/1', null, 'cloud_download', null, false, 30),
  // new Menu(33, 'Audio Report', '/video-library/1', null, 'library_music', null, false, 30),
  // new Menu(34, 'Live Stream Report', '/video-library/1', null, 'videocam', null, false, 30),
  // new Menu(35, 'Channel Report', '/video-library/1', null, 'tv', null, false, 30),
  // new Menu(36, 'VOD Diagnostics Report', '/video-library/1', null, 'ondemand_video', null, false, 30),
  // new Menu(38, 'Setup', '/video-library', null, 'settings', null, true, 0),
  // new Menu(39, 'Channel Packages', '/video-library/1', null, 'subscriptions', null, false, 38),
  // new Menu(40, 'Channel Configuration', '/video-library/1', null, 'video_settings', null, false, 38),
  // new Menu(41, 'Help', '/video-tutorials', null, 'help', null, false, 0),
  // new Menu(42, 'Resources', '/video-library/1', null, 'file_copy', null, false, 41),
  // new Menu(43, 'Video Tutorials', '/video-library/1', null, 'cast_for_education', null, false, 41),
  // new Menu(44, 'Channel Guide', '/video-library/1', null, 'tv', null, false, 41),
  // new Menu(41, 'Developer Resources', '/video-library', null, 'share', null, true, 0),
  // new Menu(42, 'Developer API', '/video-library/1', null, 'integration_instructions', null, false, 41),
  // new Menu(43, 'Developer API Docs', '/video-library/1', null, 'file_copy', null, false, 41),
  new Menu(51, 'Settings', '/app-setting', null, 'settings', null, false, 0),
  new Menu(52, 'Websites', '/websites', null, 'store', null, false, 53),
  new Menu(53, 'Website Builder', '/form-builder', null, 'view_compact', null, true, 0),
  new Menu(54, 'Template Gallery', '/templates', null, 'video_library', null, false, 53),
  new Menu(55, 'Form Builder', '/form-builder', null, 'view_compact', null, false, 53),
  // new Menu(56, 'Channel Builder', '/channel-builder', null, '', null, false, 0),
];
