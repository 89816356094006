<div id="stream-play-wrapper">
  <div id="video-overlay">
    <img src="assets/images/loading.gif" alt="loading image" />
  </div>

  <div #placeHolder id="video_info">Stream will start playing automatically<br />when it is live</div>

  <!-- HLS Player -->
  <div #videoContainer id="video_container">
    <video id="video-player" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto">
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
      </p>
    </video>
  </div>

  <!-- WebRTC Player -->

  <video #remoteVideo id="remoteVideo" controls playsinline></video>

  <!-- 360 player is added dynamically -->

  <div id="networkWarning">Your connection isn't fast enough to play this stream!</div>
  <img
    id="play_button"
    src="assets/images/play.png"
    (click)="playWebRTCVideo()"
    style="position: absolute; top: 30px; left: 30px; display: none"
  />

  <!-- Mute/Unmute Video Button -->
  <button #unMuteButton (click)="changeVideoMuteStatus()" id="unmuteButton" title="Mute/Unmute Video">Unmute</button>
</div>
