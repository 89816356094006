import { FormGroup, ValidationErrors } from '@angular/forms';

// custom validator to check that two fields match
export function ValidatorFrom(fromControlName: string, toControlName: string, min?: number, max?: number) {
  return (formGroup: FormGroup) => {
    const fromControl = formGroup.controls[fromControlName];
    const toControl = formGroup.controls[toControlName];
    if (fromControl.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    const errors: ValidationErrors = {};
    if (!toControl.value || toControl.value === 'None') {
      errors['required'] = true;
    }

    if (min && toControl.value < min) {
      errors['min'] = min;
    }

    if (max && toControl.value > max) {
      errors['max'] = max;
    }

    // set error on matchingControl if validation fails
    if (fromControl.value && Object.keys(errors).length) {
      toControl.setErrors(errors);
    } else {
      toControl.setErrors(null);
    }
  };
}
