import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-broadcast-studio-edit-avatar-dialog',
  templateUrl: './broadcast-studio-edit-avatar-dialog.component.html',
  styleUrls: ['./broadcast-studio-edit-avatar-dialog.component.scss'],
})
export class BroadcastStudioEditAvatarDialogComponent implements OnInit {
  imageSources: any[] = [];
  constructor(public dialogRef: MatDialogRef<BroadcastStudioEditAvatarDialogComponent>) {}

  ngOnInit(): void {}

  handleUploadImage(files: FileList) {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.imageSources = this.imageSources.map((item) => ({ ...item, active: false }));
      this.imageSources.push({
        src: event.target.result,
        active: true,
      });
    };
  }

  handleSaveAvatar() {
    const avatar = this.imageSources.find((item) => item.active);
    this.dialogRef.close(avatar);
  }
}
