import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AccountSettingDialogComponent,
  ConfirmDialogComponent,
  ConfirmDialogModel,
  RestreamAccountEditDialogComponent,
  RestreamAccountEditDialogModel,
  StreamAccount,
} from '@app/shared';
import { StreamAccountService } from '@app/shared/services/stream-account/stream-account.service';
import { NotifierService } from 'angular-notifier';
import { isThisSecond } from 'date-fns';

@Component({
  selector: 'app-connect-social-media-dialog',
  templateUrl: './connect-social-media-dialog.component.html',
  styleUrls: ['./connect-social-media-dialog.component.scss'],
})
export class ConnectSocialMediaDialogComponent implements OnInit {
  isLoading = false;
  public accounts: StreamAccount[];
  public reStreamStatus: boolean;
  constructor(
    private notifier: NotifierService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { studioId: string },
    public dialogRef: MatDialogRef<ConnectSocialMediaDialogComponent>,
    private streamAccountService: StreamAccountService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllStreamAccounts();
  }

  getPathIcon(provider: string): string {
    const key = provider.toLowerCase();
    switch (key) {
      case 'facebook':
        return 'facebook-logo.svg';
      case 'youtube':
        return 'youtube.svg';
      // case 'periscope':
      //   return 'periscope.svg';
      case 'twitch':
        return 'twitch.svg';
      default:
        return '';
    }
  }
  handleDeleteAccount(streamAccount: StreamAccount) {
    const dialogData = new ConfirmDialogModel('Do you want to delete this stream account?', '');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '470px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;
        const { uuid: accountId } = streamAccount;
        this.streamAccountService.deleteAccount(accountId).subscribe(
          () => {
            this.isLoading = false;
            this.notifier.notify('success', 'Account successfully deleted');
            this.getAllStreamAccounts();
          },
          () => {
            this.isLoading = false;
            this.notifier.notify('error', 'Something went wrong');
          }
        );
      }
    });
  }

  changeStatusAccount(account: StreamAccount) {
    this.isLoading = true;
    this.streamAccountService.toggleStream(account.uuid, account.active).subscribe(
      (res) => {
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllStreamAccounts() {
    this.isLoading = true;
    this.streamAccountService.getReStream().subscribe(
      (res) => {
        this.isLoading = false;
        if (res && res.length) {
          const restream = res[0];
          const { uuid, active, mode, stream_type, stream_accounts } = restream;
          this.accounts = stream_accounts;
          this.reStreamStatus = active;
        } else {
          this.accounts = [];
          this.reStreamStatus = false;
        }
      },
      (err) => {
        this.notifier.notify('error', err);
        this.isLoading = false;
      }
    );
  }

  openAccountSettingUpdateDialog(streamAccount: StreamAccount) {
    const dialogData = new RestreamAccountEditDialogModel(streamAccount);
    const dialogRef = this.dialog.open(RestreamAccountEditDialogComponent, {
      width: '500px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.getAllStreamAccounts();
      }
    });
  }

  openAccountSettingDialog() {
    const dialogRef = this.dialog.open(AccountSettingDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.getAllStreamAccounts();
      }
    });
  }

  onAddSocialMedia() {
    this.router.navigateByUrl('/restreamer');
    this.dialog.closeAll();
  }
}
