import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationRounded',
})
export class DurationRoundedPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const split = value?.split('.');
      return `${split[0]}`;
    }
    return '00:00:00';
  }
}
