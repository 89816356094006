import { environment } from '@env/environment';

export const getThemeThumbnail = (name: string) => {
  const lowName = name.toLowerCase();
  // const url = `${environment.siteBuilderCDNUrl}/gallery`;
  const url = '../../../../assets/images';
  switch (lowName) {
    case 'entertainment':
      return `${url}/entertainment-theme.png`;
    case 'playtv':
      return `${url}/playtv-theme.png`;
    case 'filmmaker':
      return `${url}/filmmarker-theme-1.png`;
    case 'ministry':
      return `${url}/ministries-theme.png`;
    case 'broadcaster':
      return `${url}/broadcaster-theme-1.png`;
    case 'boin':
      return `${url}/boin-theme-1.png`;
    default:
      return `${url}/unknown-theme-1.png`;
  }
};

export const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
