<div mat-dialog-content>
  <!-- <app-spinner *ngIf="isLoading"></app-spinner> -->
  <div fxLayout="column" fxLayoutGap="16px" style="text-align: center">
    <div class="text-weight-bold" [style.font-size]="'18px'">Invite guests to your Live Broadcast</div>
    <div>Send the link bellow to your guests. You can up to 6 people on screen at one</div>
    <div class="w-100">
      <input class="link-invite" type="text" [value]="meetingUrl" disabled />
    </div>
    <div fxLayout="row" class="w-100" fxLayoutGap="24px">
      <button fxFlex color="primary" mat-flat-button ngxClipboard [cbContent]="meetingUrl" (click)="coppiedLink()">
        COPY TO CLIPBOARD
      </button>
      <button fxFlex color="primary" mat-flat-button (click)="onEmailLinkClick()">EMAIL LINK</button>
    </div>
    <div>Setup a username and password for your guest</div>
    <div>
      <mat-checkbox color="primary" [(ngModel)]="requireAuthentication" (change)="onRequireAuthenChange()"
        >Authenticate Guest</mat-checkbox
      >
    </div>
    <ng-container *ngIf="setupForm">
      <form [formGroup]="setupForm" (ngSubmit)="onSave()">
        <mat-form-field appearance="outline" class="w-100">
          <input
            type="text"
            formControlName="username"
            matInput
            autocomplete="current-username"
            placeholder="USERNAME"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <input
            type="password"
            formControlName="password"
            matInput
            autocomplete="current-password"
            placeholder="PASSWORD"
            required
          />
        </mat-form-field>
      </form>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions fxLayout="column" fxLayoutGap="16px" style="text-align: center">
  <div fxLayout="row" class="w-100">
    <button
      fxFlex
      color="primary"
      mat-flat-button
      (click)="onSave()"
      [disabled]="buttonStatus"
      [style.background]="buttonStatus ? '#6a9fd3' : '#125fab'"
    >
      SAVE USERNAME AND PASSWORD
    </button>
  </div>
  <div class="refresh">This link is permanent. Need a new one? <a>REFESH</a></div>
</div>

<!-- <div fxLayout="row" *ngIf="!!emailGuests.length">
  <div [style.margin-right.px]="10">
    <mat-icon> email</mat-icon>
  </div>
  <div fxFlex>
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip *ngFor="let email of emailGuests" [removable]="true" (removed)="removeEmail(email)">
        {{ email }}
        <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
<div class="w-100">
  <mat-label>Email</mat-label>
  <mat-form-field [style.width]="'80%'" appearance="outline">
    <input id="name" name="name" type="text" matInput placeholder="please input a email" required email
      [(ngModel)]="email" #name="ngModel" />
  </mat-form-field>
  <ng-container *ngIf="name.invalid && (name.dirty || name.touched)">
    <mat-error *ngIf="name.errors?.required">
      Email is required.
    </mat-error>
  </ng-container>
  <button mat-raised-button (click)="addEmail()" color="primary" [disabled]="name.invalid || !name">
    <mat-icon>add</mat-icon>
  </button>
</div> -->
