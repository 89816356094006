import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
const m3u8Parser = require('m3u8-parser');

@Component({
  selector: 'app-stream-player',
  templateUrl: './stream-player.component.html',
  styleUrls: ['./stream-player.component.scss'],
})
export class StreamPlayerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() options: {
    mute: boolean;
    autoPlay: boolean;
    hideMediaControl: boolean;
  };
  @Input() source: string;
  @Input() playingTime: number;
  @Input() duration: number;
  @Input() src: {
    videoSource: string;
    startTime: number;
    duration: number;
  };
  @Input() isCustomLive: boolean;

  @Output() loadedMedia = new EventEmitter<boolean>();
  @Output() loadNewStream = new EventEmitter<boolean>();
  @Output() playedTimeEvent: EventEmitter<any> = new EventEmitter();

  player: any;
  isVolSet: boolean;
  segments: any;
  retryInterval: any;
  inLobby = false;
  timer: any;
  is_playing = true;
  last_error_time = 0;

  constructor() {}

  ngOnDestroy(): void {
    if (this.retryInterval) {
      clearInterval(this.retryInterval);
    }
    if (this.player) {
      this.player.remove();
    }
  }

  ngOnChanges({ src }: SimpleChanges): void {
    if (this.player && src) {
      const { videoSource } = src.currentValue;
      if (videoSource !== src.previousValue.videoSource) {
        this.playStream(videoSource);
      }
    }
  }

  ngAfterViewInit(): void {
    const { videoSource } = this.src;
    // console.log(videoSource);
    // Setup the player
    this.setupPlayer(videoSource);
    // this.player.on('play', function (event: any) {
    //   console.log('hls is playing!');
    //   console.log(this.getDuration());
    // });
  }
  setupPlayer(source: string) {
    this.player = jwplayer('player').setup({
      sources: [
        {
          default: true,
          file: source,
          type: 'hls',
          label: 0,
        },
      ],
      responsive: true,
      aspectratio: '16:9',
      primary: 'html5',
      hlshtml: true,
      autostart: true,
      allowFullscreen: true,
      liveTimeout: 10000000000,
      loadAndParseHlsMetadata: true,
      preload: 'auto',
      repeat: true,
      nextupoffset: 10,
      liveSyncDuration: 6,
    });

    jwplayer().onSetupError(() => {
      document.getElementById('player').innerHTML = '<img src="assets/img/broadcast/stream-lobby.png" width="100%"/>';
    });

    this.player.on('error', function (event: any) {
      if (event.code >= 330000 && event.code <= 339000) {
        this.seek(-this.getDuration());
      } else {
        document.getElementById('player').innerHTML = '<img src="assets/img/broadcast/stream-lobby.png" width="100%"/>';
      }
    });

    this.player.on('time', function (event: any) {
      if (this.getDuration() !== Infinity) {
        const playedTime = this.getDuration() + event.latency;
        const behindtime = playedTime + event.currentTime;
        if (behindtime > 20) {
          this.seek(-this.getDuration());
        }
        // turn this off for testing.
        // that.playedTimeEvent.emit(-playedTime);
      }
    });
  }
  ngOnInit(): void {}

  playStream(url: string) {
    try {
      if (url && this.player) {
        jwplayer().load({
          default: true,
          file: url,
          type: 'hls',
          label: 0,
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  hlsParser(url: string) {
    if (!url) {
      return undefined;
    }
    const http = new XMLHttpRequest();
    http.open('GET', url, false);
    http.send();

    const manifest = http.response;

    const parser = new m3u8Parser.Parser();
    parser.push(manifest);
    parser.end();

    return parser.manifest;
  }

  isUrlExists(url: string) {
    if (!url) {
      return false;
    }
    const http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  }
}
