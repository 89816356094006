<div class="dialog-wrapper">
  <h2 class="header-section" style="display: flex; align-items: center; gap: 5px">
    <mat-icon>font_download</mat-icon> Ad Video
  </h2>
  <div class="main-section" *ngIf="!isLoadingAdVideo">
    <div class="video-thumbail-section">
      <app-videojs-player
        class="preview-video-player"
        [options]="{
          autoplay: true,
          controls: true,
          controlBar: {
            liveDisplay: true,
            pictureInPictureToggle: false
          },
          sources: [
            {
              src: videoSource,
              type: 'video/mp4'
            }
          ]
        }"
        [videoParameters]="{
          height: 250,
          width: 400
        }"
      >
      </app-videojs-player>
      <!-- <img src="" alt=""> -->
    </div>
    <div class="video-details-section">
      <div class="desc-label">Title</div>
      <div class="video-title">{{ adVideo.title }}</div>
      <div class="desc-label">Description</div>
      <div class="video-description">
        <span *ngIf="adVideo.description">
          {{ adVideo.description }}
        </span>
        <span *ngIf="!adVideo.description"> No description available. </span>
      </div>

      <div>
        <span class="detail-section video-duration"
          ><mat-icon class="icon-display">access_time</mat-icon> <span>{{ formatDuration(adVideo.duration) }}</span>
        </span>
      </div>
      <div>
        <span class="detail-section video-created"
          ><mat-icon class="icon-display">calendar_today</mat-icon>
          <span>{{ adVideo.created | date: 'MMMM d, y, h:mm a' }}</span></span
        >
      </div>
    </div>
  </div>

  <div class="loading" *ngIf="isLoadingAdVideo">
    <mat-spinner color="primary" diameter="30"> </mat-spinner>
  </div>
</div>
