import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideoService } from '@app/shared/services';

@Component({
  selector: 'app-show-ad-video-dialog',
  templateUrl: './show-ad-video-dialog.component.html',
  styleUrls: ['./show-ad-video-dialog.component.scss'],
})
export class ShowAdVideoDialogComponent implements OnInit {
  isLoadingAdVideo: boolean = false;
  adVideo: any = null;
  videoSource: string = '';

  constructor(
    public dialogRef: MatDialogRef<ShowAdVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private videoService: VideoService
  ) {}

  ngOnInit(): void {
    this.isLoadingAdVideo = true;
    this.videoService.getAdVideo(this.data.videoId).subscribe((res) => {
      this.adVideo = res.data;
      this.videoSource = this.adVideo.output_file ? this.adVideo.output_file : this.adVideo.source_file;
      this.isLoadingAdVideo = false;
    });
  }

  onContinue(): void {
    this.dialogRef.close('continue');
  }

  onCancel(): void {
    this.dialogRef.close('cancel');
  }

  formatDuration(duration: string): string {
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = Math.floor(parseFloat(parts[2]));

    let readableDuration = '';
    if (hours > 0) {
      readableDuration += hours + ' hour' + (hours > 1 ? 's' : '') + ', ';
    }
    if (minutes > 0) {
      readableDuration += minutes + ' minute' + (minutes > 1 ? 's' : '') + ', ';
    }
    readableDuration += seconds + ' second' + (seconds > 1 ? 's' : '');

    return readableDuration;
  }
}
