<div class="iframe-container" fxLayout="row">
  <div class="left-bar" fxLayout="column" fxLayoutAlign="start center" fxFlex="0 0 56px">
    <div>
      <button mat-icon-button fxLayoutAlign="center" (click)="back()">
        <mat-icon class="mat-24">arrow_back</mat-icon>
      </button>
      <button mat-icon-button fxLayoutAlign="center" (click)="preview()">
        <mat-icon class="mat-24">edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="entertainment-wrapper" fxFlex="0 1 100">
    <iframe class="e2e-iframe-trusted-src" width="100%" height="100%" [src]="sourceUrl"></iframe>
  </div>
</div>
