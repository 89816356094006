import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/auth/services';
import { CommonHelpers } from '@app/helpers';
import { Video } from '@app/shared/domain';
import { VideoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { filter, first } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '@env/environment';

@Component({
  selector: 'app-assign-trailer-clip',
  templateUrl: './assign-trailer-clip.component.html',
  styleUrls: ['./assign-trailer-clip.component.scss'],
})
export class AssignTrailerClipComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public files: NgxFileDropEntry[] = [];
  public dataSource: MatTableDataSource<Video> = new MatTableDataSource([]);
  public displayedColumns: string[] = ['thumbnail', 'title', 'description', 'duration', 'created', 'actions'];
  public params = {
    page: 1,
    pageSize: 20,
    total: 0,
    totalPage: 0,
    search: '',
    ordering: '-created',
    pageSizeOptions: [20, 50, 100, 150, 200],
  };
  public activeVideoId: string;
  private uploadSocket: WebSocketSubject<any>;
  public assignType: string;
  constructor(
    private videoService: VideoService,
    public dialogRef: MatDialogRef<AssignTrailerClipComponent>,
    private notifier: NotifierService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.activeVideoId = data.activeVideoId;
    if ('assignType' in data) {
      this.assignType = data.assignType;
    } else {
      this.assignType = 'Trailer';
    }

    this.authService
      .getAccountInfo()
      .pipe(filter((s) => !!s))
      .subscribe((user) => {
        if (this.uploadSocket) {
          this.uploadSocket.complete();
        }
        const token = this.authService.getStoreToken();
        this.uploadSocket = webSocket({
          url: `${environment.wsUrl}/video-encode-status/${user.id}/?token-key=${token}`,
        });

        this.uploadSocket.subscribe(
          ({ message }) => {
            if (message) {
              this.getVideos();
            }
          },
          (err) => console.log(err),
          () => console.log('complete')
        );
      });
  }
  ngOnDestroy(): void {
    if (this.uploadSocket) {
      this.uploadSocket.complete();
    }
  }

  ngOnInit() {
    this.getVideos();
  }

  getVideos() {
    this.isLoading = true;
    this.params.total = 0;
    this.params.totalPage = 0;
    let { page, pageSize, search, ordering } = this.params;
    if (!search) {
      search = 'COMPLETED';
    }

    let onlyAds = false;
    if (this.assignType == 'Advertisement') {
      onlyAds = true;
    }
    this.videoService
      .getVideos({
        page,
        page_size: pageSize,
        only_ads: onlyAds,
        search,
        ordering,
      })
      .subscribe(
        (res) => {
          const { results, total } = res;
          this.params.total = total;
          this.dataSource = new MatTableDataSource(results);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  pageChanged(event: any) {
    this.params.pageSize = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getVideos();
  }

  handleSearchVideos() {
    this.params.pageSize = 20;
    this.params.page = 1;
    this.getVideos();
  }

  handleSelect(video: Video) {
    this.dialogRef.close(video);
  }

  handleSortChange(event: any) {
    const { active, direction } = event;
    if (direction === 'asc') {
      this.params.ordering = active;
    } else {
      this.params.ordering = `-${active}`;
    }
    this.getVideos();
  }
}

export class AssignTrailerClipModel {}
