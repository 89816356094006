<div fxLayout="column" fxLayoutAlign="center center" [style.color]="color" class="footer" *ngIf="showFooter">
  <div>{{ 'COPYRIGHT' | translate }} © {{ year }} TvStartup Inc.</div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div><a [style.color]="color" href="/terms-policies" target="_blank">Privacy Policy</a></div>
    <mat-divider
      [style.border-right-color]="color"
      vertical
      [style.height.px]="12"
      [style.margin-right.px]="8"
      [style.margin-left.px]="8"
    ></mat-divider>
    <div>{{ 'SUPPORT' | translate }}</div>
  </div>
</div>
