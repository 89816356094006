import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-rtmp-source-dialog',
  templateUrl: './add-rtmp-source-dialog.component.html',
  styleUrls: ['./add-rtmp-source-dialog.component.scss'],
})
export class AddRtmpSourceDialogComponent implements OnInit {
  addRTMPFrom: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.addRTMPFrom = this.formBuilder.group({
      isUseAuthentication: [true],
    });
  }

  get f() {
    return this.addRTMPFrom?.controls;
  }
}
