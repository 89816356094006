import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function NotSameFiled() {
  return (formGroup: FormGroup) => {
    const controls = formGroup.controls;

    Object.keys(controls).forEach((key) => {
      const controlName = controls[key];
      if (controlName.errors && !controlName.errors.sameFiled) {
        return;
      }
      const anothers = Object.values(controls);
      if (anothers.filter((item) => item.value === controlName.value).length > 1) {
        controlName.setErrors({
          sameFiled: true,
        });
      } else {
        controlName.setErrors(null);
      }
    });
  };
}
