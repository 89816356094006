<div fxLayout="row" *ngIf="accountInfo$ | async as accountInfo" fxLayoutAlign="end center">
  <div class="username">{{ accountInfo.username }}</div>
  <button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <mat-icon *ngIf="!accountInfo.avatar">account_circle</mat-icon>
    <img
      width="30"
      height="30"
      class="rounded mat-elevation-z6"
      onerror="this.src='/assets/images/blank-profile.png'"
      *ngIf="accountInfo.avatar"
      [src]="accountInfo.avatar"
      alt="avatar"
      srcset=""
    />
  </button>
</div>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
  <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->
  <mat-toolbar color="primary">
    <div
      *ngIf="accountInfo$ | async as accountInfo"
      fxLayout="row"
      fxLayoutAlign="space-around center"
      class="user-info"
    >
      <img
        [src]="accountInfo.avatar ? accountInfo.avatar : userImage"
        onerror="this.src='/assets/images/blank-profile.png'"
        alt="user-image"
        width="80"
        height="80"
        class="rounded mat-elevation-z6"
      />
      <p>
        {{ accountInfo.username }} <br />
        <small>{{ accountInfo.email }}</small>
      </p>
    </div>
  </mat-toolbar>
  <a mat-menu-item routerLink="user-profile">
    <mat-icon>person</mat-icon>
    <span>Profile</span>
  </a>
  <a mat-menu-item routerLink="users" *ngIf="(accountInfo$ | async).user_type !== configConstans.userType.CMS_STAFF">
    <mat-icon>supervisor_account</mat-icon>
    <span>Users</span>
  </a>
  <a mat-menu-item routerLink="/app-setting">
    <mat-icon>settings</mat-icon>
    <span>Settings</span>
  </a>
  <!-- <a mat-menu-item routerLink="/">
    <mat-icon>lock</mat-icon>
    <span>Lock screen</span>
  </a> -->
  <a mat-menu-item routerLink="/video-tutorials">
    <mat-icon>help</mat-icon>
    <span>Help</span>
  </a>
  <div class="divider"></div>
  <a (click)="handleLogout()" mat-menu-item routerLink="/login">
    <mat-icon>power_settings_new</mat-icon>
    <span>Logout</span>
  </a>
  <!--</span>-->
</mat-menu>
