import { StreamType } from '@app/shared';
import { environment } from '@env/environment';
import * as moment from 'moment';

export const getDuration = (time: string) => {
  return moment.duration(moment(time, 'hh:mm:ss').diff(moment('00:00:00', 'hh:mm:ss')));
};

export const formatCacheFlyVideoUrl = (videoUrl: string) => {
  // const url = videoUrl.substring(videoUrl.indexOf('/output/'), videoUrl.length);
  // return `${environment.streamUrl}/media${url}/playlist.m3u8`;
  // const splitted = hls.split('_');
  // return `${splitted[0]}${splitted.length > 1 ? '.mp4' : ''}/playlist.m3u8`;
};

export const getVideoPosition = (videos: any, plPlayingTime: number) => {
  let position = 0;
  let totalPlayHistory = 0;
  let vPlayingTime = 0;
  while (totalPlayHistory < plPlayingTime) {
    if (position >= videos.length) {
      position = 0;
    }
    const v = videos[position];
    totalPlayHistory += getDuration(v.video.duration || 0).asSeconds();
    if (totalPlayHistory > plPlayingTime) {
      break;
    }
    position++;
  }

  const vPosition = position >= videos.length ? 0 : position;
  const vDuration = getDuration(videos[vPosition].video.duration || 0).asSeconds();
  vPlayingTime = vDuration - (totalPlayHistory - plPlayingTime);
  return { vPosition, vPlayingTime, remainTime: Math.floor(vDuration - vPlayingTime) + 1 };
};

export const getPlayingTime = (playedTime: number, videos: any, position: number, duration: any) => {
  const durationHistory = playedTime;
  let totalPlayHistory = 0;
  videos.forEach((v: any, i: number) => {
    if (i < position) {
      totalPlayHistory += getDuration(v.video.duration || 0).asSeconds();
    }
  });
  const vDuration = parseFloat(duration);
  const playingTime = durationHistory - totalPlayHistory;
  return playingTime > vDuration ? 0 : playingTime;
};

export const getStreamId = (channelId: string, type: StreamType) => {
  const sType = type === StreamType.PLAYLIST ? 'playlist' : 'live';
  const streamPrefix = environment.streamProduction ? 'prod' : 'dev';
  return `${streamPrefix}-${channelId.replace(/-/g, '')}`;
};
