export class Utils {
  static MAX_ROUND_TRIP_TIME = 250;
  public static intervalUnits(interval: number, unit: string) {
    const Millisecond = 1;
    const Second = 1000;
    const Minute = 60 * Second;
    const Hour = Minute * 60;

    unit = unit || 'minutes';

    const Units = {
      milliseconds: Millisecond,
      seconds: Second,
      minutes: Minute,
      hours: Hour,
    };

    return interval * Units[unit];
  }

  public static getNow() {
    return performance.now();
  }
}
