<mat-sidenav-container>
  <mat-toolbar color="primary" class="flex-p-x">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
      <div fxLayout="row" fxLayoutAlign="start center">
        <a class="logo" routerLink="/">
          <img alt="Logo" src="/assets/images/tvstartup-logo.png" />
        </a>
        <div class="page-title">{{ breadcrumb }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-icon class="help-icon icon-grey">error</mat-icon>
        <app-language-selector></app-language-selector>
      </div>
    </div>
  </mat-toolbar>

  <router-outlet></router-outlet>
  <app-footer [color]="'#f2f2f2'"></app-footer>
</mat-sidenav-container>
