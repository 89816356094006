<div class="flex-p">
  <app-spinner *ngIf="false"></app-spinner>
  <form fxLayout="column" fxLayoutGap="40px" class="general-settings-form" [formGroup]="generalForm">
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="siteName">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Site Name</h3>
        <p class="description">Name on website list</p>
      </div>
      <div fxFlex="60" class="site-name">
        <input type="text" formControlName="siteName" />
      </div>
    </div>
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Support Email</h3>
        <p class="description">Email of support Email</p>
      </div>
      <div fxFlex="60" class="site-name">
        <input type="text" formControlName="supportEmail" />
      </div>
    </div>
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Favicon</h3>
        <p class="description">
          Appears in the browser tabs. <br />
          Recommended size 48x48px <br />
          File Type: .ico or .png
        </p>
      </div>
      <div fxFlex="60">
        <button
          *ngIf="!previewFavicon"
          mat-flat-button
          color="primary"
          (click)="fileInput.click()"
          type="button"
          class="button-200"
          [disabled]="!isPublished"
        >
          Upload Image
          <mat-icon *ngIf="loadingFavicon"><mat-spinner diameter="20" color="accent"> </mat-spinner></mat-icon>
        </button>
        <mat-card-content *ngIf="previewFavicon">
          <img
            src="{{ previewFavicon }}"
            width="100px"
            height="100px"
            (click)="fileInput.click()"
            (error)="errorFaviconHandler()"
          />
          <mat-icon *ngIf="loadingFavicon" class="img-spin"
            ><mat-spinner diameter="20" color="primary"> </mat-spinner
          ></mat-icon>
        </mat-card-content>
        <input #fileInput type="file" hidden (change)="handleChange($event.target.files)" />
      </div>
    </div>
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Related Videos Position</h3>
        <p class="description">Choose how the playlist will look for your viewers.</p>
      </div>
      <div fxFlex="60">
        <mat-radio-group
          class="videos-position"
          fxLayout="row"
          fxFlex="60"
          fxLayoutGap="16px"
          aria-label="Select an option"
          formControlName="videoStyle"
        >
          <mat-radio-button color="primary" value="sidebar">
            <img width="248" height="160" src="assets/img/template/sidebar.png" alt="SIDEBAR IMAGE" />
          </mat-radio-button>
          <mat-radio-button color="primary" value="standard">
            <img width="248" height="160" src="assets/img/template/below-player.png" alt="BELOW PLAYER IMAGE" />
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayoutGap="32px" fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="calc(40% - 32px)">
        <h3 class="title">Continue Watching</h3>
        <p class="description">
          Show a section at the top of the catalog page that allows users to resume where they left off.
        </p>
      </div>
      <div fxFlex="60">
        <mat-checkbox color="primary" formControlName="showContinueWatching"> Show Continue Watching </mat-checkbox>
      </div>
    </div>
  </form>
</div>
