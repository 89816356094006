import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    if (value) {
      const split = value?.split(':');
      return `${split[0]} hours ${split[1]} minutes ${split[2]} seconds`;
    }
    return `00 hours 00 minutes 00 seconds`;
  }
}
