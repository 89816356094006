<div mat-dialog-title style="text-align: center">Setting</div>
<div mat-dialog-content style="overflow-x: hidden !important">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <form>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between none" fxLayoutGap="20px">
        <div fxLayout="column" fxFlex>
          <mat-label class="text-weight-bold">Video Input</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select
              placeholder="Select Account Platform"
              [(value)]="defaultVideoDeviceId"
              [disabled]="!liveStudioSetting?.isOpenCamera"
            >
              <mat-option
                *ngFor="let item of videoInputs"
                [value]="item.id"
                (onSelectionChange)="onSelectVideo($event, item)"
                >{{ item.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-label class="text-weight-bold">Mic Input</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select
              placeholder="Select Account Platform"
              [(value)]="defaultAudioInputDeviceId"
              [disabled]="!liveStudioSetting?.isOpenMic"
            >
              <mat-option
                *ngFor="let item of micInputs"
                [value]="item.id"
                (onSelectionChange)="onSelectAudioInput($event, item)"
                >{{ item.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-label class="text-weight-bold">Speaker Output</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Select Account Platform" [(value)]="defaultAudioOutputDeviceId">
              <mat-option
                *ngFor="let item of speakerOutputs"
                [value]="item.id"
                (onSelectionChange)="onSelectAudioOutput($event, item)"
                >{{ item.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex>
          <div
            #webcamFrame
            [ngClass]="{ 'black-bg': !source || !liveStudioSetting?.isOpenCamera }"
            fxLayoutAlign="center center"
          >
            <div class="icon">
              <div class="icon-palette" *ngIf="!source || !liveStudioSetting?.isOpenCamera">
                <mat-icon class="mat-40" [style.color]="'#fff'">videocam</mat-icon>
              </div>
              <video
                [hidden]="!(liveStudioSetting?.isOpenCamera && source && !this.getActiveBackground())"
                #videoCamera
                autoplay
                muted
                [id]="'video_sync_' + item.id"
                [srcObject]="source"
                class="vjs-default-skin w-100 lv-video-pls video-cam"
              ></video>
              <canvas
                [hidden]="
                  !(liveStudioSetting?.isOpenCamera && source && this.getActiveBackground() && !this.isSettingWebgl())
                "
                style="
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                  height: 200px;
                "
                [ngStyle]="{ 'background-image': 'url(' + getBackgroundSrc() + ')' }"
                #outputCanvas
                class="vjs-default-skin w-100"
              ></canvas>
              <canvas
                [hidden]="
                  !(liveStudioSetting?.isOpenCamera && source && this.getActiveBackground() && this.isSettingWebgl())
                "
                style="
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                  height: 200px;
                "
                [ngStyle]="{ 'background-image': 'url(' + getBackgroundSrc() + ')' }"
                #outputCanvasWeb
                class="vjs-default-skin w-100"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="broadcast-setting-dialog-add-screen">
        <div class="text text-weight-bold">Green Screen Background</div>
        <div
          fxFlex
          fxLayout="row"
          class="warning-msg"
          matTooltip="
          The green screen feature works on almost all devices, but we recommend using a powerful computer since it uses your graphics processor (GPU).
          Make sure you have enabled hardware acceleration.
        "
        >
          <mat-icon color="warn">warning</mat-icon>
          <span>Additional equipment needed: solid green (blue/red) background.</span>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          style="margin-top: 12px; overflow-y: auto; width: 100%"
        >
          <app-spinner *ngIf="backgroundUploading"></app-spinner>
          <div class="image" *ngFor="let bg of backgroundImages">
            <div
              *ngIf="bg.source"
              class="broadcast-setting-dialog-add-screen-default"
              [ngClass]="{ 'image-selected': bg.is_active === true }"
            >
              <img class="bg-image" [src]="bg.source" alt="GREEN SCREEN BACKGROUND" />
              <div class="mask">
                <div *ngIf="!bg.is_active" (click)="handleSelectBackgroundImage(bg, '')">Select</div>
                <div (click)="removeBackgroundImage(bg)">
                  <mat-icon>delete</mat-icon>
                </div>
              </div>
            </div>

            <div
              *ngIf="!bg.source"
              [ngClass]="{ 'image-selected': bg.is_active === true }"
              class="broadcast-setting-dialog-add-screen-default"
            >
              <div class="bg-image" [ngClass]="{ 'image-selected': bg.is_active === true }">NONE</div>
              <div class="mask">
                <div *ngIf="!bg.is_active" (click)="handleSelectBackgroundImage(bg, 'none')">Select</div>
              </div>
            </div>
          </div>

          <button (click)="inputUploadBackground.click()">
            <mat-icon>add</mat-icon>
          </button>
          <input
            hidden
            #inputUploadBackground
            type="file"
            (change)="handleUploadFile($event.target.files, 5)"
            accept="image/*"
          />
        </div>
      </div>
      <div fxFlex>
        <a mat-icon-button (click)="onChangeDropdown()">
          <mat-icon>
            {{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </mat-icon>
        </a>
        <span style="cursor: pointer" (click)="onChangeDropdown()">
          {{ show ? 'Show less' : 'Show advanced options' }}
        </span>
      </div>
      <div class="container" [hidden]="!show">
        <div fxLayout="row" fxLayoutAlign="space-between none" fxLayoutGap="20px" [style.margin-top]="'20px'">
          <div fxLayout="column" fxFlex>
            <div fxLayout="column" fxFlex>
              <div class="text-weight-bold">Similarity</div>
              <mat-slider
                name="similarity"
                min="0"
                max="1"
                step="0.01"
                [(ngModel)]="optionGraphicWebgl.similarity"
                [ngModelOptions]="{ standalone: true }"
                (change)="onChangeWebgl()"
                aria-label="unit(s)"
                [disabled]="!backgroundGreenScreen.media_uuid"
              ></mat-slider>
            </div>
            <div fxLayout="column" fxFlex>
              <div class="text-weight-bold">Smoothness</div>
              <mat-slider
                name="smoothness"
                min="0"
                max="1"
                step="0.01"
                [(ngModel)]="optionGraphicWebgl.smoothness"
                [ngModelOptions]="{ standalone: true }"
                (change)="onChangeWebgl()"
                aria-label="unit(s)"
                [disabled]="!backgroundGreenScreen.media_uuid"
              ></mat-slider>
            </div>
            <div fxLayout="column" fxFlex>
              <div class="text-weight-bold">Spill</div>
              <mat-slider
                name="spill"
                min="0"
                max="1"
                step="0.01"
                [(ngModel)]="optionGraphicWebgl.spill"
                [ngModelOptions]="{ standalone: true }"
                (change)="onChangeWebgl()"
                aria-label="unit(s)"
                [disabled]="!backgroundGreenScreen.media_uuid"
              ></mat-slider>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <mat-label class="text-weight-bold" [style.margin-bottom]="'15px'">Backdrop Color</mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <mat-select
                [(ngModel)]="optionGraphicWebgl.color"
                (ngModelChange)="onChangeWebgl()"
                name="color"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!backgroundGreenScreen.media_uuid"
                placeholder="Select Color"
              >
                <mat-option value="green">Green</mat-option>
                <mat-option value="blue">Blue</mat-option>
                <mat-option value="red">Red</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div fxLayout="row" class="w-100" [style.padding]="'8px 12px 8px 20px'">
    <button
      [style.border]="'2px solid'"
      [style.font-size]="'20px'"
      (click)="openDialogLinkSocial()"
      fxFlex
      color="primary"
      mat-stroked-button
    >
      LINKED SOCIAL MEDIA ACCOUNTS
    </button>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" [style.padding]="'0 20px'" [style.margin-top]="'20px'">
  <button fxFlex [style.background]="'#dcd9d9'" (click)="onCancel()" mat-dialog-close mat-flat-button>Cancel</button>
  <button fxFlex color="primary" (click)="onSave()" appPreventDoubleClick [disabled]="loading" mat-flat-button>
    Save
    <mat-icon *ngIf="loading === true">
      <mat-spinner color="warn" diameter="24"> </mat-spinner>
    </mat-icon>
  </button>
</div>
