export const SamplePurchasePages = [
  {
    uuid: '123',
    name: 'filmfliks.com',
    is_selected: false,
    is_published: false,
    domain: 'domain.com',
  },
];

export const ContentType = {
  AUDIO: 1,
  VIDEO: 2,
  SUBSCRIPTION: 3,
  VOD: 4,
  AOD: 5,
  CHANNEL: 6,
  PPV_EVENT: 7,
  TENANT: 8,
};

export enum TutorialType {
  Dashboard = 0,
  MyTV = 1,
  VideoLibrary = 2,
  AudioLibrary = 3,
  Channels = 4,
  VideoOnDemand = 5,
  AudioOnDemand = 6,
  VideoPlaylist = 7,
  AudioPlaylist = 8,
  AppSettings = 9,
  Subscription = 10,
  Restream = 11,
  LiveRecorder = 12,
  LiveBroadcast = 13,
  PPV = 14,
  Analytics = 15,
  WebsiteBuilder = 16,
}

export const PurchaseLink = {
  [ContentType.AOD]: 'purchase-audio.html',
  [ContentType.AUDIO]: 'purchase-audio.html',
  [ContentType.VIDEO]: 'purchase-video.html',
  [ContentType.VOD]: 'purchase-video.html',
  [ContentType.SUBSCRIPTION]: 'pricing.html',
  [ContentType.PPV_EVENT]: 'purchase-ppv-event.html',
};

export const CheckBoxContentTypes = [
  {
    name: 'Individual Content',
    checked: false,
    value: Object.values(ContentType).filter((val) => val !== ContentType.TENANT),
    disabled: false,
  },
  { name: 'Entire Channel', checked: false, value: [ContentType.TENANT], disabled: false },
];

export const LiveScreenIndex = {
  DEFAULT: -1,
  BACKGROUND: 0,
  LAYOUT_SOURCE_ITEM: 1,
  SOURCE_ITEM: 2,
  TAG_NAME: 3,
  SOURCE_ITEM_FULL_SCREEN: 4,
  OVERLAY_BACKGROUND: 5,
  INTRO: 6,
  TICKER: 7,
  LOGO: 8,
  BANNER: 8,
  OVERLAY_CHAT: 9,
};
