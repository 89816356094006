import { ElementRef, Injectable } from '@angular/core';
import { BroadcastStudioService } from '@shared';
import { VideocamerawebglService } from '@shared/services/videoCameraWebgl';
import { Instance } from '@shared/services/videoCameraChromaKey/index';

@Injectable({
  providedIn: 'root',
})
export class VideoCameraChromaKeyService {
  instance: Instance;
  private color: any = {
    green: [120, 170],
    red: [0, 15, 345, 359],
    blue: [186, 268],
  };

  constructor(
    private broadcastStudioService: BroadcastStudioService,
    private videocamerawebglService: VideocamerawebglService
  ) {
    this.instance = {} as any;
  }

  addStreamScreen(
    video: HTMLVideoElement,
    canvasEl: ElementRef<HTMLCanvasElement>,
    canvasElWeb: ElementRef<HTMLCanvasElement>,
    id: string,
    type: string
  ) {
    const canvas = canvasEl.nativeElement;
    const defaultObbject = {
      context: canvas.getContext('2d'),
      canvasEl,
      canvasElWeb,
      webGl: null,
      video,
      stop: true,
      width: canvas.width,
      height: canvas.height,
      reqID: null,
    } as any;
    if (this.instance[id]) {
      this.instance = {
        ...this.instance,
        [id]: {
          ...this.instance[id],
          [type]: defaultObbject,
        },
      };
    } else {
      this.instance = {
        ...this.instance,
        [id]: {
          [type]: defaultObbject,
        },
      };
    }

    return this;
  }

  // @ts-ignore
  private cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  reInit(id: string, type: string) {
    if (!this.instance[id][type] || this.instance[id][type].stop === true) {
      this.startStream(id, type);
    }
  }

  stop(id: string, type: string) {
    if (this.instance[id][type]) {
      this.instance[id][type].stop = true;
    }
  }

  isStreaming(id: string, type: string) {
    return this.instance[id][type] && this.instance[id][type].stop === false;
  }

  startStream(id: string, type: string) {
    const self = this;
    const selfModel = this.instance[id][type];
    //already start
    if (!selfModel || selfModel?.stop == false) return;
    //start
    selfModel.stop = false;
    function draw() {
      if ((window as any).stopCamera === true || (self.instance[id][type] && self.instance[id][type].stop === true)) {
        if (selfModel.reqID != null) {
          self.cancelAnimationFrame(selfModel.reqID);
        }
        return;
      }
      var frame = self.readFrame(id, type);
      let backgroundVideo = {};
      if (type === 'LIVE_PREVIEW') {
        backgroundVideo = self.broadcastStudioService?.getLiveStudioSettingPreview()?.backgroundVideo || {};
      } else {
        backgroundVideo = self.broadcastStudioService?.liveStudioSetting?.backgroundVideo || {};
      }
      // @ts-ignore
      const { similarity = 0, smoothness = 0, spill = 0, color = 'green' } = backgroundVideo?.optionWebgl || {};
      if (frame) {
        self.replaceColor(color, frame.data);
        selfModel.context.putImageData(frame, 0, 0);
      }
      // self.applyBackground(selfModel.canvasEl.nativeElement, broadcastStudioService);
      if (!(similarity === 0 && smoothness === 0 && spill === 0)) {
        if (selfModel.webGl == null) {
          selfModel.webGl = self.videocamerawebglService.initWebGl(
            selfModel.canvasEl,
            selfModel.canvasElWeb,
            '#00FF00'
          );
        }
        if (selfModel.webGl.processFrame != null) {
          selfModel.webGl.processFrame(similarity, smoothness, spill);
        }
      }
      // Wait for the next frame.
      selfModel.reqID = requestAnimationFrame(draw);
    }

    // Ready! Let's start drawing.
    selfModel.reqID = requestAnimationFrame(draw);
  }

  readFrame(id: string, type: string) {
    const selfModel = this.instance[id][type];
    try {
      selfModel.context.drawImage(selfModel.video, 0, 0, selfModel.width, selfModel.height);
    } catch (e) {
      console.log(e);
      // The video may not be ready, yet.
      return null;
    }
    return selfModel.context.getImageData(0, 0, selfModel.width, selfModel.height);
  }

  replaceColor(colorName: string, data: any) {
    var len = data.length / 4;
    const isRangeSetting =
      colorName != '-' &&
      this.color[colorName] !== null &&
      Array.isArray(this.color[colorName]) &&
      this.color[colorName].length >= 2;
    let n = 0;
    if (!isRangeSetting) {
      console.log(colorName, " .This color doesn't match the range hls");
      return;
    }
    n = this.color[colorName].length;
    const range = this.color[colorName];
    for (var i = 0; i < len; i++) {
      // Convert from RGB to HSL...
      var hsl = this.rgb2hsl(data[i * 4], data[i * 4 + 1], data[i * 4 + 2]);
      var h = hsl[0],
        s = hsl[1],
        l = hsl[2];

      // ... and check if we have a somewhat green red blue pixel.
      if (((h >= range[0] && h <= range[1]) || (n === 4 && h >= range[2] && h <= range[3])) && s >= 25 && l <= 50) {
        data[i * 4 + 3] = 0;
      }
    }
  }

  rgb2hsl(r: any, g: any, b: any) {
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    // Calculate hue
    // No difference
    if (delta == 0) h = 0;
    // Red is max
    else if (cmax == r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax == g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s, l];
  }

  applyBackground(canvas: HTMLCanvasElement, broadcastStudioService: BroadcastStudioService) {
    const { backgroundVideo } = broadcastStudioService?.liveStudioSetting || {};
    // set background for video live stream
    var bgVideo = null;
    if (backgroundVideo && backgroundVideo?.source && backgroundVideo?.source?.length > 0) {
      bgVideo = new Image();
      bgVideo.crossOrigin = 'anonymous';
      bgVideo.src = backgroundVideo.source;
      this.setBackground(bgVideo, canvas);
    }
  }

  setBackground(backgroundVideo: any, canvasElement: HTMLCanvasElement) {
    if (canvasElement == null) return;
    const canvasCtx = canvasElement.getContext('2d');
    if (backgroundVideo == null) return;
    if (canvasCtx == null) return;
    const width = canvasElement.width;
    const height = canvasElement.height;
    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.drawImage(backgroundVideo, 0, 0, width, height);
  }
}
