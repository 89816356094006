<div class="entry-header">
  <h1 class="entry-title">Terms &amp; Policies</h1>
</div>
<!-- .entry-header -->

<div class="entry-content">
  <p><strong>Third Party Data Usage:</strong></p>
  <p>&nbsp;</p>
  <p>
    TvStartup's use and transfer of information received from Google APIs to any other app will adhere to
    <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a
    >, including the Limited Use requirements.
  </p>
  <p>&nbsp;</p>
  <p><strong>Refund Policy:</strong></p>
  <p>&nbsp;</p>
  <p>
    Customers are eligible for reimbursement within 7 calendar days of the purchase date or subscription date for
    services and subscriptions in most cases*. Please note that after the 7-day grace period, customers are not eligible
    for refunds. We encourage customers to ensure our services or software fits their needs within 7 days after
    purchase.
  </p>
  <p>&nbsp;</p>
  <p>
    In addition to our initial reimbursement period, we offer account credits for customers who request refunds after
    this period. This allows customers to apply the original purchase amount towards another product or service, or
    reserve the purchase amount as a credit on the TvStartup account for future use.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>Exclusive Memorial Day Sale 2023 Terms:</strong></p>
  <p>&nbsp;</p>
  <p>
    Discounted rates for the 2023 Memorial Day Special only apply for the first 3 months of service and existing and/or
    former customers are not eligible for the discounted pricing.
  </p>
  <p>&nbsp;</p>
  <p>
    <strong><br />Important:</strong>
  </p>
  <p>&nbsp;</p>
  <p>
    All sales are non-refundable once development services have been rendered, hosting services have been provided,
    and/or access to the TvStartup Software Platform has been provided.
  </p>
  <p>&nbsp;</p>
  <p>
    For any additional questions or to request a refund, please&nbsp;<a href="https://tvstartup.com/contact-us/"
      >contact us.</a
    >
  </p>
  <p>&nbsp;</p>
  <p>
    <strong><br />Billing Policy:</strong>
  </p>
  <p>&nbsp;</p>
  <p>
    All monthly service plans provided by TvStartup Inc. are considered recurring monthly subscriptions. Upfront fees
    for one-time development services are specified as such on the invoice where applicable.
  </p>
  <p>&nbsp;</p>
  <p>
    Customer agrees at time of purchase to authorize charges both at the time of purchase and recurring monthly
    auto-billing for all subscription plans. All subscription plans require a payment method to be saved on file for
    monthly billing.
  </p>
  <p>&nbsp;</p>
  <p>
    All sales are non-refundable after development, hosting services, and/or access to the TvStartup Software Platform
    have been provided. Upfront development services are non-refundable if the development services have been rendered
    to client.
  </p>
  <p>&nbsp;</p>
  <p>
    All subscriptions to our Video CMS software include a limited amount of bandwidth and storage defined by the terms
    of the subscription plan. By default on our most common subscription plan this is 500GB of CDN bandwidth and 500GB
    of CDN storage, but may differ depending on the exact service plan in use. Bandwidth usage and overages are tracked
    in the TvStartup Video CMS software dashboard, and all usage overages are auto billed monthly.
  </p>
  <p>&nbsp;</p>
  <p><strong>Cancellation Policy:</strong></p>
  <p>&nbsp;</p>
  <p>
    For monthly recurring subscription plans which allow cancellation at any time (no-contract), customers can request
    cancellation by contacting billing@tvstartup.com at any time.
  </p>
  <p>&nbsp;</p>
  <p>
    To re-activate a service after cancellation, reactivation fees may apply in many cases. For services where
    development fees are applicable and a channel has previously been terminated, the development fee(s) for the
    services must be paid again to re-develop the app(s) and re-register the channel account in order to re-activate the
    channel.
  </p>
  <p>&nbsp;</p>
  <p>
    If a discounted rate has been applied at signup during a sale or other special offer in which the development fee(s)
    have been discounted or waived for service(s), the development fee(s) will apply to re-activate the account if
    account has been cancelled.
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>Content Policy:</strong></p>
  <p>&nbsp;</p>
  <p>
    TvStartup Inc. hosts channel content in compliance with current platform policies for each app or channel platform
    respectively which we develop apps for. There are two specific commonalities between all app platforms with regards
    to content policy, prohibiting the following contents from being hosted on any channel:
  </p>
  <ol>
    <li>
      <strong>Pirated Content:</strong> any copyrighted content for which proper distribution rights have not been
      acquired prior to publishing the video/audio&nbsp;content which is in violation of the respective app platform’s
      content policy is prohibited.
    </li>
    <li>
      <strong>Explicit Content/Pornography:</strong> any video/audio content containing nudity, pornography, or
      otherwise sexually explicit content which is in violation of the respective app platform’s content policy is
      prohibited.
    </li>
  </ol>
  <p>&nbsp;</p>
  <p>
    TvStartup reserves the right to temporarily or permanently remove channels or individual video contents from any
    channel or app in order to comply with these requirements. Platforms such as Apple TV, Amazon Fire TV, Roku and
    others may enforce compliance of these policies at any time a violation is discovered for any app TvStartup
    publishes on behalf of a client, and TvStartup is required to comply with the app platform’s content policy
    requirements with expediency.
  </p>
  <p>&nbsp;</p>
  <p>
    For reference, here are current content policy guidelines for the most popular app platforms TvStartup develops on:
  </p>
  <p>&nbsp;</p>
  <p>
    Current Apple Store Review Guidelines from Apple:<br /><a
      href="https://developer.apple.com/app-store/review/guidelines/"
      >https://developer.apple.com/app-store/review/guidelines/</a
    >
  </p>
  <p>&nbsp;</p>
  <p>
    Roku Developer Program Compliance:<br /><a href="https://developer.roku.com/docs/features/legal/compliance.md"
      >https://developer.roku.com/docs/features/legal/compliance.md</a
    >
  </p>
  <p>&nbsp;</p>
  <p>
    Amazon Developer Program Content Policy:<br /><a
      href="https://developer.amazon.com/docs/policy-center/understanding-content-policy.html"
      >https://developer.amazon.com/docs/policy-center/understanding-content-policy.html</a
    >
  </p>
  <p>&nbsp;</p>
  <p>
    Android Developer Program Content Policy:<br /><a href="https://play.google.com/about/developer-content-policy/"
      >https://play.google.com/about/developer-content-policy/</a
    >
  </p>
  <p>&nbsp;</p>
  <p>Other policies may still apply based on the specific polices of each platform.</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>Terms Of Service:</strong></p>
  <p>&nbsp;</p>
  <p>
    TvStartup Inc. hosts and delivers video and audio content via the TvStartup “Channel Manager” video CMS software and
    distribution platforms including apps and websites. All users of the TvStartup platform retain full ownership of all
    video content the upload and host with TvStartup Inc., with no transfer of ownership of the user’s video content to
    TvStartup Inc. Any content owned by third-parties which is hosted on the TvStartup software platform is owned by the
    respective license holder(s), licensees, copyright holder, or other rights holder(s), and no rights holder(s)
    transfer ownership of the respective content to TvStartup Inc. Pirated content of any kind is against the terms of
    service listed above under the “Content Policy” section, and also against the terms of service for distribution on
    the app platforms TvStartup provides distribution services for.
  </p>
  <p>&nbsp;</p>
  <p><b>Limitation of Liability:</b></p>
  <b> </b>
  <p><b></b></p>
  <p>
    In no event shall TvStartup be liable to any Customer or User of any TvStartup service or software for
    consequential, special or indirect losses or damages, howsoever arising, including in the case of any form of
    service, software, or hosting down-time or data loss, whether under contract, tort or otherwise, including, without
    limitation, third party claims, loss of<span class="Apple-converted-space">&nbsp; </span>profits, loss of customers,
    or damage to reputation or goodwill, even if TvStartup is advised of the possibility of such damages. &nbsp;
  </p>
  <p>&nbsp;</p>
  <p><strong>Privacy Policy:</strong></p>
  <p>&nbsp;</p>
  <div class="component-text-content">
    <p>INTRODUCTION</p>
    <p>&nbsp;</p>
    <p>
      TvStartup Inc. (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy
      explains how we collect, use, disclose, and safeguard your information when you visit our website tvstartup.com
      and tvstartup.biz, including any other media form, media channel, mobile website, or mobile application related or
      connected thereto (collectively, the “Site”). Please read this privacy policy carefully. If you do not agree with
      the terms of this privacy policy, please do not access the site.
    </p>
    <p>&nbsp;</p>
    <p>
      We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you
      about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or modifications will be
      effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive
      specific notice of each such change or modification.
    </p>
    <p>&nbsp;</p>
    <p>You are encouraged to periodically review this Privacy Policy to stay informed of updates.</p>
  </div>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <div class="component-text-content">
    <p><strong>COLLECTION OF YOUR INFORMATION</strong></p>
    <p>&nbsp;</p>
    <p>
      We may collect information about you in a variety of ways. The information we may collect on the Site includes:
    </p>
    <p>&nbsp;</p>
    <p>Personal Data</p>
    <p>&nbsp;</p>
    <p>
      Personally identifiable information, such as your name, billing address, email address, telephone number, and your
      age, that you voluntarily give to us when you register with the Site, when you choose to participate in various
      activities related to the Site, such as online chat and message boards. You are under no obligation to provide us
      with personal information of any kind, however your refusal to do so may prevent you from using certain features
      of the Site.
    </p>
    <p>&nbsp;</p>
    <p>Derivative Data</p>
    <p>&nbsp;</p>
    <p>
      Information our servers automatically collect when you access the Site, such as your IP address, your browser
      type, your operating system, your access times, and the pages you have viewed directly before and after accessing
      the Site.
    </p>
    <p>&nbsp;</p>
    <p>Financial Data</p>
    <p>&nbsp;</p>
    <p>
      Financial information, such as data related to your payment method (e.g. valid credit card number, card brand,
      expiration date) that we may collect when you purchase, order, return, exchange, or request information about our
      services from the Site. We store limited financial information that we collect on some forms. Otherwise, all
      financial information is stored by our payment processor,
      <a href="https://www.authorize.net/about-us/privacy/">Authorize.Net</a>&nbsp;and you are encouraged to review
      their privacy policy and contact them directly for responses to your questions.
    </p>
    <p>&nbsp;</p>
    <p>Facebook Permissions</p>
    <p>&nbsp;</p>
    <p>
      The Site may by default access your <a href="https://www.facebook.com/about/privacy/">Facebook</a> basic account
      information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other
      information that you choose to make public. We may also request access to other permissions related to your
      account, such as friends, checkins, and likes, and you may choose to grant or deny us access to each individual
      permission. For more information regarding Facebook permissions, refer to the
      <a href="https://developers.facebook.com/docs/facebook-login/permissions">Facebook Permissions Reference</a> page.
    </p>
    <p>&nbsp;</p>
    <p>Third-Party Data</p>
    <p>&nbsp;</p>
    <p>
      Information from third parties, such as personal information or network friends, if you connect your account to
      the third party and grant the Site permission to access this information.
    </p>
    <p>&nbsp;</p>
    <p>Data From Contests, Giveaways, and Surveys</p>
    <p>&nbsp;</p>
    <p>
      Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
    </p>
    <p>&nbsp;</p>
    <p>USE OF YOUR INFORMATION</p>
    <p>&nbsp;</p>
    <p>
      Having accurate information about you permits us to provide you with a smooth, efficient, and customized
      experience. Specifically, we may use information collected about you via the Site to:
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>Administer sweepstakes, promotions, and contests.</li>
      <li>Assist law enforcement and respond to subpoena.</li>
      <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
      <li>Create and manage your account.</li>
      <li>
        Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site to
        you.
      </li>
      <li>Email you regarding your account or order.</li>
      <li>Enable user-to-user communications.</li>
      <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
      <li>Generate a personal profile about you to make future visits to the Site more personalized.</li>
      <li>Increase the efficiency and operation of the Site.</li>
      <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
      <li>Notify you of updates to the Site.</li>
      <li>Offer new products, services, and/or recommendations to you.</li>
      <li>Perform other business activities as needed.</li>
      <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
      <li>Process payments and refunds.</li>
      <li>Request feedback and contact you about your use of the Site.</li>
      <li>Resolve disputes and troubleshoot problems.</li>
      <li>Respond to product and customer service requests.</li>
      <li>Send you a newsletter.</li>
      <li>Solicit support for the Site</li>
    </ul>
    <p>&nbsp;</p>
    <p>DISCLOSURE OF YOUR INFORMATION</p>
    <p>&nbsp;</p>
    <p>
      We may share information we have collected about you in certain situations. Your information may be disclosed as
      follows:
    </p>
    <p>&nbsp;</p>
    <p>By Law or to Protect Rights</p>
    <p>&nbsp;</p>
    <p>
      If we believe the release of information about you is necessary to respond to legal process, to investigate or
      remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may
      share your information as permitted or required by any applicable law, rule, or regulation. This includes
      exchanging information with other entities for fraud protection and credit risk reduction.
    </p>
    <p>&nbsp;</p>
    <p>Third-Party Service Providers</p>
    <p>&nbsp;</p>
    <p>
      We may share your information with third parties that perform services for us or on our behalf, including payment
      processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
    </p>
    <p>&nbsp;</p>
    <p>Online Postings</p>
    <p>&nbsp;</p>
    <p>
      When you post comments, contributions or other content to the Site, your posts may be viewed by all users and may
      be publicly distributed outside the Site in perpetuity.
    </p>
    <p>&nbsp;</p>
    <p>Other Third Parties</p>
    <p>&nbsp;</p>
    <p>
      We may share your information with advertisers and investors for the purpose of conducting general business
      analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.
    </p>
    <p>&nbsp;</p>
    <p>Sale or Bankruptcy</p>
    <p>&nbsp;</p>
    <p>
      If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we
      may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your
      information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may
      occur and that the transferee may decline honor commitments we made in this Privacy Policy.
    </p>
    <p>&nbsp;</p>
    <p>
      We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we
      have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence,
      emails or other communications from third parties, you are responsible for contacting the third party directly.
    </p>
    <p>&nbsp;</p>
    <p>TRACKING TECHNOLOGIES</p>
    <p>&nbsp;</p>
    <p>
      <strong><br />Cookies and Web Beacons</strong>
    </p>
    <p>&nbsp;</p>
    <p>
      We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize
      the Site and improve your experience. When you access the Site, your personal information is not collected through
      the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject
      cookies, but be aware that such action could affect the availability and functionality of the Site. You may not
      decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web
      browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an
      individual basis.
    </p>
    <p>
      We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize
      the Site and improve your experience.
    </p>
    <p>&nbsp;</p>
    <p>Internet-Based Advertising</p>
    <p>&nbsp;</p>
    <p>
      Additionally, we may use third-party software to serve ads on the Site, implement email marketing campaigns, and
      manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking
      technology to help manage and optimize your online experience with us. For more information about opting-out of
      interest-based ads, visit the
      <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative Opt-Out Tool</a>or
      <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance Opt-Out Tool</a>.
    </p>
    <p>&nbsp;</p>
    <p>Website Analytics</p>
    <p>&nbsp;</p>
    <p>
      We may also partner with selected third-party vendors, such as Google, Facebook&nbsp;and others, to allow tracking
      technologies and remarketing services on the Site through the use of first party cookies and third-party cookies,
      to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and
      better understand online activity.
    </p>
    <p>&nbsp;</p>
    <p>
      By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You
      are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not
      transfer personal information to these third-party vendors. However, if you do not want any information to be
      collected and used by tracking technologies, you can visit the third-party vendor or the
      <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative Opt-Out Tool</a> or
      <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance Opt-Out Tool</a>.
    </p>
    <p>&nbsp;</p>
    <p>
      You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or
      erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or
      settings.
    </p>
    <p>&nbsp;</p>
    <p>THIRD-PARTY WEBSITES</p>
    <p>&nbsp;</p>
    <p>
      The Site may contain links to third-party websites and applications of interest, including advertisements and
      external services, that are not affiliated with us. Once you have used these links to leave the Site, any
      information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the
      safety and privacy of your information.
    </p>
    <p>&nbsp;</p>
    <p>
      Before visiting and providing any information to any third-party websites, you should inform yourself of the
      privacy policies and practices (if any) of the third party responsible for that website, and should take those
      steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the
      content or privacy and security practices and policies of any third parties, including other sites, services or
      applications that may be linked to or from the Site.
    </p>
    <p>&nbsp;</p>
    <p>SECURITY OF YOUR INFORMATION</p>
    <p>&nbsp;</p>
    <p>
      We use administrative, technical, and physical security measures to help protect your personal information. While
      we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite
      our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be
      guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to
      interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide
      personal information.
    </p>
    <p>&nbsp;</p>
    <p>POLICY FOR CHILDREN</p>
    <p>&nbsp;</p>
    <p>
      We do not knowingly solicit information from or market to minors under the age of 18. If you become aware of any
      data we have collected from children under age 18, please contact us using the contact information provided below.
    </p>
  </div>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <div class="component-text-content">
    <p><strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
    <p>&nbsp;</p>
    <p>
      Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can
      activate to signal your privacy preference not to have data about your online browsing activities monitored and
      collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As
      such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates
      your choice not to be tracked online.
    </p>
    <p>&nbsp;</p>
    <p>
      If a standard for online tracking is adopted that we must follow in the future, we will inform you about that
      practice in a revised version of this Privacy Policy. Most web browsers and some mobile operating systems include
      a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data
      about your online browsing activities monitored and collected. If you set the DNT signal on your browser, we will
      respond to such DNT browser signals.
    </p>
    <p>&nbsp;</p>
    <p>OPTIONS REGARDING YOUR INFORMATION</p>
  </div>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <div class="component-text-content">
    <p><strong>Account Information</strong></p>
    <p>&nbsp;</p>
    <p>You may at any time review or change the information in your account or terminate your account by:</p>
    <p>&nbsp;</p>
    <ul>
      <li>Logging into your account settings and updating your account</li>
      <li>Contacting us using the contact information provided below</li>
    </ul>
    <p>&nbsp;</p>
    <p>
      Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems,
      assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
    </p>
  </div>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <div class="component-text-content">
    <p><strong>Emails and Communications</strong></p>
    <p>&nbsp;</p>
    <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>
    <p>&nbsp;</p>
    <ul>
      <li>Noting your preferences at the time you register your account with the Site.</li>
      <li>Logging into your account settings and updating your preferences.</li>
      <li>Contacting us using the contact information provided below.</li>
    </ul>
    <p>&nbsp;</p>
    <p>CALIFORNIA PRIVACY RIGHTS</p>
    <p>&nbsp;</p>
    <p>
      California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
      California residents to request and obtain from us, once a year and free of charge, information about categories
      of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
      addresses of all third parties with which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a request, please submit your request in
      writing to us using the contact information provided below.
    </p>
    <p>&nbsp;</p>
    <p>
      If you are under 18 years of age, reside in California, and have a registered account with the Site you have the
      right to request removal of unwanted data that you publicly post on the Site. To request removal of such data,
      please contact us using the contact information provided below, and include the email address associated with your
      account and a statement that you reside in California. We will make sure the data is not publicly displayed on the
      Site, but please be aware that the data may not be completely or comprehensively removed from our systems.
    </p>
  </div>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <div class="component-text-content">
    <p><strong>CONTACT US</strong></p>
    <p>&nbsp;</p>
    <p>
      If you have questions or comments about this Privacy Policy, please
      <a href="https://tvstartup.com/contact-us/">contact us.</a><br /><span class="screen-reader-text">Close</span>
    </p>
  </div>
  <p>&nbsp;</p>
</div>
