import { Injectable } from '@angular/core';
import { ChannelService, User } from '@shared';
import { ApiService } from '../../core/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';

export interface Credentials {
  username: string;
  password: string;
  token: string;
}

export interface SessionResponse {
  token: string;
  user: User;
}

export interface UserRegisterPayload {
  credentials: {
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    password: string;
    confirm: string;
  };

  channel_details: {
    title: string;
    description: string;
    stream_key?: string;
    stream_url?: string;
    channel_profile?: string;
    timezone: string;
  };

  plan_code?: string;
  coupon_code?: string;
  addons?: any[];
  redirect_url?: string;
}

export interface UserRegisterPayloadWithoutChannel {
  credentials: {
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    password: string;
    confirm: string;
    zoho_id: string;
    subscription_id: string;
  };

  plan_code?: string;
  coupon_code?: string;
  addons?: any[];
  redirect_url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly TOKEN = 'TOKEN';
  private readonly ACCOUNT_INFO = 'ACCOUNT_INFO';
  private readonly STORAGE_INFO = 'STORAGE_INFO';
  private readonly FEATURES = 'FEATURES';
  private readonly accountInfoSubject$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private readonly checkedExistingLogin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isNewAccount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private notifier: NotifierService,
    private router: Router,
    private channelService: ChannelService
  ) {}

  public setIsNewAccount(isNew: boolean) {
    this.isNewAccount$.next(isNew);
  }

  public getIsNewAccount(): Observable<boolean> {
    return this.isNewAccount$.asObservable().pipe(distinctUntilChanged());
  }

  public getCheckedExistingLogin(): Observable<boolean> {
    return this.checkedExistingLogin$.asObservable().pipe(distinctUntilChanged());
  }

  public getAccountInfo(): Observable<User> {
    return this.accountInfoSubject$.asObservable().pipe(distinctUntilChanged());
  }

  public setAccountInfo(user: User) {
    this.accountInfoSubject$.next(user);
  }

  public getTenant(): string {
    return this.accountInfoSubject$.getValue().tenant_id;
  }

  login(credentials: Credentials): Observable<SessionResponse> {
    return this.apiService.post('/api/v2/auth/login/', credentials).pipe(
      map((res) => {
        if (res) {
          let { access_token: token, user } = res;
          user = {
            ...user,
            avatar: `${environment.mediaUrl}/${user.avatar}`,
            tenant: user.tenant_id,
          };
          this.setAccountInfo(user);
          this.storeToken(token);
          this.storeAccoutInfo(user);
          this.storeFeature(user.features || []);
          this.storeStorageInfo(user.is_use_scale_engine);
        }
        return res;
      })
    );
  }

  logout() {
    this.doLogoutUser();
    this.notifier.notify('success', `You have signed out`);
    this.router.navigateByUrl('/auth/login');
    window.location.href = '/auth/login';
  }

  register(data: UserRegisterPayloadWithoutChannel): Observable<any> {
    return this.apiService.post('/api/v1/auth/register/', data).pipe(
      map((res) => {
        if (res) {
          let { temporary_token: token } = res;
          localStorage.setItem('temporary_token', token);
        }
        return res;
      })
    );
  }

  checkEmail(data: { credentials: { email: string } }): Observable<any> {
    return this.apiService.post('/api/v1/auth/check-zoho-subscription/', data).pipe(
      map((res) => {
        if (res) {
          console.log(res);
        }
        return res;
      })
    );
  }

  indicator(email: string, token: string): Observable<any> {
    return this.apiService.get('/api/v1/auth/verify/?email=' + email + '&temporary-token=' + token).pipe(
      map((res) => {
        if (res) {
          if (res.is_all_setup === true) {
            let { access_token: token, user } = res;
            user = {
              ...user,
              avatar: `${environment.mediaUrl}/${user.avatar}`,
              tenant: user.tenant_id,
            };
            this.setAccountInfo(user);
            this.storeToken(token);
            this.storeAccoutInfo(user);
            this.storeFeature(user.features || []);
          }
        }
        return res;
      })
    );
  }

  public storeAccoutInfo(user: User) {
    localStorage.setItem(this.ACCOUNT_INFO, JSON.stringify(user));
  }

  getStoreToken() {
    return localStorage.getItem(this.TOKEN);
  }

  getStoreAccountInfo() {
    let features = [];
    const featureLocalStorage = localStorage.getItem(this.FEATURES);
    if (featureLocalStorage) {
      try {
        const bytes = CryptoJS.AES.decrypt(featureLocalStorage, environment.cryptoKey);
        features = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        features = [];
      }
    }
    const user = JSON.parse(localStorage.getItem(this.ACCOUNT_INFO)) as User;
    if (user) {
      user.features = features;
    }
    return user;
  }

  checkExistingLogin() {
    const token = this.getStoreToken();
    const user = this.getStoreAccountInfo();
    if (token && user) {
      this.setAccountInfo(user);
      this.checkedExistingLogin$.next(true);
    } else {
      this.doLogoutUser();
    }
  }

  getUserProfile() {}

  doLogoutUser() {
    this.removeAccountInfo();
    this.removeToken();
    this.removeFeature();
    this.setAccountInfo(null);
    this.storeStorageInfo(null);
    this.channelService.setActiveChannel(null);
    this.checkedExistingLogin$.next(false);
  }

  forgotPassword(email: string) {
    return this.apiService.post('/api/v1/auth/reset-password/', { email });
  }

  resetPassword(data: any) {
    return this.apiService.post('/api/v1/auth/reset-password/', data);
  }

  public storeFeature(features: string[]) {
    const key = environment.cryptoKey;
    const featuresEncrypt = CryptoJS.AES.encrypt(JSON.stringify(features), key).toString();
    localStorage.setItem(this.FEATURES, featuresEncrypt);
  }

  private storeToken(token: string) {
    localStorage.setItem(this.TOKEN, token);
  }

  private removeToken() {
    localStorage.removeItem(this.TOKEN);
  }

  private removeAccountInfo() {
    localStorage.removeItem(this.ACCOUNT_INFO);
    localStorage.removeItem('hasActiveCard');
    localStorage.removeItem('hosted_page_update_card');
    localStorage.removeItem('lastShowZohoPageTimestamp');
    localStorage.removeItem('lastCheckedTimestamp');
    localStorage.removeItem('xx_inv_data');
  }

  private removeFeature() {
    localStorage.removeItem(this.FEATURES);
  }

  public storeStorageInfo(isUseScaleEngine: boolean) {
    localStorage.setItem(this.STORAGE_INFO, JSON.stringify({ is_use_scale_engine: isUseScaleEngine }));
  }

  getStorageInfo(): any {
    let storage = localStorage.getItem(this.STORAGE_INFO);

    if (storage) return storage;
    else this.logout(); // force logout. relogin to store storage info
  }

  getPackageDetails(): Observable<any> {
    return this.apiService.get(`/api/v1/user/user-package-details/`);
  }

  checkIfHibernated(tenant_id: string): Observable<any> {
    return this.apiService.get(`/api/v1/user/${tenant_id}/check-tenant-if-hibernated/`);
  }
}
