<button
  mat-raised-button
  color="primary"
  (click)="showTutorial()"
  [ngClass]="{ 'app-tut-btn': 1, 'app-tut-btn-relative': relative === true }"
  [ngStyle]="customStyle ? parseStyle(customStyle) : null"
>
  <mat-icon>help</mat-icon>
  Watch Tutorials
</button>
