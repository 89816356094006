import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from '@env/environment.staging';
import { BehaviorSubject } from 'rxjs';
import { BroadcastStudioMainService } from '../../broadcast-studio.service';

let popupWindow: any;
let accessToken: any;
declare const FB: any;

@Injectable({
  providedIn: 'root',
})
export class AddOutputDialougService implements OnInit {
  accessTokenStatus = new BehaviorSubject(false);
  youtubeAccessTokenStatus = new BehaviorSubject(false);
  facebookTokenStatus = new BehaviorSubject(false);
  tvstartupChannelStatus = new BehaviorSubject(false);
  instagramToggleStatus = new BehaviorSubject(false);
  rtmpToggleStatus = new BehaviorSubject(false);
  tiktokToggleStatus = new BehaviorSubject(false);

  tvStartupVisibilityStatus = new BehaviorSubject(false);
  facebookVisibilityStatus = new BehaviorSubject(false);
  youtubeVisibilityStatus = new BehaviorSubject(false);
  twitchVisibilityStatus = new BehaviorSubject(false);
  instagramVisibilityStatus = new BehaviorSubject(false);
  rtmpVisibilityStatus = new BehaviorSubject(false);
  tiktokVisibilityStatus = new BehaviorSubject(false);

  randomKey: any;

  constructor(private http: HttpClient, private broadCastStudioService: BroadcastStudioMainService) {}

  ngOnInit(): void {
    // this.randomKey = localStorage.getItem('randomKey');
  }

  twitchAuth() {
    const twitchUrl = window.location.origin;
    var signinUrl = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${environment.twitchClientId}&redirect_uri=${twitchUrl}/broadcast-studio&scope=channel%3Amanage%3Apolls+channel%3Aread%3Apolls+channel%3Aread%3Astream_key`;
    popupWindow = window.open(signinUrl, '_blank', 'height=700,width=500, top=500,left=500');
    let childUrl;
    let myInterval = setInterval(() => {
      childUrl = popupWindow.location.href;
      if (childUrl.includes('access_token=')) {
        const url = new URL(childUrl);
        const params = new URLSearchParams(url.hash.slice(1));
        accessToken = params.get('access_token');
        if (accessToken) {
          clearInterval(myInterval);
          popupWindow.close();
          this.clickTwitch(accessToken, myInterval, popupWindow);
        }
      }
    }, 1000);
  }

  async clickTwitch(token: any, myInterval: any, popupWindow: any) {
    const response = await fetch('https://id.twitch.tv/oauth2/validate', {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    response.json().then((res) => {
      this.getStreamKey(res, token, myInterval, popupWindow);
    });
  }

  async getStreamKey(res: any, token: any, myInterval: any, popupWindow: any) {
    const response = await fetch(`https://api.twitch.tv/helix/streams/key?broadcaster_id=${res.user_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Client-Id': `${res.client_id}`,
      }),
    });

    response.json().then((res) => {
      const streamKey = res.data[0].stream_key;
      streamKey
        ? localStorage.setItem('twitchStreamKeyValue', streamKey)
        : localStorage.setItem('twitchStreamKeyValue', 'false');
      this.accessTokenStatus.next(true);

      localStorage.setItem('twitchVisible', 'true');
      this.twitchVisibilityStatus.next(true);
    });
  }

  loginWithFacebook() {
    FB.login(
      (response: any) => {
        let facebookAccessToken = response.authResponse.accessToken;
        let facebookUserId = response.authResponse.userID;
        localStorage.setItem('facebookVisible', 'true');
        this.facebookVisibilityStatus.next(true);
        if (facebookAccessToken && facebookUserId) {
          this.getStreamUrl(facebookAccessToken, facebookUserId);
        }
      },
      {
        scope: 'publish_video',
        auth_type: 'rerequest',
      }
    );
  }

  getStreamUrl(token: any, userId: any) {
    this.http
      .post('https://jitsirtmp.centralindia.cloudapp.azure.com/fb/generateStreamUrl', {
        facebookAccessToken: token,
        facebookUserId: userId,
      })
      .subscribe((res: any) => {
        this.facebookTokenStatus.next(true);
        localStorage.setItem('facebookUrl', JSON.stringify(res.data.stream_url));
      });
  }

  killTvStartupProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.channelProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.channelProcessId,
          })
          .subscribe();
      }
    } else {
      const obj = JSON.parse(localStorage.getItem('selectedChannelRtmpInfo'));

      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          channel:
            obj.rtmpUrl.charAt(obj.rtmpUrl.length - 1) == '/'
              ? `${obj.rtmpUrl}${obj.streamKey}`
              : `${obj.rtmpUrl}/${obj.streamKey}`,
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            channelProcessId: res.data.channelProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }

  killTwitchProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.twitchProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.twitchProcessId,
          })
          .subscribe();
      }
    } else {
      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          twitch: localStorage.getItem('twitchStreamKeyValue'),
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            twitchProcessId: res.data.twitchProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }

  killInstagramProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.instagramProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.instagramProcessId,
          })
          .subscribe();
      }
    } else {
      const obj = JSON.parse(localStorage.getItem('instaInfo'));

      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          instagram:
            obj.rtmpUrl.charAt(obj.rtmpUrl.length - 1) == '/'
              ? `${obj.rtmpUrl}${obj.streamKey}`
              : `${obj.rtmpUrl}/${obj.streamKey}`,
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            instagramProcessId: res.data.instagramProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }

  killRtmpProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.customRtmpProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.customRtmpProcessId,
          })
          .subscribe();
      }
    } else {
      let obj = JSON.parse(localStorage.getItem('customRtmpInfo'));
      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          customRtmp:
            obj.customRtmpUrl.charAt(obj.customRtmpUrl.length - 1) == '/'
              ? `${obj.customRtmpUrl}${obj.customRtmpKey}`
              : `${obj.customRtmpUrl}/${obj.customRtmpKey}`,
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            customRtmpProcessId: res.data.customRtmpProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }

  killTiktokProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.tiktokProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.tiktokProcessId,
          })
          .subscribe();
      }
    } else {
      let obj = JSON.parse(localStorage.getItem('tiktokInfo'));
      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          tiktok:
            obj.tiktokRtmpUrl.charAt(obj.tiktokRtmpUrl.length - 1) == '/'
              ? `${obj.tiktokRtmpUrl}${obj.tiktokStreamKey}`
              : `${obj.tiktokRtmpUrl}/${obj.tiktokStreamKey}`,
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            tiktokProcessId: res.data.tiktokProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }

  killYoutubeProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.youtubeProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.youtubeProcessId,
          })
          .subscribe();
      }
    } else {
      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          youtube: localStorage.getItem('youtubeToken'),
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            youtubeProcessId: res.data.youtubeProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }

  killFacebookProcess(status: boolean = true, streamKeyValue: any = '') {
    if (status) {
      const processId = JSON.parse(localStorage.getItem('processId'));
      if (processId.fbProcessId) {
        this.http
          .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/killProcess', {
            processId: processId.fbProcessId,
          })
          .subscribe();
      }
    } else {
      this.http
        .post('https://jitsirtmp.centralindia.cloudapp.azure.com/restream/all', {
          facebook: JSON.parse(localStorage.getItem('facebookUrl')),
          streamId: streamKeyValue,
        })
        .subscribe((res: any) => {
          let processId = JSON.parse(localStorage.getItem('processId'));
          let ourProcessId = {
            ...processId,
            fbProcessId: res.data.fbProcessId,
          };
          localStorage.setItem('processId', JSON.stringify(ourProcessId));
        });
    }
  }
}
