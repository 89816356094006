import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { WebsitePreviewingComponent } from './cms/websites/views/website-previewing/website-previewing.view';
import { AuthGuard } from './core';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { CmsLayoutComponent } from './layouts/cms-layout/cms-layout.component';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { PrivacyPolicyComponent } from './shared';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'terms-policies',
    component: PrivacyPolicyComponent,
    // loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'previewing-webpage/:websiteUUID',
    component: WebsitePreviewingComponent,
    loadChildren: () => import('./cms/websites/website.module').then((m) => m.ProjectsModule),
  },
  {
    path: 'guest',
    component: GuestLayoutComponent,
    loadChildren: () => import('./guest/guest.module').then((m) => m.GuestModule),
  },
  {
    path: 'embed-code',
    component: GuestLayoutComponent,
    loadChildren: () => import('./embed-code/embed-code.module').then((m) => m.EmbedCodeModule),
  },
  {
    path: '',
    component: CmsLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRouting {}
