import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from '@app/shared/domain';

@Component({
  selector: 'app-preview-video',
  templateUrl: './preview-video.component.html',
  styleUrls: ['./preview-video.component.scss'],
})
export class PreviewVideoComponent implements OnInit {
  public videoSource: string;
  public video: Video;
  constructor(@Inject(MAT_DIALOG_DATA) public data: PreviewVideoModel) {
    if (data.video.video_type) {
      this.videoSource = ['IMPORTED', 'MERGED', 'UPLOADED', 'RECORDED'].includes(data.video.video_type)
        ? data.video.output_file
        : data.video.source_file;
    } else {
      this.videoSource = data.video.output_file ? data.video.output_file : data.video.source_file;
    }
    this.video = data.video;
  }

  ngOnInit(): void {}
}

export class PreviewVideoModel {
  constructor(public video: Video) {}
}
