import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService } from '@app/shared/services/analytics';
import { MatTableDataSource } from '@angular/material/table';
import html2canvas from 'html2canvas';
import * as pdfMake from 'pdfmake/build/pdfmake';

@Component({
  selector: 'app-storage-breakdown',
  templateUrl: './storage-breakdown.component.html',
  styleUrls: ['./storage-breakdown.component.scss'],
})
export class StorageBreakdownComponent implements OnInit {
  public filter: any;
  public limit: any;
  public total_storage: any;
  public overage: any;
  public cost: any;
  public isLoading: boolean = false;
  today: Date = new Date();
  storageBreakdown: MatTableDataSource<any>;
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
  };
  single: any[] = [];
  gradient: boolean = true;
  spinnerColor = 'primary';
  spinnerMode = 'indeterminate';
  spinnerDiameter = 25;
  docDefinition: any;

  constructor(
    private readonly analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: StorageBreakdownModel
  ) {
    this.filter = data.filter;
    this.limit = data.limit;
    this.total_storage = data.total_storage;
    this.overage = data.overage;
    this.cost = data.cost;
    this.single = [
      {
        name: 'Limit',
        value: this.limit,
      },
      {
        name: 'Usage',
        value: this.total_storage,
      },
    ];
  }

  ngOnInit(): void {
    let start_date = this.filter.start_date;
    let end_date = this.filter.end_date;
    this.isLoading = true;
    this.analyticsService.getStorageBreakdown(start_date.toISOString(), end_date.toISOString()).subscribe(
      (res) => {
        this.storageBreakdown = new MatTableDataSource(res);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  handleSortChange(event: any) {
    const isAsc = event.direction === 'asc';
    switch (event.active) {
      case 'date':
        this.storageBreakdown.data = this.storageBreakdown.data.sort((a, b) =>
          this.compare(a.created, b.created, isAsc)
        );
        break;
      case 'storage':
        this.storageBreakdown.data = this.storageBreakdown.data.sort((a, b) => this.compare(a.usage, b.usage, isAsc));
        break;
      default:
        break;
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  handleDownloadPdfStorageBreakdown() {
    setTimeout(() => {
      console.log('Downloading');

      const element = document.getElementById('storage-breakdown');
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm
      const pdfPageHeightPx = (pdfHeight * 100) / 30; // Convert A4 height to pixels

      html2canvas(element, {
        windowHeight: 1000,
        windowWidth: 1920,
        backgroundColor: null,
        logging: false,
        onclone: (document) => {
          document.getElementById('storage-breakdown').style.visibility = 'visible';
        },
      }).then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let pages = [];
        let pageHeight = pdfPageHeightPx;
        let totalPages = Math.ceil(canvas.height / pageHeight);

        for (let i = 0; i < totalPages; i++) {
          let srcY = i * pageHeight;
          let pageCanvas = document.createElement('canvas');
          pageCanvas.width = canvas.width;
          pageCanvas.height = pageHeight;
          let ctx = pageCanvas.getContext('2d');
          ctx.drawImage(canvas, 0, srcY, canvas.width, pageHeight, 0, 0, canvas.width, pageHeight);
          let pageData = pageCanvas.toDataURL('image/png');
          pages.push(pageData);
        }

        const docDefinition = {
          content: pages.map((pageData) => ({
            image: pageData,
            width: pdfWidth * 2,
          })),
        };

        pdfMake.createPdf(docDefinition).download('storage-breakdown.pdf');
      });
    }, 500);
  }
}

export class StorageBreakdownModel {
  constructor(
    public filter: any,
    public limit: any,
    public total_storage: any,
    public overage: any,
    public cost: any
  ) {}
}
