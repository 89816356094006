import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FolderStructureService } from '@app/shared';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
interface FolderNode {
  channel_id: string;
  uuid: string;
  parent_id: string;
  title: string;
  items: number;
  type: string;
  active: boolean;
  children?: FolderNode[];
}

@Component({
  selector: 'app-select-folder-dialog',
  templateUrl: './select-folder-dialog.component.html',
  styleUrls: ['./select-folder-dialog.component.scss'],
})
export class SelectFolderDialogComponent implements OnInit {
  loading: boolean = false;
  action: string = null;
  totalVideos: number = 0;
  folder_id: string[] = null;
  folders: FolderNode[] = [];
  raw_folders: any[];
  treeControl = new NestedTreeControl<FolderNode>((node) => node.children);
  hasChild = (_: number, node: FolderNode) => node.children && node.children.length > 0;
  selectedFolder: any;
  search_text: string = '';
  library: string = '';
  public fetchFoldersSubscriberObject: Subscription;
  public folderQueryChanged: Subject<string> = new Subject<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectFolderModel,
    public dialogRef: MatDialogRef<SelectFolderDialogComponent>,
    private folderService: FolderStructureService,
    private router: Router
  ) {
    this.action = data.action;
    this.folder_id = data.folder_id;
    this.library = data.library;
    this.folderQueryChanged.pipe(debounceTime(300)).subscribe(() => {
      this.fetchFolders();
    });
  }

  ngOnInit(): void {
    this.fetchFolders();
  }

  select(folder: any) {
    this.dialogRef.close(folder);
  }

  fetchFolders() {
    this.loading = true;
    let params = {
      search: this.search_text,
      library: this.library,
    };
    this.fetchFoldersSubscriberObject = this.folderService.fetchFolders(params).subscribe((folders) => {
      this.raw_folders = folders.results;
      this.folders = this.convertToTreeStructure(this.raw_folders);
      this.raw_folders.forEach((raw_folder) => {
        if (this.folder_id && this.folder_id.includes(raw_folder.uuid)) {
          this.selectedFolder = raw_folder;
          return;
        }
      });
      if (this.selectedFolder && this.selectedFolder.directory.split('/').length > 1) {
        let rootFolder: any = {
          uuid: 'root',
          title: 'root',
          type: 'folder',
        };
        this.folders.unshift(rootFolder);
      }
      this.loading = false;
    });
  }

  convertToTreeStructure(folders: any) {
    const folderMap = new Map();

    folders.forEach((folder: any) => {
      folder.children = [];
      folderMap.set(folder.uuid, folder);
    });

    folders.forEach((folder: any) => {
      if (folder.parent !== null && this.isParentExist(folder.parent)) {
        const parentFolder = folderMap.get(folder.parent);
        if (parentFolder) {
          if (this.folder_id) {
            if (!this.folder_id.includes(folder.uuid)) {
              parentFolder.children.push(folder);
            }
          } else {
            parentFolder.children.push(folder);
          }
        }
      }
    });
    const rootFolders = folders.filter((folder: any) => {
      if (folder.parent === null || !this.isParentExist(folder.parent)) {
        if (this.folder_id) {
          if (!this.folder_id.includes(folder.uuid)) {
            return true;
          }
        } else {
          return true;
        }
      }
    });
    return rootFolders;
  }

  isParentExist(parent: string) {
    let exist = false;
    this.raw_folders.forEach((folder: any) => {
      if (folder.uuid == parent) {
        exist = true;
      }
    });
    return exist;
  }

  handleSearchFolders(e: string) {
    this.fetchFoldersSubscriberObject.unsubscribe();
    this.folderQueryChanged.next();
  }

  handleViewFileExplorer(): void {
    this.dialogRef.close(false);
    this.router.navigate(['/folders'], { queryParams: { view: this.library } });
  }
}

export class SelectFolderModel {
  constructor(public folder_id: string[] = null, public action: string = 'select', public library: string) {}
}
