import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services';
import { CommonHelpers } from '@app/helpers';
import { Badge } from '@app/shared';
import { UserService, ZohoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { first } from 'rxjs/operators';
import { ConfirmDialogModel, ConfirmDialogComponent } from '@app/shared';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-upgarade-plan-dialog',
  templateUrl: './upgarade-plan-dialog.component.html',
  styleUrls: ['./upgarade-plan-dialog.component.scss'],
})
export class UpgradePlanDialogComponent implements OnInit {
  feature: string;
  isLoading: boolean;
  isUnlocking: any = {};
  accountPlan: string;
  myBadges: Badge[] = [];
  constructor(
    private dialogRef: MatDialogRef<UpgradePlanDialogComponent>,
    private zohoService: ZohoService,
    private authService: AuthService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: UpgradePlanDialogModel,
    private notify: NotifierService,
    private dialog: MatDialog
  ) {
    this.feature = data.feature;
  }

  ngOnInit(): void {
    this.authService
      .getAccountInfo()
      .pipe(first())
      .subscribe((account) => {
        this.accountPlan = account.plan;
        this.isLoading = true;
        this.zohoService.verifyFeature(this.feature).subscribe(
          (res) => {
            this.isLoading = false;
            if (res.allow) {
              this.authService.logout();
              this.dialogRef.close(false);
              return;
            }

            if (!account.plan) {
              this.myBadges =
                res.plans &&
                res.plans.map((item: any) => ({
                  ...item,
                  logo: `https://api.tvstartupcms.com/media/zoho/images/${item.plan_code}.png`,
                }));
            } else {
              this.myBadges =
                res.addons &&
                res.addons.map((item: any) => ({
                  ...item,
                  logo: `https://api.tvstartupcms.com/media/zoho/images/${item.addon_code}.png`,
                }));
            }
          },
          (err) => {
            this.isLoading = false;
            this.notify.notify('error', err);
          }
        );
      });
  }

  handleCancel() {
    this.dialogRef.close(false);
  }

  handleUnlockedAddons(code: string) {
    this.authService
      .getAccountInfo()
      .pipe(first())
      .subscribe((account) => {
        this.isUnlocking[code] = true;
        this.zohoService
          .changeSubscription({
            action: 'change_plan',
            data: {
              coupon_code: '',
              plan_code: account.plan,
              addons: [
                {
                  addon_code: code,
                },
              ],
              end_trial_period: '',
            },
            proceed_checkout: true,
          })
          .subscribe(
            (res) => {
              this.isUnlocking[code] = false;
              const result = CommonHelpers.transformResponseFromZoho(res);
              if (result.success) {
                this.userService.getProfile().subscribe((user) => {
                  this.authService.storeFeature(user.features);
                  this.authService.storeAccoutInfo({
                    ...account,
                    plan: user.purchased_plan,
                    features: user.features,
                  });
                  this.authService.setAccountInfo({
                    ...account,
                    plan: user.purchased_plan,
                    features: user.features,
                  });
                  this.dialogRef.close(true);
                  this.notify.notify('success', result.message);
                });
              } else {
                if (result.redirect) {
                  window.location.href = result.message;
                  return;
                } else {
                  this.notify.notify('error', result.message);
                }
              }
            },
            (err) => {
              this.notify.notify('error', err);
              this.dialogRef.close(false);
              this.isUnlocking[code] = false;
            }
          );
      });
  }

  handleUnlockedPlan(code: string) {
    this.authService
      .getAccountInfo()
      .pipe(first())
      .subscribe((account) => {
        this.isUnlocking[code] = true;
        this.zohoService
          .changeSubscription({
            action: 'change_plan',
            data: {
              coupon_code: '',
              plan_code: code,
              addons: [],
              end_trial_period: '',
            },
            proceed_checkout: true,
          })
          .subscribe(
            (res) => {
              this.isUnlocking[code] = false;
              const result = CommonHelpers.transformResponseFromZoho(res);
              if (result.success) {
                this.userService.getProfile().subscribe((user) => {
                  this.authService.storeFeature(user.features);
                  this.authService.setAccountInfo({
                    ...account,
                    plan: user.purchased_plan,
                    features: user.features,
                  });
                  this.authService.setAccountInfo({
                    ...account,
                    plan: user.purchased_plan,
                    features: user.features,
                  });
                  this.dialogRef.close(true);
                  this.notify.notify('success', result.message);
                });
              } else {
                if (result.redirect) {
                  window.location.href = result.message;
                  return;
                } else {
                  this.notify.notify('error', result.message);
                }
              }
            },
            (err) => {
              this.notify.notify('error', err);
              this.isUnlocking[code] = false;
            }
          );
      });
  }

  clickedUnlock(type: string, code: string) {
    const dialogData = new ConfirmDialogModel(
      'Confirm Purchase',
      "Are you sure you want to proceed with purchasing this feature? Clicking 'Proceed Checkout' will take you to the checkout form. This is NON-REFUNDABLE.",
      'Proceed Checkout'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '380px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (type == 'plan') {
          this.handleUnlockedPlan(code);
        } else if (type == 'addon') {
          this.handleUnlockedAddons(code);
        }
      }
    });
  }
}

export class UpgradePlanDialogModel {
  constructor(public feature: string) {}
}
