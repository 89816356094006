import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-no-payment-gateway',
  templateUrl: './no-payment-gateway.component.html',
  styleUrls: ['./no-payment-gateway.component.scss'],
})
export class NoPaymentGatewayComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NoPaymentGatewayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: string }
  ) {}

  ngOnInit(): void {}

  handleAddPaymentGateway() {
    this.dialogRef.close('add');
  }
  handleGoback() {
    this.dialogRef.close();
  }
}
