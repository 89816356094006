<div class="flex-p">
  <app-spinner *ngIf="false"></app-spinner>
  <div>
    <h2>{{ pages.length }} Pages Found</h2>
    <div cdkDropList class="drag-list py-1" (cdkDropListDropped)="drop($event)">
      <div class="drag-box" *ngFor="let page of pages; let i = index" cdkDragLockAxis="y" cdkDrag>
        <div class="drag-custom-placeholder" *cdkDragPlaceholder></div>
        <div>
          <button mat-icon-button class="drag-icon" cdkDragHandle>
            <mat-icon>menu</mat-icon>
          </button>
          {{ page.name }}
        </div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menuItemPage"
          aria-label="icon-button with a menu"
          [disabled]="page.defaultPage"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menuItemPage="matMenu">
          <button mat-menu-item (click)="editPageItem(page, i)">Edit Page</button>
          <button mat-menu-item (click)="deletePageItem(page, i)">Delete</button>
        </mat-menu>
      </div>
    </div>
    <div fxLayoutAlign="end start">
      <button mat-flat-button color="primary" (click)="addPageItem()">+ Add New Page</button>
    </div>
  </div>
</div>
