import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { Audio } from '@app/shared/domain';
import { Track } from 'ngx-audio-player';

@Component({
  selector: 'app-preview-audio',
  templateUrl: './preview-audio.component.html',
  styleUrls: ['./preview-audio.component.scss'],
})
export class PreviewAudioComponent implements OnInit {
  public audioSource: string;
  public audio: Audio;
  public tracks: Track[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: PreviewAudioModel) {
    this.audio = data.audio;
    this.audioSource = this.audio.source_file;
  }

  ngOnInit(): void {
    // this.tracks.push({
    //   title: this.audio.title,
    //   duration: CommonHelpers.durationToSecond(this.audio.duration),
    //   link: this.audio.source_file + '/playlist.m3u8',
    // });
  }

  onEnded(e: any) {}

  triggerOnEnded(event: any) {
    console.log('ended');
  }
}

export class PreviewAudioModel {
  constructor(public audio: Audio) {}
}
