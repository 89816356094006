<div mat-dialog-title style="text-align: center">Subscription Plan</div>
<div mat-dialog-content>
  <form [formGroup]="planForm" *ngIf="(channelList$ | async).length">
    <div fxLayout="column">
      <mat-label class="text-weight-bold">Channel</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="channel_uuid">
          <mat-option *ngFor="let c of channelList$ | async" [value]="c.uuid">
            {{ c.title }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.channel_uuid?.invalid">Please choose a channel</mat-error>
      </mat-form-field>

      <mat-label class="text-weight-bold">Subscription Plan</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="subscription_plan_uuid">
          <app-spinner *ngIf="isLoadingPlan"></app-spinner>
          <ng-container *ngIf="!isLoadingPlan">
            <mat-option *ngFor="let plan of plans" [value]="plan.uuid">
              {{ plan.title }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="f.subscription_plan_uuid?.invalid">Please choose a subscription plan</mat-error>
      </mat-form-field>

      <mat-label class="text-weight-bold">PPV Event</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="ppv_event_uuid">
          <app-spinner *ngIf="isLoadingPPVEvent"></app-spinner>
          <ng-container *ngIf="!isLoadingPPVEvent">
            <mat-option *ngFor="let ppv_event of ppv_events" [value]="ppv_event.uuid">
              {{ ppv_event.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-label class="text-weight-bold">PPV VOD</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="vod_uuid">
          <app-spinner *ngIf="isLoadingVOD"></app-spinner>
          <ng-container *ngIf="!isLoadingVOD">
            <mat-option *ngFor="let vod of vods" [value]="vod.uuid">
              {{ vod.content.title }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-label class="text-weight-bold">PPV Video</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="video_uuid">
          <app-spinner *ngIf="isLoadingVideo"></app-spinner>
          <ng-container *ngIf="!isLoadingVideo">
            <mat-option *ngFor="let video of videos" [value]="video.uuid">
              {{ video.content.title }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-label class="text-weight-bold">PPV AOD</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="aod_uuid">
          <app-spinner *ngIf="isLoadingAOD"></app-spinner>
          <ng-container *ngIf="!isLoadingAOD">
            <mat-option *ngFor="let aod of aods" [value]="aod.uuid">
              {{ aod.content.title }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-label class="text-weight-bold">PPV Audio</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="audio_uuid">
          <app-spinner *ngIf="isLoadingAudio"></app-spinner>
          <ng-container *ngIf="!isLoadingAudio">
            <mat-option *ngFor="let audio of audios" [value]="audio.uuid">
              {{ audio.content.title }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="mat-small" [style.margin-left]="'auto'" color="primary" (click)="handleAdd()">
    Add
  </button>
</div>
