import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Channel, ChannelDefault, Package } from '@app/shared/domain';
import { ChannelService, PackageService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { AddingAdditionalChannelDialogComponent } from '@app/shared';

@Component({
  selector: 'app-additional-channel-request-dialog',
  templateUrl: './additional-channel-request-dialog.component.html',
  styleUrls: ['./additional-channel-request-dialog.component.scss'],
})
export class AdditionalChannelRequestDialogComponent implements OnInit {
  public activeChannel$ = this.channelService.getActiveChannel();
  public isLoading: boolean;
  public isLoadingDefault: boolean;
  public channelDefault: ChannelDefault;
  public channelPackages: Package[];

  protected _onDestroy = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<AdditionalChannelRequestDialogComponent>,
    private notifier: NotifierService,
    private channelService: ChannelService,
    private packageService: PackageService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AdditionalChannelRequestDialogModel
  ) {}

  ngOnInit() {
    this.isLoadingDefault = true;
    forkJoin([this.channelService.getChannelDefault(), this.packageService.getAllPackages()]).subscribe(
      ([channelDefault, channelPackages]) => {
        this.channelDefault = channelDefault;
        this.channelPackages = channelPackages;
        this.isLoadingDefault = false;
      },
      () => {
        this.notifier.notify('error', 'Something went wrong');
        this.isLoadingDefault = false;
      }
    );
  }

  handleCreateChannel() {
    const dialogRef = this.dialog.open(AddingAdditionalChannelDialogComponent, {
      width: '430px',
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

export class AdditionalChannelRequestDialogModel {}
