import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { BroadcastStudioService, ChannelService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { tap } from 'rxjs/operators';
import { EmailGuestDialogComponent } from '@shared/components/email-guest-dialog/email-guest-dialog.component';
import { BroadcastStudioMainService } from '@app/cms/broadcast-studio/broadcast-studio.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-invite-guest-dialog',
  templateUrl: './invite-guest-dialog.component.html',
  styleUrls: ['./invite-guest-dialog.component.scss'],
})
export class InviteGuestDialogComponent implements OnInit {
  emails = new FormArray([]);
  studioId: string;
  emailGuests: string[] = [];
  email: string;
  setupForm: FormGroup;
  inviteUrl = '';
  requireAuthentication: boolean;
  isLoading: boolean;
  meetingUrl: any;
  buttonStatus: boolean = false;
  @ViewChild('inputLink') inputLink: ElementRef<HTMLInputElement>;

  constructor(
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: InviteGuestDialogModel,
    private broadcastStudioService: BroadcastStudioService,
    private broadcastStudioMainService: BroadcastStudioMainService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<InviteGuestDialogComponent>,
    private dialog: MatDialog,
    private broadCastStudioService: BroadcastStudioMainService
  ) {
    this.studioId = data.studioId;
  }

  coppiedLink() {
    this.notifier.notify('success', `copied to your clipboard`);
  }

  addMoreEmail() {
    this.emails.push(new FormControl('', [Validators.required, Validators.email]));
  }

  handleInviteGuest() {
    const data = {
      emails: this.emailGuests,
      invite_url: 'http://localhost:4201/guest/broadcasts-studio',
      studio_uuid: this.studioId,
    };
    this.broadcastStudioService.doInviteGuest(data).subscribe(console.log);
  }

  removeEmail(email: string) {
    this.emailGuests = this.emailGuests.filter((e) => e !== email);
  }

  addEmail() {
    this.emailGuests.push(this.email);
    this.email = '';
  }

  ngOnInit(): void {
    this.meetingUrl = localStorage.getItem('meetingLink');
    this.inviteUrl = `${window.location.origin}/guest/broadcasts-studio/${this.studioId}`;
    this.setupForm = this.formBuilder.group({
      username: [{ value: '', disabled: false }],
      password: [{ value: '', disabled: false }],
    });
    // this.broadcastStudioService
    //   .getStudioInfo(this.studioId)
    //   .pipe(
    //     tap((studioInfo: any) => {
    //       this.requireAuthentication = studioInfo.is_verify_account || false;
    //       this.setupForm = this.formBuilder.group({
    //         username: [{ value: studioInfo.live_account, disabled: !this.requireAuthentication }],
    //         password: [{ value: studioInfo.live_account ? '******' : '', disabled: !this.requireAuthentication }],
    //       });
    //     })
    //   )
    //   .subscribe(
    //     () => {},
    //     (err: any) => {}
    //   );
  }

  onRequireAuthenChange() {
    // if (this.requireAuthentication) {
    //   this.setupForm.controls['username'].enable();
    //   this.setupForm.controls['password'].enable();
    // } else {
    //   this.setupForm.controls['username'].disable();
    //   this.setupForm.controls['password'].disable();
    // }
    if (this.requireAuthentication) {
      this.buttonStatus = false;
    } else {
      this.buttonStatus = true;
    }
  }

  onSave(): void {
    // const formData = this.setupForm.getRawValue();
    // let payload;
    // if (this.requireAuthentication) {
    //   payload =
    //     formData.password === '******'
    //       ? {
    //           is_verify_account: this.requireAuthentication,
    //           live_account: formData.username,
    //         }
    //       : {
    //           is_verify_account: this.requireAuthentication,
    //           live_account: formData.username,
    //           passcode: formData.password,
    //         };
    // } else {
    //   payload = {
    //     is_verify_account: this.requireAuthentication,
    //   };
    // }
    // this.broadcastStudioService.updateStudioInfo(this.studioId, payload).subscribe(() => this.dialogRef.close());
    this.broadcastStudioMainService.submitFormHandlerStatus(this.setupForm.value.password);
    this.dialogRef.close();
  }

  onEmailLinkClick(): void {
    const dialogData = new InviteGuestDialogModel(this.studioId);
    this.dialog.open(EmailGuestDialogComponent, {
      width: '600px',
      data: dialogData,
    });
  }
}

export class InviteGuestDialogModel {
  constructor(public studioId: string) {}
}
