import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-channel-embed-code-dialog',
  templateUrl: './channel-embed-code-dialog.component.html',
  styleUrls: ['./channel-embed-code-dialog.component.scss'],
})
export class ChannelEmbedCodeDialogComponent implements OnInit {
  embedCode: string;
  constructor(private notifier: NotifierService, @Inject(MAT_DIALOG_DATA) public data: ChannelEmbedCodeDialogModel) {
    this.embedCode = data.embedCode;
  }

  ngOnInit(): void {}
  copied($event: any) {
    this.notifier.notify('success', `Copy embed successfully`);
  }
}

export class ChannelEmbedCodeDialogModel {
  constructor(public embedCode: string) {}
}
