import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { ChannelService, SubscriptionService } from '@app/shared/services';
import * as moment from 'moment';
import { BillingService } from '@app/shared/services/bill/billing.service';
import { ContentType } from '@app/app.constants';

@Component({
  selector: 'app-subscription-plan-dialog',
  templateUrl: './subscription-plan-dialog.component.html',
  styleUrls: ['./subscription-plan-dialog.component.scss'],
})
export class SubscriptionPlanDialogComponent implements OnInit {
  public planForm: FormGroup;
  public isLoadingPlan: boolean;
  public isLoadingPPVEvent: boolean;
  public isLoadingVOD: boolean;
  public isLoadingVideo: boolean;
  public isLoadingAOD: boolean;
  public isLoadingAudio: boolean;
  public plans: any;
  public ppv_events: any;
  public vods: any;
  public videos: any;
  public aods: any;
  public audios: any;
  public channelList$ = this.channelService.getChannelList();

  constructor(
    public dialogRef: MatDialogRef<SubscriptionPlanDialogComponent>,
    private formBuilder: FormBuilder,
    private channelService: ChannelService,
    private subscriptionService: SubscriptionService,
    private billingService: BillingService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.planForm = this.formBuilder.group({
      subscription_plan_uuid: [''],
      ppv_event_uuid: [''],
      vod_uuid: [''],
      video_uuid: [''],
      aod_uuid: [''],
      audio_uuid: [''],
      channel_uuid: ['', [Validators.required]],
    });
    this.planForm?.get('channel_uuid').valueChanges.subscribe((channelId) => {
      // Plans
      this.isLoadingPlan = true;
      this.subscriptionService.getListSubscriptionPlan(channelId).subscribe((res) => {
        this.isLoadingPlan = false;
        this.plans = res;
      });

      // PPV Events
      this.isLoadingPPVEvent = true;
      this.billingService.getListTicket({ channel_id: channelId, not_expired: true }).subscribe((res) => {
        this.isLoadingPPVEvent = false;
        this.ppv_events = res;
      });

      // VOD
      this.isLoadingVOD = true;
      this.subscriptionService.getPricingList(ContentType.VOD, { pricing_type: 'ppv' }).subscribe((res) => {
        this.isLoadingVOD = false;
        this.vods = res;
      });

      // Video
      this.isLoadingVideo = true;
      this.subscriptionService.getPricingList(ContentType.VIDEO, { pricing_type: 'ppv' }).subscribe((res) => {
        this.isLoadingVideo = false;
        this.videos = res;
      });

      // AOD
      this.isLoadingAOD = true;
      this.subscriptionService.getPricingList(ContentType.AOD, { pricing_type: 'ppv' }).subscribe((res) => {
        this.isLoadingAOD = false;
        this.aods = res;
      });

      // Audio
      this.isLoadingAudio = true;
      this.subscriptionService.getPricingList(ContentType.AUDIO, { pricing_type: 'ppv' }).subscribe((res) => {
        this.isLoadingAudio = false;
        this.audios = res;
      });
    });
  }

  get f() {
    return this.planForm.controls;
  }

  handleAdd() {
    let plan_uuid_val = this.planForm.controls['subscription_plan_uuid'].value;
    let ppv_event_uuid_val = this.planForm.controls['ppv_event_uuid'].value;
    let vod_uuid_val = this.planForm.controls['vod_uuid'].value;
    let video_uuid_val = this.planForm.controls['video_uuid'].value;
    let aod_uuid_val = this.planForm.controls['aod_uuid'].value;
    let audio_uuid_val = this.planForm.controls['audio_uuid'].value;

    if (
      plan_uuid_val == '' &&
      ppv_event_uuid_val == '' &&
      vod_uuid_val == '' &&
      video_uuid_val == '' &&
      aod_uuid_val == '' &&
      audio_uuid_val == ''
    ) {
      this.planForm.controls['subscription_plan_uuid'].setErrors({ invalid: true });
      this.planForm.controls['subscription_plan_uuid'].markAsTouched({ onlySelf: true });
      return;
    } else {
      this.planForm.controls['subscription_plan_uuid'].setErrors(null);
      this.planForm.controls['subscription_plan_uuid'].markAsTouched({ onlySelf: true });
    }

    if (this.planForm.invalid) {
      CommonHelpers.validateAllFormFields(this.planForm);
      return;
    }
    this.dialogRef.close(this.planForm.value);
  }
}
