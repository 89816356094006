<div mat-dialog-title style="text-align: center">Restreamer Account Settings</div>
<div mat-dialog-content>
  <form [formGroup]="accountSettingForm">
    <div fxLayout="column">
      <mat-label class="text-weight-bold">Account Platform</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="provider" placeholder="Select Account Platform">
          <mat-option value="FACEBOOK">Facebook</mat-option>
          <mat-option value="YOUTUBE">YouTube</mat-option>
          <mat-option value="TWITCH">Twitch</mat-option>
        </mat-select>
        <mat-error *ngIf="f.provider.invalid">Please choose a provider</mat-error>
      </mat-form-field>
      <mat-label class="text-weight-bold">Channel Name:</mat-label>
      <mat-form-field appearance="outline" class="width-form">
        <input type="text" formControlName="channelName" matInput required />
        <mat-error *ngIf="f.channelName.invalid">Channel Name is required</mat-error>
      </mat-form-field>

      <mat-label class="text-weight-bold">Stream Key:</mat-label>
      <mat-form-field appearance="outline" class="width-form">
        <input type="text" formControlName="streamKey" matInput required />
        <mat-error *ngIf="f.streamKey.invalid">Stream Key is required</mat-error>
      </mat-form-field>
      <mat-label class="text-weight-bold">Server/Streaming URL:</mat-label>
      <mat-form-field appearance="outline" class="width-form">
        <input type="text" formControlName="serverUrl" matInput required />
        <mat-error *ngIf="f.serverUrl.invalid">Server/Streaming URL is required</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleCreate()"
  >
    Create
  </button>
</div>
