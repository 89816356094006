import { FormGroup } from '@angular/forms';
// custom validator to check that two fields match
export function PaymentValidator(controlIsDefaultName: string) {
  return (formGroup: FormGroup) => {
    const controlIsDefault = formGroup.controls[controlIsDefaultName];
    const isDefault = controlIsDefault.value;
    const controls = formGroup.controls;
    if (isDefault) {
      Object.keys(controls).forEach((key) => {
        const controlName = controls[key];
        if (!controlName.value && key !== controlIsDefaultName) {
          controlName.setErrors({
            required: true,
          });
        } else {
          controlName.setErrors(null);
        }
      });
    } else {
      const exitsValue = Object.keys(controls).find((i) => controls[i].value);
      if (exitsValue) {
        Object.keys(controls).forEach((key) => {
          const controlName = controls[key];
          if (!controlName.value && key !== controlIsDefaultName) {
            controlName.setErrors({
              required: true,
            });
          } else {
            controlName.setErrors(null);
          }
        });
      } else {
        Object.keys(controls).forEach((key) => {
          const controlName = controls[key];
          controlName.setErrors(null);
        });
      }
    }
  };
}
