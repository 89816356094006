import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@app/auth/services';
import { configConstans } from '@app/core';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = '../assets/images/blank-profile.png';
  public accountInfo$ = this.authService.getAccountInfo();
  public configConstans = configConstans;
  constructor(private authService: AuthService) {}

  ngOnInit() {}

  handleLogout() {
    this.authService.logout();
  }
}
