import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-select-oauth-option',
  templateUrl: './select-oauth-option.component.html',
  styleUrls: ['./select-oauth-option.component.scss'],
})
export class SelectOauthOptionComponent implements OnInit {
  account_type: string = null;
  constructor(
    public dialogRef: MatDialogRef<SelectOauthOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectOauthOptionModel
  ) {
    this.account_type = data.account_type;
  }

  ngOnInit(): void {}

  select(action: any) {
    this.dialogRef.close(action);
  }
}

export class SelectOauthOptionModel {
  constructor(public account_type: string) {}
}
